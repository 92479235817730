import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Page } from 'src/constant/Page';
import { UserOrder } from 'src/model/Order';
import { openSnackbar } from 'src/redux/uiSlice';
import { getTradeById } from 'src/service/tradeAdminService';
import { bnFormat } from 'src/util/bigNumber';

const TradeAdminDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: orderId } = useParams<{ id: string }>();
  const [order, setOrder] = useState<UserOrder>();

  useEffect(() => {
    if (!orderId) return;

    getTradeById(orderId)
      .then((res) => setOrder(res))
      .catch((e) => {
        navigate(Page.Trade, { replace: true });
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [orderId]);

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">{`${t(
        'trade.desc.trade',
      )} ${order?.id ?? ''}`}</div>
      <div className="mt-[30px] flex flex-col gap-[30px] md:flex-row">
        <div className="bg-white px-[25px] py-[30px] md:w-[50%]">
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              ID
            </div>
            <div className="w-[70%] break-all leading-[21px]">{order?.id}</div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.status')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order ? t(`trade.desc.statusDisplay.${order.status}`) : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.seller')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              <div
                className="w-fit cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
                onClick={() => {
                  if (order?.user.side === 'ask') navigate(`${Page.UserAsk}/${order.userId}`);
                  else if (order?.user.side === 'bid') navigate(`${Page.UserBid}/${order.userId}`);
                  else navigate(`${Page.UserAdmin}/${order?.userId}`);
                }}
              >
                {t(`trade.desc.side.${order?.user.side}`)}-{order?.user.email}
              </div>
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.amount')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order ? `${bnFormat(order.openAmount)} ${order.base.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.price')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order ? `${bnFormat(order.price)} ${order.quote.toUpperCase()}` : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.limit')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order ? `${bnFormat(order.minTotal)} - ${bnFormat(order.maxTotal)}` : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.createTime')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order?.createdAt ? format(new Date(order.createdAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.updateTime')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order?.updatedAt ? format(new Date(order.updatedAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.suspendTime')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order?.suspendedAt ? format(new Date(order.suspendedAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('trade.desc.deleteTime')}
            </div>
            <div className="w-[70%] break-all leading-[21px]">
              {order?.deletedAt ? format(new Date(order.deletedAt), 'yyyy/MM/dd HH:mm:ss') : ''}
            </div>
          </div>
        </div>
        <div className="bg-white px-[25px] py-[30px] md:w-[50%]">
          <div className="mb-[10px] font-bold">{t('bankAccount.paymentMethod')}</div>
          {order?.bankAccount.paymentMethodType === 'bank' && (
            <>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.bankName')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">
                  {order?.bankAccount.bankName}
                </div>
              </div>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.province')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">
                  {order?.bankAccount.province}
                </div>
              </div>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.city')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">{order?.bankAccount.city}</div>
              </div>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.bankBranchName')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">
                  {order?.bankAccount.bankBranchName}
                </div>
              </div>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.accountNumber')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">{order?.bankAccount.account}</div>
              </div>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.accountName')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">{order?.bankAccount.name}</div>
              </div>
            </>
          )}
          {order?.bankAccount.paymentMethodType === 'alipay' && (
            <>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.alipayAccountName')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">{order?.bankAccount.name}</div>
              </div>
              <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
                <div className="w-[30%] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
                  {t('bankAccount.alipayAccount')}
                </div>
                <div className="w-[70%] break-all leading-[21px]">{order?.bankAccount.account}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TradeAdminDetail;
