import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Pagination from 'src/component/Pagination';
import RoundDateFilter from 'src/component/RoundDateFilter';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcTron from 'src/image/ic-tron.svg';
import IcUsdt from 'src/image/ic-usdt.svg';
import { Balance } from 'src/model/Balance';
import { BalanceTransaction } from 'src/model/Property';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getProfit, getProfitTransaction } from 'src/service/homeService';
import { bnFormat } from 'src/util/bigNumber';
import Card from './component/Card';
import Table from './component/Table';

const Profit = () => {
  const { coin } = useParams<{ coin: string }>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((rootState: RootState) => rootState).auth;
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [balance, setBalance] = useState<Balance>();
  const [transactionArray, setTransactionArray] = useState<Array<BalanceTransaction>>();
  const { begin, end, dateFilterProps } = useDateFilter();
  const { offset, limit, setCount, paginationProps } = usePagination();

  useEffect(() => {
    if (!coin) return;

    getProfit()
      .then((res) => setBalance(res.find((v) => v.coin === coin)))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    if (!coin) return;

    getProfitTransaction(coin, { begin, end, offset, limit })
      .then((res) => {
        setTransactionArray(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [begin, end, offset, limit]);

  const onWithdrawalClick = () => {
    navigate(`${Page.Home}/profit/withdrawal?coinId=${coin}`);
  };

  const navigateCallback = (transaction: BalanceTransaction) => {
    switch (transaction.type) {
      case 'ask':
      case 'bid': {
        navigate(`${Page.Fill}/${transaction.referenceId}`);
        break;
      }
      case 'withdrawal':
      case 'withdrawal-fee': {
        navigate(`${Page.WithdrawalRecord}/${transaction.referenceId}`);
        break;
      }
      case 'deposit': {
        navigate(`${Page.DepositRecord}/${transaction.referenceId}`);
        break;
      }
      case 'profit': {
        navigate(`${Page.Fill}/${transaction.referenceId}`);
        break;
      }
      case 'fee-profit': {
        navigate(`${Page.WithdrawalRecord}/${transaction.referenceId}`);
        break;
      }
      case 'parent-rebate':
      case 'rebate-expense': {
        navigate(`${Page.ParentRebate}/${transaction.referenceId}`);
        break;
      }
      case 'agent-rebate': {
        navigate(`${Page.AgentRebate}/${transaction.referenceId}`);
        break;
      }
    }
  };

  const onDownload = () => {
    if (!coin) return;

    getProfitTransaction(coin, { begin, end, offset, limit }, true).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] hidden text-[28px] font-bold xs:block xs:pb-[30px] sm:mt-[20px] sm:text-[32px]">
        {t('profit.heading', { coin: coin?.split('-')[0]?.toUpperCase() })}
      </div>
      <div className="rounded-[12px] bg-black-500 p-[25px] text-white">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-[10px] text-[20px] font-bold">
            <img src={coin?.includes('trx') ? IcTron : IcUsdt} />
            {coin?.toUpperCase()}
          </div>
          <div className="hidden xs:flex xs:flex-col xs:items-end">
            <div className="text-[12px] text-grey-300">{t('profit.desc.total')}</div>
            <div className="text-[27px] font-bold">{bnFormat(balance?.total ?? null)}</div>
          </div>
        </div>
        <div className="mt-[10px] xs:hidden">
          <div className="text-[12px] text-grey-300">{t('profit.desc.total')}</div>
          <div className="text-[16px] font-bold">{bnFormat(balance?.total ?? null)}</div>
        </div>
        <div className="mt-[10px]">
          <div className="text-[12px] text-grey-300">{t('profit.desc.available')}</div>
          <div className="text-[14px]">{bnFormat(balance?.free ?? null)}</div>
        </div>
        <div className="mt-[10px]">
          <div className="text-[12px] text-grey-300">{t('profit.desc.locked')}</div>
          <div className="text-[14px]">{bnFormat(balance?.locked ?? null)}</div>
        </div>
        {accountInfo?.role === 'manager' && (
          <div className="mt-[15px] flex flex-row justify-end gap-[12px]">
            <Button size="small" onClick={onWithdrawalClick}>
              {t('profit.act.withdrawal')}
            </Button>
          </div>
        )}
      </div>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] text-[14px] dark:bg-black-800 md:px-[40px]">
        <div className="flex justify-between">
          <RoundDateFilter {...dateFilterProps} />
          <Button
            className="h-fit font-bold"
            size="small"
            appearance="secondary"
            onClick={onDownload}
          >
            {t('profit.act.download')}
          </Button>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {transactionArray?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {transactionArray &&
          transactionArray?.length > 0 &&
          (isBiggerThanMd ? (
            <Table data={transactionArray} navigateCallback={navigateCallback} />
          ) : (
            <Card data={transactionArray} navigateCallback={navigateCallback} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default Profit;
