import { useTranslation } from 'react-i18next';
import DatePicker from 'src/component/DatePicker';
import { DateRange } from 'src/model/DateFilter';

type Props = {
  label?: string;
  fromDate: Date;
  toDate: Date;
  setDateRange: (dateRange: DateRange) => void;
  onChange?: () => void;
};

const DateFilter = ({ label, fromDate, toDate, setDateRange, onChange }: Props) => {
  const { t } = useTranslation();

  const onCalendarChanged = (dateType: 'from' | 'to') => (newDate: Date) => {
    const newDateRange = {
      from: dateType === 'from' || newDate.getTime() < fromDate.getTime() ? newDate : undefined,
      to: dateType === 'to' || newDate.getTime() > toDate.getTime() ? newDate : undefined,
    };
    onChange && onChange();
    setDateRange(newDateRange);
  };

  return (
    <div className="text-dark-500">
      {label && <div className="text-[14px]">{label}</div>}
      <div className="flex gap-[20px]">
        <div className="box-border flex h-[40px] w-[124px] items-center gap-[8px] border-0 border-b-[1px] border-solid border-light-500 px-[0px] py-[8x] md:w-[160px] lg:w-[260px]">
          <div>{t('dateFilter.from')}</div>
          <DatePicker
            value={fromDate}
            onChange={onCalendarChanged('from')}
            className="!h-fit !w-fit !border-0 !p-[0px] leading-[22px]"
          />
        </div>
        <div className="box-border flex h-[40px] w-[124px] items-center gap-[8px] border-0 border-b-[1px] border-solid border-light-500 px-[0px] py-[8x] md:w-[160px] lg:w-[260px]">
          <div>{t('dateFilter.to')}</div>
          <DatePicker
            value={toDate}
            onChange={onCalendarChanged('to')}
            className="!h-fit !w-fit !border-0 !p-[0px] leading-[22px]"
          />
        </div>
      </div>
    </div>
  );
};

export default DateFilter;
