import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import Modal from 'src/component/Modal';
import { AccountUser } from 'src/model/AccountUser';
import { AskerLimitEditForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateUserAskerLimit } from 'src/service/userService';

type Props = {
  open: boolean;
  onClose: () => void;
  doRefresh: () => void;
  target: AccountUser;
};

const EditModal = ({ open, onClose, doRefresh, target }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<AskerLimitEditForm>({
    defaultValues: { askerLimit: String(target.fillsLimit) },
  });
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    methods.reset();
  };

  const onSubmit = (data: AskerLimitEditForm) => {
    updateUserAskerLimit(target.id, data.askerLimit)
      .then(() => {
        doRefresh();
        handleClose();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mb-[30px] text-[32px] font-bold">{t('askerLimit.desc.editHead')}</div>
        <FormNumberInput
          name="askerLimit"
          label={t('askerLimit.desc.limit')}
          hint={t('askerLimit.desc.limitHint')}
          allowMinus
          min={-1}
          required
        />
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={handleClose} type="button">
            {t('act.cancel')}
          </Button>
          <Button type="submit">{t('askerLimit.act.add')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditModal;
