import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { Balance } from 'src/model/Balance';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Balance[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (balance: Balance) => (
    <div key={balance.id}>
      <div className="px-0 py-[15px]">
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => {
              if (balance.user.side === 'ask')
                navigate(`${Page.UserAsk}/${balance.userId}/property/usdt`);
              else if (balance.user.side === 'bid')
                navigate(`${Page.UserBid}/${balance.userId}/property/usdt`);
              else navigate(`${Page.UserAdmin}/${balance.userId}/property/usdt`);
            }}
          >
            {balance.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('balance.desc.user')}</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => {
              if (balance.user.side === 'ask') navigate(`${Page.UserAsk}/${balance.userId}`);
              else if (balance.user.side === 'bid') navigate(`${Page.UserBid}/${balance.userId}`);
              else navigate(`${Page.UserAdmin}/${balance.userId}`);
            }}
          >
            {t(`order.desc.side.${balance.user.side}`)}-{balance.user.email}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('balance.desc.coin')}</div>
          <div>{balance.coin.toUpperCase()}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('balance.desc.total')}
          </div>
          <div>{bnFormat(balance.total)}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('balance.desc.free')}</div>
          <div>{bnFormat(balance.free)}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('balance.desc.locked')}
          </div>
          <div>{bnFormat(balance.locked)}</div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
