import {
  GetAssetIdResponse,
  GetAssetParams,
  GetAssetResponse,
  PatchAssetRequest,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getAssetList = async (params?: GetAssetParams) => {
  try {
    return await http.authGet<GetAssetResponse>('assets', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getAssetId = async (id: string) => {
  try {
    return await http.authGet<GetAssetIdResponse>(`assets/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const patchAsset = async (data: PatchAssetRequest) => {
  try {
    return await http.authPatch<void>('assets', { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getAssetList,
  getAssetId,
  patchAsset,
};
