import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { MessageQueryForm } from 'src/model/Form';
import { Message } from 'src/model/Message';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getMessageList } from 'src/service/messageService';
import Card from './component/Card';
import MessageModal from './component/MessageModal';
import Table from './component/Table';

const MessageAdmin = () => {
  const { t, ready } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminMessage: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const [side, setSide] = useState<string>(filter.side ?? '');
  const [email, setEmail] = useState<string>(filter.email ?? '');
  const [list, setList] = useState<Message[]>();
  const [modalTarget, setModalTarget] = useState<Message>();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<MessageQueryForm>({
    defaultValues: {
      email: filter.email,
    },
  });

  useEffect(() => {
    if (side.length !== 0 && email.length === 0) return;
    getMessageList(
      email.length === 0 ? { user: state?.userId } : { user: state?.userId, side, email },
      { begin, end, offset, limit },
    )
      .then((res) => {
        setList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [side, email, begin, end, offset, limit, state]);

  const onStatusFilterChange = (value: string) => {
    setSide(value);
    paginationProps.setPage(1);
  };

  const onQuery = (data: MessageQueryForm) => {
    setEmail(data.email);
    paginationProps.setPage(1);
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] text-[32px] font-bold">{t('messageAdmin.heading')}</div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex justify-between gap-[30px] px-[15px] py-[0px] md:mt-[30px] md:gap-[60px] md:px-[30px] md:py-[0px]">
        <DateFilter
          label={t('messageAdmin.desc.createDate')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
        <div>
          <Button onClick={() => navigate(Page.MessageCanned)}>
            {t('messageAdmin.act.manageCanned')}
          </Button>
        </div>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <Form
          methods={methods}
          onSubmit={onQuery}
          className="relative mb-[10px] flex items-end xs:flex-wrap xs:gap-x-[25px]"
        >
          <Select
            value={side}
            label={t('messageAdmin.desc.side')}
            onChange={onStatusFilterChange}
            defaultValue={filter.side ?? ''}
            className="w-[119px] md:w-[140px] lg:w-[240px]"
          >
            <SelectOption value={'ask'}>{t('messageAdmin.desc.asker')}</SelectOption>
            <SelectOption value={'bid'}>{t('messageAdmin.desc.bidder')}</SelectOption>
          </Select>
          <FormSearchInput
            name="email"
            placeholder="Email"
            onQuery={() => {
              onQuery({ email: '' });
              setSide('');
            }}
          />
          <Button appearance="secondary" size="small" type="submit" disabled={side === ''}>
            {t('messageAdmin.act.search')}
          </Button>
          <div className="flex w-[120px] items-center gap-[8px]">
            <img className="h-[24px] w-[24px]" src={IcLimit} />
            <div className="grow">
              <Select
                value={limit.toString()}
                onChange={(v) => {
                  setLimit(Number(v));
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="5">{'5'}</SelectOption>
                <SelectOption value="10">{'10'}</SelectOption>
                <SelectOption value="50">{'50'}</SelectOption>
                <SelectOption value="100">{'100'}</SelectOption>
              </Select>
            </div>
          </div>
        </Form>
        <div className="h-[1px] bg-light-200" />
        {list?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {list &&
          list.length > 0 &&
          (isBiggerThanMd ? (
            <Table list={list} onClick={(message) => setModalTarget(message)} />
          ) : (
            <Card list={list} onClick={(message) => setModalTarget(message)} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <MessageModal
        open={modalTarget !== undefined}
        onClose={() => setModalTarget(undefined)}
        message={modalTarget}
      />
    </div>
  );
};

export default MessageAdmin;
