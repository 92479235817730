import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import { MediaQuery } from 'src/constant/Media';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { Balance } from 'src/model/Balance';
import { QueryForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getBalances } from 'src/service/balanceService';
import Card from './component/Card';
import Table from './component/Table';

const BalanceAdmin = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const { adminBalance: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<QueryForm>({
    defaultValues: {
      id: filter.id,
    },
  });
  const [balances, setBalances] = useState<Balance[]>();
  const [userQuery, setUserQuery] = useState<string>();

  useEffect(() => {
    getBalances({ email: userQuery, offset, limit, sort: filterSorting })
      .then((res) => {
        setBalances(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [offset, limit, filterSorting, userQuery]);

  const onSortingFilterChange = (value: string) => {
    setFilterSorting(value);
    paginationProps.setPage(1);
  };

  const onQuery = (data: QueryForm) => {
    setUserQuery(data.id);
    paginationProps.setPage(1);
  };

  const onDownload = () => {
    getBalances({ email: userQuery, offset, limit, sort: filterSorting }, true).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('balance.heading')}
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px] md:px-[40px] md:py-[25px]">
        <div className="flex flex-wrap items-center justify-between gap-x-[10px]">
          <Form
            methods={methods}
            onSubmit={onQuery}
            className="flex flex-wrap items-center gap-[30px] pb-[25px]"
          >
            <FormSearchInput
              name="id"
              placeholder={t('balance.desc.searchInputHelper')}
              onQuery={onQuery}
            />
            <Button appearance="secondary" size="small" type="submit">
              {t('balance.act.search')}
            </Button>
            <div className="flex w-[120px] gap-[8px]">
              <img src={IcLimit} />
              <div className="grow">
                <Select
                  value={limit.toString()}
                  onChange={(v) => {
                    setLimit(Number(v));
                    paginationProps.setPage(1);
                  }}
                >
                  <SelectOption value="5">{'5'}</SelectOption>
                  <SelectOption value="10">{'10'}</SelectOption>
                  <SelectOption value="50">{'50'}</SelectOption>
                  <SelectOption value="100">{'100'}</SelectOption>
                </Select>
              </div>
            </div>
            <Button
              className="font-bold"
              size="small"
              appearance="secondary"
              type="button"
              onClick={onDownload}
            >
              {t('trade.desc.download')}
            </Button>
          </Form>
          <div className="pb-[15px]">
            <SortingSelect
              defaultValue={filter.sorting ?? 'default'}
              onChange={onSortingFilterChange}
            >
              <SelectOption value="default">{t('balance.desc.sortingOption.default')}</SelectOption>
              <SelectOption value="-total">
                {t('balance.desc.sortingOption.totalDesc')}
              </SelectOption>
              <SelectOption value="total">{t('balance.desc.sortingOption.totalAsce')}</SelectOption>
            </SortingSelect>
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {balances?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {balances &&
          balances.length > 0 &&
          (isBiggerThanMd ? <Table data={balances} /> : <Card data={balances} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default BalanceAdmin;
