import { format } from 'date-fns';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'src/component/Checkbox';
import { Page } from 'src/constant/Page';
import { UserOrder } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<UserOrder>;
  onCheck: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void;
};

const Card = ({ data, onCheck }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: UserOrder) => (
    <div key={order.id}>
      <div className="px-0 py-[15px]">
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Trade}/${order.id}`)}
          >
            {order.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('trade.desc.status')}</div>
          <div>{t(`trade.desc.statusDisplay.${order.status}`)}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('trade.desc.seller')}</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => {
              if (order.user.side === 'ask') navigate(`${Page.UserAsk}/${order.userId}`);
              else if (order.user.side === 'bid') navigate(`${Page.UserBid}/${order.userId}`);
              else navigate(`${Page.UserAdmin}/${order.userId}`);
            }}
          >
            {t(`trade.desc.side.${order.user.side}`)}-{order.user.email}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('trade.desc.amount')}</div>
          <div>{`${bnFormat(order.openAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('trade.desc.price')}</div>
          <div>{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('trade.desc.limit')}</div>
          <div>{`${bnFormat(order.minTotal)} - ${
            order.maxTotal
          } ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('trade.desc.updateTime')}
          </div>
          <div>{format(Date.parse(order.updatedAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('trade.desc.priority')}
          </div>
          <Checkbox checked={order.priority === 1} onChange={onCheck(order.id)} />
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
