import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  open: boolean;
  onClose: () => void;
  withdrawal?: WithdrawalRecord;
  onConfirm: () => void;
};

const ApproveModal = ({ open, onClose, withdrawal, onConfirm }: Props) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onClose();
    onConfirm();
  };

  if (!withdrawal) return <></>;

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="mb-[25px] text-[32px] font-bold text-black-900 dark:text-white">
          {t('withdrawalRecord.desc.approve')}
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div className="text-[14px]">{withdrawal.id}</div>
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.user')}
          </div>
          <div className="text-[14px]">
            {t(`withdrawalRecord.desc.side.${withdrawal.user?.side}`)}-{withdrawal.user?.email}
          </div>
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.amount')}
          </div>
          <div className="text-[14px]">{bnFormat(withdrawal.amount)}</div>
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.fee')}
          </div>
          <div className="text-[14px]">{bnFormat(withdrawal.fee)}</div>
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.coin')}
          </div>
          <div className="text-[14px]">
            {(withdrawal.network
              ? `${withdrawal.coin}-${withdrawal.network}`
              : withdrawal.coin
            ).toUpperCase()}
          </div>
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.address')}
          </div>
          <div className="text-[14px]">{withdrawal.address}</div>
        </div>
        <div className="mb-[5px] flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.withdrawalDate')}
          </div>
          <div className="text-[14px]">
            {format(Date.parse(withdrawal.createdAt), 'yyyy/MM/dd HH:mm:ss')}
          </div>
        </div>
        <div className="mt-[40px] text-end">
          <Button onClick={handleConfirm}>{t('withdrawalRecord.desc.doApprove')}</Button>
        </div>
      </div>
    </Modal>
  );
};

export default ApproveModal;
