import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FillAdmin } from 'src/model/Order';

type Props = {
  fill: FillAdmin;
};

const FillStatusLabel = ({ fill }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-wrap gap-1">
      <div
        className={classNames(
          'w-fit rounded-[4px] px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white',
          {
            'bg-khaki': fill.status === 'executed',
            'bg-dark-500': fill.status === 'sent',
            'bg-green': fill.status === 'received',
            'bg-grey-500': fill.status === 'canceled',
          },
        )}
      >
        {t(`fill.desc.statusDisplay.${fill.status}`)}
      </div>
      {fill.rejectedAt && fill.status === 'executed' && (
        <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
          {t('fill.desc.statusDisplay.rejected')}
        </div>
      )}
    </div>
  );
};

export default FillStatusLabel;
