import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { DepositRecord } from 'src/model/Deposit';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: DepositRecord[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (deposit: DepositRecord) => (
    <div key={deposit.id}>
      <div className="flex flex-row">
        <div className="box-border flex w-[25%] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.DepositRecord}/${deposit.id}`)}
          >
            {deposit.id}
          </div>
        </div>
        <div className="box-border flex w-[25%] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {deposit.user ? (
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (deposit.user?.side === 'ask') navigate(`${Page.UserAsk}/${deposit.userId}`);
                else if (deposit.user?.side === 'bid')
                  navigate(`${Page.UserBid}/${deposit.userId}`);
                else navigate(`${Page.UserAdmin}/${deposit.userId}`);
              }}
            >
              {t(`depositRecord.desc.side.${deposit.user.side}`)}-{deposit.user.email}
            </div>
          ) : (
            <div>{t('depositRecord.desc.system')}</div>
          )}
        </div>
        <div className="box-border flex w-[12.5%] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {bnFormat(deposit.amount)}
        </div>
        <div className="box-border flex w-[12.5%] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {(deposit.network ? `${deposit.coin}-${deposit.network}` : deposit.coin).toUpperCase()}
        </div>
        <div className="box-border flex w-[25%] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          <div>
            <span>{`${format(Date.parse(deposit.createdAt), 'yyyy/MM/dd')} `}</span>
            <span className="break-keep">{format(Date.parse(deposit.createdAt), 'HH:mm:ss')}</span>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[25%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          ID
        </div>
        <div className="box-border w-[25%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('depositRecord.desc.user')}
        </div>
        <div className="box-border w-[12.5%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('depositRecord.desc.amount')}
        </div>
        <div className="box-border w-[12.5%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('depositRecord.desc.coin')}
        </div>
        <div className="box-border w-[25%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('depositRecord.desc.depositDate')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
