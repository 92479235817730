import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { CannedMessage } from 'src/model/Message';

type Props = {
  list: Array<CannedMessage>;
  onEdit: (data: CannedMessage) => void;
  onDelete: (data: CannedMessage) => void;
};

const Table = ({ list, onEdit, onDelete }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (data: CannedMessage) => (
    <div key={data.id}>
      <div className="flex flex-row">
        <div className="box-border w-2/12 break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          {data.id}
        </div>
        <div className="box-border w-2/12 break-all px-[12px] py-[16px] text-[14px]">
          {data.name}
        </div>
        <div className="box-border w-2/12 break-all px-[12px] py-[16px] text-[14px]">
          {data.subject}
        </div>
        <div className="box-border w-5/12 whitespace-pre-line px-[12px] py-[16px] text-[14px]">
          {data.content}
        </div>
        <div className="box-border flex w-1/12 flex-col flex-wrap items-center justify-center gap-2 px-[12px] py-[16px]">
          <div>
            <Button size="small" appearance="secondary" onClick={() => onEdit(data)}>
              {t('messageCannedAdmin.act.edit')}
            </Button>
          </div>
          <div>
            <Button size="small" appearance="secondary" onClick={() => onDelete(data)}>
              {t('messageCannedAdmin.act.delete')}
            </Button>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-2/12 px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          ID
        </div>
        <div className="box-border w-2/12 px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('messageCannedAdmin.desc.name')}
        </div>
        <div className="box-border w-2/12 px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('messageCannedAdmin.desc.subject')}
        </div>
        <div className="box-border w-5/12 px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('messageCannedAdmin.desc.content')}
        </div>
        <div className="w-1/12" />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{list.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
