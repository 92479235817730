import classNames from 'classnames';
import { format } from 'date-fns';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { BalanceTransaction } from 'src/model/Property';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BalanceTransaction[];
  navigateCallback: (transaction: BalanceTransaction) => void;
};

const Table = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (transaction: BalanceTransaction) => () => {
    navigateCallback(transaction);
  };

  const elementMapFunction = (
    transaction: BalanceTransaction,
    index: number,
    array: BalanceTransaction[],
  ) => (
    <div key={transaction.id} className="flex flex-col">
      <div className="flex flex-row">
        <div className="box-border w-[25%] break-words pr-[10px] text-grey-500 dark:text-grey-700">
          {format(Date.parse(transaction.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div className="box-border w-[25%] break-words pr-[10px]">
          {bnFormat(transaction.amount)}
        </div>
        <div className="box-border w-[25%] break-words pr-[10px]">
          {bnFormat(transaction.balance)}
        </div>
        <div className="box-border flex w-[25%] flex-row items-start gap-[10px] break-words pr-[10px] text-[14px]">
          {t(`userProperty.desc.transactionType.${camelCase(transaction.type)}`)}
          {!['manipulation', 'on-chain-fee', 'init'].includes(transaction.type) && (
            <Button appearance="text" onClick={onDetailClick(transaction)}>
              {t('userProperty.act.detail')}
            </Button>
          )}
        </div>
      </div>
      <div
        className={classNames('my-[15px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-[25%]">{t('userProperty.desc.time')}</div>
        <div className="w-[25%]">{t('userProperty.desc.amount')}</div>
        <div className="w-[25%]">{t('userProperty.desc.balance')}</div>
        <div className="w-[25%]">{t('userProperty.desc.information')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
