import rebateEndpoint from 'src/api/rebateEndpoint';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getRebateList = async (
  type: 'parent' | 'agent',
  params: {
    user?: string;
    side?: string;
    email?: string;
  },
  paginationParams?: PaginationParams,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await rebateEndpoint.getRebateList(
      {
        ...paginationParams,
        ...params,
        order: 'desc',
        type,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: type === 'parent' ? 'adminParentRebate' : 'adminAgentRebate',
        filter: {
          id: undefined,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    if (csv) toCsvFile(`${type}-rebate.csv`, res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getRebateById = async (id: string, save?: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await rebateEndpoint.getRebateById(id);

    if (save)
      if (res.data.type === 'parent')
        dispatch(updateFilter({ key: 'adminParentRebate', filter: { id } }));
      else dispatch(updateFilter({ key: 'adminAgentRebate', filter: { id } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getRebateFillById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await rebateEndpoint.getRebateIdFill(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
