import classNames from 'classnames';
import { ChangeEvent, forwardRef, TextareaHTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';

export type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  error?: boolean | string;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  ({ label, error, className, disabled, autoComplete = 'off', onChange, ...props }, ref) => {
    const [value, setValue] = useState<string>();
    const onInput = (v: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(v.target.value);
      onChange && onChange(v);
    };

    return (
      <div>
        {label && (
          <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">{label}</div>
        )}
        <textarea
          className={twMerge(
            'box-border h-[300px] w-full resize-y rounded-[5px] border-[1px] border-solid border-light-500 bg-transparent p-[15px] text-[1em] text-black-900 focus:border-turquoise focus:outline-none dark:border-grey-500 dark:text-white dark:focus:border-aqua',
            className,
            classNames({
              'border-crimson dark:border-red': !!error,
              'border-dark-500/30 dark:border-grey-500/30': disabled,
            }),
          )}
          disabled={disabled}
          autoComplete={autoComplete}
          ref={ref}
          value={value}
          onChange={onInput}
          {...props}
        />
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
