import { GetCryptosResponse, PutCryptosRequest, PutCryptosResponse } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const listCryptos = async () => {
  try {
    return await http.authGet<GetCryptosResponse>('cryptos');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const updateCrypto = async (id: string, data: PutCryptosRequest) => {
  try {
    return await http.authPut<PutCryptosResponse, PutCryptosRequest>(`cryptos/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  listCryptos,
  updateCrypto,
};
