import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Asset } from 'src/model/Asset';
import { getAssetList } from 'src/service/homeService';
import { bn, bnFormat } from 'src/util/bigNumber';

const SystemBalance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [assetList, setAssetList] = useState<Asset[]>();

  useEffect(() => {
    getAssetList().then((res) => setAssetList(res));
  }, []);

  return (
    <div>
      <div className="bg-white p-[30px] text-black-900">
        <div className="mb-[20px] text-[24px] font-bold leading-[1.4]">
          {t('home.systemBalance.title')}
        </div>
        <div className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 md:justify-start">
          <div className="font-bold md:w-[calc(100%/6)]" />
          <div
            className={classNames(
              'font-bold text-grey-700 dark:text-grey-300',
              'text-right md:flex md:w-[calc(100%/6*5)] md:text-left',
            )}
          >
            <div className="md:w-[50%] md:pl-[12px]">{t('home.systemBalance.total')}</div>
            <div className="md:w-[50%] md:pl-[12px]">{t('home.systemBalance.systemTotal')}</div>
            <div className={classNames('text-[14px] md:text-[16px]', 'md:w-[50%] md:pl-[12px]')}>
              {t('home.systemBalance.systemFree')}
            </div>
            <div className={classNames('text-[14px] md:text-[16px]', 'md:w-[50%] md:pl-[12px]')}>
              {t('home.systemBalance.frozenBalance')}
            </div>
            <div className="md:w-[50%] md:pl-[12px]" />
          </div>
        </div>
        {assetList &&
          assetList.map((v) => (
            <div
              key={v.id}
              className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 md:justify-start"
            >
              <div className="font-bold md:w-[calc(100%/6)]">{`${v.id.toUpperCase()}`}</div>
              <div className="text-right md:flex md:w-[calc(100%/6*5)] md:text-left">
                <div className="md:w-[50%] md:pl-[12px]">{bnFormat(v.total)}</div>
                <div className="md:w-[50%] md:pl-[12px]">
                  {bn(v.free).plus(v.locked).toFormat()}
                </div>
                <div
                  className={classNames(
                    'text-[14px] md:text-[16px]',
                    'text-dark-500 md:text-black-900',
                    'md:w-[50%] md:pl-[12px]',
                  )}
                >
                  {bnFormat(v.free)}
                </div>
                <div
                  className={classNames(
                    'text-[14px] md:text-[16px]',
                    'text-dark-500 md:text-black-900',
                    'md:w-[50%] md:pl-[12px]',
                  )}
                >
                  {bnFormat(v.frozen)}
                </div>
                <div className="text-right md:w-[50%] md:pl-[12px]">
                  <Button
                    className="min-w-fit px-[10px] py-[0]"
                    appearance="secondary"
                    onClick={() => navigate(`${Page.Home}/${v.id}/property`)}
                  >
                    {t('home.systemBalance.transaction')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className={classNames('bg-white p-[30px] text-black-900', 'mt-[30px]')}>
        <div className="mb-[20px] text-[24px] font-bold leading-[1.4]">
          {t('home.systemBalance.title2')}
        </div>
        <div className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 md:justify-start">
          <div className="font-bold md:w-[calc(100%/6)]" />
          <div
            className={classNames(
              'font-bold text-grey-700 dark:text-grey-300',
              'text-right md:flex md:w-[calc(100%/6*5)] md:text-left',
            )}
          >
            <div className="md:w-[50%] md:pl-[12px]">{t('home.systemBalance.coldBalance')}</div>
            <div className="md:w-[50%] md:pl-[12px]" />
          </div>
        </div>
        {assetList &&
          assetList.map((v) => (
            <div
              key={v.id}
              className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 md:justify-start"
            >
              <div className="font-bold md:w-[calc(100%/6)]">{`${v.id.toUpperCase()}`}</div>
              <div className="text-right md:flex md:w-[calc(100%/6*5)] md:text-left">
                <div className="md:w-[50%] md:pl-[12px]">{bnFormat(v.coldWallet)}</div>
                <div className="text-right md:w-[50%] md:pl-[12px]">
                  <Button
                    className="min-w-fit px-[10px] py-[0]"
                    appearance="secondary"
                    onClick={() => navigate(`${Page.Home}/${v.id}/frozen`)}
                  >
                    {t('home.systemBalance.transaction')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default SystemBalance;
