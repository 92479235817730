import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from 'src/component/Alert';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormNumberInput from 'src/component/FormNumberInput';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import { Severity } from 'src/constant/Notification';
import { Page } from 'src/constant/Page';
import useQuery from 'src/hook/useQuery';
import { PostWithdrawalsPreviewResponse } from 'src/model/Api';
import { Balance } from 'src/model/Balance';
import { WithdrawalForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { createWithdrawal, getProfit, previewWithdrawal } from 'src/service/homeService';
import { bn, bnFixed, bnFormat } from 'src/util/bigNumber';

type Query = {
  coinId?: string;
  network?: string;
  address?: string;
  amount?: string;
  note?: string;
};

const WithdrawalProfit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const query = useQuery<Query>();
  const { crypto } = useSelector((rootState: RootState) => rootState.coin);
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const [coinDecimal, setCoinDecimal] = useState<number>(0);
  const methods = useForm<WithdrawalForm>();
  const formData = useWatch({ control: methods.control });
  const [previewTask, setPreviewTask] = useState<number>();
  const [previewData, setPreviewData] = useState<PostWithdrawalsPreviewResponse>();
  const [balance, setBalance] = useState<Balance>();

  useEffect(() => {
    if (!formData.coin) return;

    getProfit()
      .then((res) => setBalance(res.find((v) => v.coin === formData.coin)))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [formData.coin]);

  useEffect(() => {
    setPreviewData(undefined);
    if (!formData.coin || !formData.address || formData.address.length === 0) return;

    if (previewTask) window.clearTimeout(previewTask);

    const requestData = {
      coin: formData.coin,
      network: formData.network,
      address: formData.address,
    };

    const task = window.setTimeout(() => {
      previewWithdrawal(requestData)
        .then((res) => {
          setPreviewData(res);
          setPreviewTask(undefined);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
    }, 500);

    setPreviewTask(task);

    return () => window.clearTimeout(task);
  }, [formData.address]);

  useEffect(() => {
    setPreviewData(undefined);
    if (!formData.coin || !formData.address || formData.address.length === 0) return;

    previewWithdrawal({
      coin: formData.coin,
      network: formData.network,
      address: formData.address,
    })
      .then((res) => {
        setPreviewData(res);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [formData.coin, formData.network]);

  useEffect(() => {
    if (crypto.length === 0) return;

    let currentCoin = 'usdt';
    if (query.coinId && crypto.map((v) => v.id).includes(query.coinId.toLowerCase())) {
      methods.setValue('coin', query.coinId.toLowerCase());
      currentCoin = query.coinId.toLowerCase();
    } else methods.setValue('coin', 'usdt');

    const currentCrypto = crypto.find((v) => v.id === currentCoin);
    if (currentCrypto?.networks.length === 1 && currentCrypto.networks[0].network === '')
      methods.setValue('network', '');
    else if (
      query.network &&
      currentCrypto?.networks.map((v) => v.network).includes(query.network.toLowerCase())
    )
      methods.setValue('network', query.network.toLowerCase());
    else methods.setValue('network', 'trc20');

    setCoinDecimal(crypto.find((v) => v.id === currentCoin)?.decimal ?? 0);
  }, [crypto, query]);

  useEffect(() => {
    if (previewData === undefined) return;

    if (!previewData.isAddressValid)
      methods.setError('address', { message: t('withdrawal.desc.addressInvalidate') });
    else methods.clearErrors('address');
  }, [formData, previewData, t]);

  useEffect(() => {
    const { amount } = methods.getValues();
    if (balance === undefined) return;

    if (bn(amount).gt(bn(balance.free)))
      methods.setError('amount', { message: t('withdrawal.desc.amountGreaterThan') });
    else methods.clearErrors('amount');
  }, [formData, balance, t]);

  const onCoinChange = (coin: string) => {
    methods.setValue('coin', coin);
    if (coin === 'usdt') methods.setValue('network', 'trc20');
    else methods.setValue('network', '');
  };

  const onSubmit = (data: WithdrawalForm) => {
    createWithdrawal(
      {
        coin: data.coin,
        network: data.network,
        address: data.address,
        amount: Number.parseFloat(data.amount),
        note: data.note.length > 0 ? data.note : undefined,
      },
      data.code,
    )
      .then(() => {
        navigate(`${Page.UserAdmin}/withdrawal/success`, {
          replace: true,
          state: { userId: 'xx' },
        });
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (accountInfo && accountInfo.role !== 'manager') navigate(Page.Home);

  return (
    <div>
      <BackButton />
      <div className="mt-[10px] flex flex-col items-start xs:flex-row xs:justify-between xs:justify-items-end sm:mt-[20px]">
        <div className="text-[28px] font-bold sm:text-[32px]">{t('withdrawal.heading')}</div>
      </div>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mt-[30px] flex flex-col gap-[20px]">
          <div className="flex flex-col gap-[20px] xs:flex-row xs:gap-[30px]">
            {formData.coin && (
              <div className="grow">
                <FormSelect
                  name="coin"
                  label={t('withdrawal.desc.coin')}
                  onChange={onCoinChange}
                  asterisked
                >
                  {crypto.map((v) => (
                    <SelectOption key={v.id} value={v.id}>
                      {v.id.toUpperCase()}
                    </SelectOption>
                  ))}
                </FormSelect>
              </div>
            )}
            {formData.network && (
              <div className="grow">
                <FormSelect name="network" label={t('withdrawal.desc.selectNetwork')} asterisked>
                  {crypto
                    .find((item) => item.id === formData.coin)
                    ?.networks.map((v) => (
                      <SelectOption key={v.network} value={v.network}>
                        {v.network.toUpperCase()}
                      </SelectOption>
                    ))}
                </FormSelect>
              </div>
            )}
          </div>
          <div className="relative xs:flex xs:flex-row">
            <div className="grow">
              <FormInput
                name="address"
                label={t('withdrawal.desc.address')}
                maxLength={256}
                placeholder={t('withdrawal.desc.recipientAddress', {
                  coin: formData.coin?.toUpperCase(),
                })}
                required
                asterisked
                defaultValue={query.address}
              />
            </div>
          </div>
          {previewData && previewData.txCount === 0 && (
            <Alert severity={Severity.Clear}>
              {previewData.checksum === false
                ? t('withdrawal.desc.newAndUnchecksummedAddressWarning')
                : t('withdrawal.desc.newAddressWarning')}
            </Alert>
          )}
          {previewData && previewData.txCount > 0 && previewData.checksum === false && (
            <Alert severity={Severity.Clear}>
              {t('withdrawal.desc.unchecksummedAddressWarning')}
            </Alert>
          )}
          <div className="relative">
            <FormNumberInput
              name="amount"
              label={t('withdrawal.desc.amount')}
              placeholder={t('withdrawal.desc.receiveAmount')}
              hint={
                balance === undefined
                  ? `${t('withdrawal.desc.available')}: -`
                  : `${t('withdrawal.desc.available')}: ${bnFormat(
                      balance.free,
                    )} ${balance.coin.toUpperCase()}`
              }
              decimal={coinDecimal}
              required
              asterisked
              defaultValue={query.amount}
            />
            <Button
              appearance="text"
              className="absolute right-0 top-0"
              type="button"
              disabled={balance === undefined}
              onClick={() => {
                if (!balance?.free) return;
                methods.setValue('amount', bnFixed(balance.free));
              }}
            >
              {t('withdrawal.act.max')}
            </Button>
          </div>
          <FormInput
            name="note"
            label={t('withdrawal.desc.memo')}
            maxLength={64}
            hint={t('withdrawal.desc.memoRule')}
            defaultValue={query.note}
          />
          <FormInput label={t('withdrawal.desc.securityCode')} name="code" required asterisked />
          <div className="text-[14px] text-grey-700 dark:text-grey-300">
            <div>{t('withdrawal.desc.fee')}</div>
            <div className="text-[16px] text-black-700 dark:text-white">
              {previewData === undefined
                ? `-`
                : `${previewData.fee} ${previewData.coin.toUpperCase()}`}
            </div>
          </div>
        </div>
        <div className="mt-[30px] text-grey-700 dark:text-grey-300">
          <div className="text-[16px]">{t('withdrawal.desc.notice')}</div>
          <ul>
            {t<string, string[]>('withdrawal.desc.noticeItems', {
              returnObjects: true,
              defaultValue: [],
            }).map((value, index) => (
              <li key={`notice${index}`} className="pb-[10px] text-[14px]">
                {value}
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-[40px] text-end">
          <Button
            size="large"
            type="submit"
            disabled={
              !previewData?.isAddressValid || Object.keys(methods.formState.errors).length !== 0
            }
          >
            {t('act.submit')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default WithdrawalProfit;
