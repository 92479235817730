import { GetConfigIdResponse, GetConfigsResponse, PutConfigsRequest } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getConfigs = async () => {
  try {
    return await http.authGet<GetConfigsResponse>('configs');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getConfigId = async (id: string) => {
  try {
    return await http.authGet<GetConfigIdResponse>(`configs/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const updateConfigs = async (id: string, data: PutConfigsRequest) => {
  try {
    await http.authPut<void>(`configs/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getConfigs,
  getConfigId,
  updateConfigs,
};
