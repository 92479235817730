import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Balance } from 'src/model/Balance';
import { RootState } from 'src/redux/store';
import { getProfit } from 'src/service/homeService';
import { bnFormat } from 'src/util/bigNumber';

const Profit = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [profitBalance, setProfitBalance] = useState<Balance[]>();
  const [feeBalance, setFeeBalance] = useState<Balance[]>();
  const { crypto } = useSelector((rootState: RootState) => rootState.coin);

  useEffect(() => {
    if (crypto.length === 0) return;
    getProfit().then((res) => {
      setProfitBalance(
        res.filter((v) =>
          crypto
            .filter((o) => o.isTradable)
            .map((o) => o.id)
            .includes(v.coin),
        ),
      );
      setFeeBalance(
        res.filter((v) =>
          crypto
            .filter((o) => !o.isTradable)
            .map((o) => o.id)
            .includes(v.coin),
        ),
      );
    });
  }, [crypto]);

  return (
    <div className="flex flex-col gap-[30px]">
      <div className="bg-white p-[30px] text-black-900">
        <div className="mb-[20px] text-[24px] font-bold leading-[1.4]">
          {t('home.profit.title1')}
        </div>
        <div className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 sm:justify-start">
          <div className="font-bold sm:w-[calc(100%/4)]" />
          <div
            className={classNames(
              'font-bold text-grey-700 dark:text-grey-300',
              'text-right sm:flex sm:w-[calc(100%/4*3)] sm:text-left',
            )}
          >
            <div className="sm:w-[50%] sm:pl-[12px]">{t('home.profit.total')}</div>
            <div className="text-[14px] sm:w-[50%] sm:pl-[12px] sm:text-[16px]">
              {t('home.profit.free')}
            </div>
            <div className="sm:w-[50%] sm:pl-[12px]" />
          </div>
        </div>
        {profitBalance &&
          profitBalance.map((v) => (
            <div
              key={v.id}
              className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 sm:justify-start"
            >
              <div className="font-bold sm:w-[calc(100%/4)]">{`${v.coin.toUpperCase()}`}</div>
              <div className="text-right sm:flex sm:w-[calc(100%/4*3)] sm:text-left">
                <div className="sm:w-[50%] sm:pl-[12px]">{bnFormat(v.total)}</div>
                <div
                  className={classNames(
                    'text-[14px] sm:text-[16px]',
                    'text-dark-500 sm:text-black-900',
                    'sm:w-[50%] sm:pl-[12px]',
                  )}
                >
                  {bnFormat(v.free)}
                </div>
                <div className="flex justify-end gap-[10px] sm:w-[50%] sm:pl-[12px]">
                  <Button
                    className="min-w-fit px-[10px] py-[0]"
                    appearance="secondary"
                    onClick={() => navigate(`${Page.Home}/${v.coin}/profit`)}
                  >
                    {t('home.profit.transaction')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="bg-white p-[30px] text-black-900">
        <div className="mb-[20px] text-[24px] font-bold leading-[1.4]">
          {t('home.profit.title2')}
        </div>
        <div className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 sm:justify-start">
          <div className="font-bold sm:w-[calc(100%/4)]" />
          <div
            className={classNames(
              'font-bold text-grey-700 dark:text-grey-300',
              'text-right sm:flex sm:w-[calc(100%/4*3)] sm:text-left',
            )}
          >
            <div className="sm:w-[50%] sm:pl-[12px]">{t('home.profit.total')}</div>
            <div className="text-[14px] sm:w-[50%] sm:pl-[12px] sm:text-[16px]">
              {t('home.profit.free')}
            </div>
            <div className="sm:w-[50%] sm:pl-[12px]" />
          </div>
        </div>
        {feeBalance &&
          feeBalance.map((v) => (
            <div
              key={v.id}
              className="flex justify-between border-[0px] border-b-[1px] border-solid border-light-200 py-[16px] pl-0 pr-[12px] leading-[1.5] dark:border-dark-700 sm:justify-start"
            >
              <div className="font-bold sm:w-[calc(100%/4)]">{`${v.coin.toUpperCase()}`}</div>
              <div className="text-right sm:flex sm:w-[calc(100%/4*3)] sm:text-left">
                <div className="sm:w-[50%] sm:pl-[12px]">{bnFormat(v.total)}</div>
                <div
                  className={classNames(
                    'text-[14px] sm:text-[16px]',
                    'text-dark-500 sm:text-black-900',
                    'sm:w-[50%] sm:pl-[12px]',
                  )}
                >
                  {bnFormat(v.free)}
                </div>
                <div className="flex justify-end gap-[10px] sm:w-[50%] sm:pl-[12px]">
                  <Button
                    className="min-w-fit px-[10px] py-[0]"
                    appearance="secondary"
                    onClick={() => navigate(`${Page.Home}/${v.coin}/profit`)}
                  >
                    {t('home.profit.transaction')}
                  </Button>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Profit;
