import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { RootState } from 'src/redux/store';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<WithdrawalRecord>;
  onApprove: (v: WithdrawalRecord) => void;
  onCancel: (id: string) => void;
  onVerifyClick: (record: WithdrawalRecord) => void;
};

const Card = ({ data, onApprove, onCancel, onVerifyClick }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);

  const elementMapFunction = (withdrawal: WithdrawalRecord) => (
    <div key={withdrawal.id}>
      <div className="flex flex-col gap-[4px] px-0 py-[20px]">
        <div className="flex items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.WithdrawalRecord}/${withdrawal.id}`)}
          >
            {withdrawal.id}
          </div>
        </div>
        <div className="flex items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.user')}
          </div>
          {withdrawal.user ? (
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (withdrawal.user?.side === 'ask')
                  navigate(`${Page.UserAsk}/${withdrawal.userId}`);
                else if (withdrawal.user?.side === 'bid')
                  navigate(`${Page.UserBid}/${withdrawal.userId}`);
                else navigate(`${Page.UserAdmin}/${withdrawal.userId}`);
              }}
            >
              {t(`withdrawalRecord.desc.side.${withdrawal.user?.side}`)}-{withdrawal.user?.email}
            </div>
          ) : (
            <div>{t('withdrawalRecord.desc.profitWithdrawal')}</div>
          )}
        </div>
        <div className="flex items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.amount')}
          </div>
          <div>{bnFormat(withdrawal.amount)}</div>
        </div>
        <div className="flex items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.coin')}
          </div>
          <div>
            {(withdrawal.network
              ? `${withdrawal.coin}-${withdrawal.network}`
              : withdrawal.coin
            ).toUpperCase()}
          </div>
        </div>
        <div className="flex items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.status')}
          </div>
          <div className="flex gap-[5px]">
            <div>{t(`withdrawalRecord.desc.statusDisplay.${withdrawal.status}`)}</div>
            {withdrawal.status === 'created' && accountInfo?.id === withdrawal.userId && (
              <Button appearance="text" type="button" onClick={() => onVerifyClick(withdrawal)}>
                {t('withdrawalRecord.desc.verify')}
              </Button>
            )}
          </div>
        </div>
        <div className="flex items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.withdrawalDate')}
          </div>
          <div>{format(Date.parse(withdrawal.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="flex justify-end gap-[10px]">
          {withdrawal.status === 'verified' && (
            <Button appearance="text" onClick={() => onApprove(withdrawal)}>
              {t('withdrawalRecord.desc.approve')}
            </Button>
          )}
          {(withdrawal.status === 'created' || withdrawal.status === 'verified') && (
            <Button
              appearance="text"
              className="text-crimson dark:text-red"
              onClick={() => onCancel(withdrawal.id)}
            >
              {t('act.cancel')}
            </Button>
          )}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
