import classNames from 'classnames';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Announcement } from 'src/model/Announcement';
import { openSnackbar } from 'src/redux/uiSlice';
import { deleteAnnouncementById, getAnnouncementById } from 'src/service/announcementService';
import DeleteModal from './component/DeleteModal';

const AnnouncementAdminDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch();
  const [announcement, setAnnouncement] = useState<Announcement>();
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (id === undefined) return;
    getAnnouncementById(id)
      .then((res) => setAnnouncement(res))
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
        navigate(Page.Announcement, { replace: true });
      });
  }, [id]);

  const onDelete = () => {
    if (id === undefined) return;
    setDeleteModalOpen(false);
    deleteAnnouncementById(id)
      .then(() => navigate(Page.Announcement))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] text-[32px] font-bold">{t('announcement.desc.detail')}</div>
      {announcement && (
        <div className="mx-0 my-[30px] flex gap-[10px] bg-white p-[25px]">
          <Button
            className="font-bold"
            appearance="secondary"
            size="small"
            onClick={() => {
              navigate(`${Page.Announcement}/edit`, { state: announcement });
            }}
          >
            {t('announcement.act.update')}
          </Button>
          <Button appearance="secondary" size="small" onClick={() => setDeleteModalOpen(true)}>
            {t('announcement.act.delete')}
          </Button>
        </div>
      )}
      {announcement && (
        <div className="bg-white p-[30px]">
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              ID
            </div>
            <div className="flex-1 break-all leading-[21px]">{announcement.id}</div>
          </div>
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('announcement.desc.side')}
            </div>
            <div className="flex-1 break-all leading-[21px]">
              {announcement.side === 'ask'
                ? t('announcement.desc.sideAsk')
                : t('announcement.desc.sideBid')}
            </div>
          </div>
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('announcement.desc.published')}
            </div>
            <div className="flex-1 break-all leading-[21px]">
              {format(Date.parse(announcement.publishedAt), 'yyyy-MM-dd HH:mm')}
            </div>
          </div>
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('announcement.desc.unpinned')}
            </div>
            <div className="flex-1 break-all leading-[21px]">
              {announcement.unpinnedAt
                ? format(Date.parse(announcement.unpinnedAt), 'yyyy-MM-dd HH:mm')
                : '-'}
            </div>
          </div>
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('announcement.desc.unpublished')}
            </div>
            <div className="flex-1 break-all leading-[21px]">
              {announcement.unpublishedAt
                ? format(Date.parse(announcement.unpublishedAt), 'yyyy-MM-dd HH:mm')
                : '-'}
            </div>
          </div>
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('announcement.desc.subject')}
            </div>
            <div className="flex-1 break-all leading-[21px]">{announcement.subject}</div>
          </div>
          <div className="box-border flex min-h-[29px] gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="w-[30%] max-w-[135px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('announcement.desc.content')}
            </div>
            <div className={classNames('flex-1 break-all leading-[21px]', 'whitespace-pre-line')}>
              {announcement.content}
            </div>
          </div>
        </div>
      )}
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={onDelete}
      />
    </div>
  );
};

export default AnnouncementAdminDetail;
