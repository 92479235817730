import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { Crypto } from 'src/model/Crypto';
import { SettingFeeForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { getCrypto, updateFee } from 'src/service/settingService';

/**
 * 提領手續費
 */
type Props = { side: 'ask' | 'bid' };

const Fee = ({ side }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [crypto, setCrypto] = useState<Crypto[]>();
  const ercFeeFormMethods = useForm<SettingFeeForm>();
  const trcFeeFormMethods = useForm<SettingFeeForm>();

  useEffect(() => {
    getCrypto()
      .then((res) => settleCrypto(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  const settleCrypto = (res: Crypto[]) => {
    setCrypto(res);
    const erc20 = res.find((v) => v.network === 'erc20');
    const trc20 = res.find((v) => v.network === 'trc20');
    ercFeeFormMethods.setValue(
      'withdrawalFee',
      side === 'ask' ? erc20?.askWithdrawalFee ?? '' : erc20?.bidWithdrawalFee ?? '',
    );
    trcFeeFormMethods.setValue(
      'withdrawalFee',
      side === 'ask' ? trc20?.askWithdrawalFee ?? '' : trc20?.bidWithdrawalFee ?? '',
    );
  };

  const onSubmit = (id: string) => (data: SettingFeeForm) => {
    updateFee(side, id, data.withdrawalFee).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">
        {side === 'ask' ? t('setting.desc.askWithdrawalFee') : t('setting.desc.bidWithdrawalFee')}
      </div>
      <div className="flex">
        <Form
          className="px-[15px] py-[30px] md:p-[30px]"
          methods={trcFeeFormMethods}
          onSubmit={onSubmit(crypto?.find((v) => v.network === 'trc20')?.id ?? '')}
        >
          <div className="mb-[20px] font-bold">USDT-TRC20</div>
          <div className="flex items-end gap-[30px] md:gap-[60px]">
            <FormNumberInput className="w-[120px]" name="withdrawalFee" decimal={6} required />
            <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
              {t('setting.act.save')}
            </Button>
          </div>
        </Form>
        <Form
          className="px-[15px] py-[30px] md:p-[30px]"
          methods={ercFeeFormMethods}
          onSubmit={onSubmit(crypto?.find((v) => v.network === 'erc20')?.id ?? '')}
        >
          <div className="mb-[20px] font-bold">USDT-ERC20</div>
          <div className="flex items-end gap-[30px] md:gap-[60px]">
            <FormNumberInput className="w-[120px]" name="withdrawalFee" decimal={6} required />
            <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
              {t('setting.act.save')}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Fee;
