import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import { deleteAgentRebate } from 'src/service/settingService';

type Props = {
  open: boolean;
  onClose: () => void;
  id: string;
};

const DeleteRebateModal = ({ open, onClose, id }: Props) => {
  const { t } = useTranslation();

  const onDelete = () => {
    deleteAgentRebate(id).then(onClose);
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div className="flex flex-col">
        <div className="text-[32px] font-bold">{t('setting.desc.deleteHead')}</div>
        <div className="mt-[20px]">{t('setting.desc.deleteAlert')}</div>
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button className="font-bold" appearance="outlined" onClick={onClose}>
            {t('act.cancel')}
          </Button>
          <Button appearance="primary" onClick={onDelete}>
            {t('setting.act.delete')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteRebateModal;
