import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { CommissionAdmin } from 'src/model/Commission';

type Props = {
  data: CommissionAdmin[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (commission: CommissionAdmin) => (
    <div key={commission.id}>
      <div className="flex flex-col gap-[4px] px-0 py-[20px]">
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Commission}/${commission.id}`)}
          >
            {commission.id}
          </div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('commission.desc.user')}
          </div>
          <div
            className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.UserAsk}/${commission.userId}`)}
          >
            {commission.user.email}
          </div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('commission.desc.coin')}
          </div>
          <div className={'text-[14px]'}>{commission.coin.toUpperCase()}</div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('commission.desc.kid')}
          </div>
          <div
            className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.UserAsk}/${commission.kid.id}`)}
          >
            {commission.kid.email}
          </div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('commission.desc.kidAmount')}
          </div>
          <div className={'text-[14px]'}>{commission.askAmount}</div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('commission.desc.amount')}
          </div>
          <div className={'text-[14px]'}>{commission.amount}</div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('commission.desc.createdAt')}
          </div>
          <div className={'text-[14px]'}>
            {format(new Date(commission.createdAt), 'yyyy-MM-dd HH:mm:SS')}
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
