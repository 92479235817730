import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Message } from 'src/model/Message';

type Props = {
  list: Array<Message>;
  onClick: (data: Message) => void;
};

const Card = ({ list, onClick }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (data: Message) => (
    <div key={data.id}>
      <div className="px-[0px] py-[15px]">
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div className="font-bold text-turquoise dark:text-aqua">{data.id}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageAdmin.desc.user')}
          </div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => {
              if (data.user.side === 'ask') navigate(`${Page.UserAsk}/${data.userId}`);
              else if (data.user.side === 'bid') navigate(`${Page.UserBid}/${data.userId}`);
              else navigate(`${Page.UserAdmin}/${data.userId}`);
            }}
          >
            {t(`order.desc.side.${data.user.side}`)}-{data.user.email}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageAdmin.desc.subject')}
          </div>
          <div>{data.subject}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageAdmin.desc.time')}
          </div>
          <div>{format(new Date(data.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageAdmin.desc.relation')}
          </div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Fill}/${data.messageableId}`)}
          >{`${t('messageAdmin.desc.fill')}-${data.messageableId}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageAdmin.desc.content')}
          </div>
          <Button appearance="text" onClick={() => onClick(data)}>
            {t('messageAdmin.desc.content')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{list.map(elementMapFunction)}</div>;
};

export default Card;
