import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AccountUser } from 'src/model/AccountUser';
import { getUserListByQuery } from 'src/service/userService';
import AutoComplete from './AutoComplete';
import AutoCompleteOption from './AutoCompleteOption';

type Props = {
  className?: string;
  filteredSide?: string;
  setUserId: (id: string) => void;
  label?: string;
};

const UserSearch = ({ className, filteredSide, setUserId, label }: Props) => {
  const { t } = useTranslation();
  const [input, setInput] = useState<string>('');
  const [users, setUsers] = useState<AccountUser[]>();

  useEffect(() => {
    const timer = setTimeout(() => {
      getUserListByQuery(filteredSide, input).then((res) => setUsers(res));
    }, 1000);

    return () => clearTimeout(timer);
  }, [input]);

  const getUserLabel = (v?: AccountUser) => {
    if (v) return `${t(`user.desc.side.${v.side}`)} ${v.slug ?? '(none)'} ${v.email}`;

    return '';
  };

  return (
    <AutoComplete
      className={className}
      value={input}
      label={label}
      onChange={(v) => {
        setInput(v);
        setUserId(v);
      }}
      filter={false}
    >
      {users?.map((v) => (
        <AutoCompleteOption key={v.id} value={v.id}>
          {getUserLabel(v)}
        </AutoCompleteOption>
      ))}
    </AutoComplete>
  );
};

export default UserSearch;
