import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { AccountUser } from 'src/model/AccountUser';

type Props = {
  data: Array<AccountUser>;
  onEdit: (user: AccountUser) => () => void;
  onDelete: (user: AccountUser) => () => void;
};

const Table = ({ data, onEdit, onDelete }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (user: AccountUser) => (
    <div key={user.id}>
      <div className="flex flex-row">
        <div className="box-border w-1/4 break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${Page.UserAsk}/${user.id}`)}
          >
            {user.id}
          </div>
        </div>
        <div className="box-border w-[calc(100%/8)] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          {user.slug ?? '-'}
        </div>
        <div className="box-border w-1/4 break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          {user.email}
        </div>
        <div className="box-border w-[calc(100%/8)] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          {user.fillsLimit}
        </div>
        <div className="box-border flex w-1/4 flex-wrap justify-end gap-[3px] px-[12px] py-[16px]">
          <div>
            <Button appearance="secondary" size="small" onClick={onEdit(user)}>
              {t('askerLimit.act.edit')}
            </Button>
          </div>
          <div>
            <Button appearance="secondary" size="small" onClick={onDelete(user)}>
              {t('askerLimit.act.delete')}
            </Button>
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-1/4 px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          ID
        </div>
        <div className="box-border w-[calc(100%/8)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('askerLimit.desc.slug')}
        </div>
        <div className="box-border w-1/4 px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          Email
        </div>
        <div className="box-border w-[calc(100%/8)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('askerLimit.desc.askerLimit')}
        </div>
        <div className="box-border w-1/4 px-[12px] py-[16px]" />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
