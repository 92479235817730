import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { FreeForm } from 'src/model/Form';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (free: string) => void;
};

const ConfirmUpdateBalanceModal = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<FreeForm>();

  const handleSubmit = (data: FreeForm) => {
    onClose();
    onSubmit(data.free);
    methods.reset();
  };

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="text-[32px] font-bold">{t('userProperty.desc.updateHeading')}</div>

        <Form methods={methods} onSubmit={handleSubmit} className="mt-[25px]">
          <FormInput
            hint={t('userProperty.desc.updateHelper')}
            label={t('userProperty.desc.amount')}
            name="free"
            type="number"
            required
          />
          <div className="mt-[65px] flex flex-row justify-end gap-[20px]">
            <Button appearance="secondary" onClick={onClose} type="button">
              {t('act.cancel')}
            </Button>
            <Button type="submit">{t('act.submit')}</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default ConfirmUpdateBalanceModal;
