import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Page } from 'src/constant/Page';
import useNav from 'src/hook/useNav';
import IcLogo from 'src/image/ic-logo.svg';
import { RootState } from 'src/redux/store';
import Button from './Button';

const SideMenuAdmin = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const nav = useNav();
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);

  const onNavigate = (path: string) => () => navigate(path);

  return (
    <div className="box-border h-[100vh] w-[256px] overflow-y-auto !bg-white px-0 py-[20px] dark:!bg-black-800">
      <div className="mb-[10px] pl-[30px]">
        <img className="cursor-pointer" src={IcLogo} />
      </div>
      <div className="px-[30px] py-[10px] text-[20px] font-bold text-dark-500 dark:text-grey-300">
        <div>{accountInfo?.slug ?? '-'}</div>
        <div className="text-[12px] font-normal">{accountInfo?.email ?? '-'}</div>
      </div>
      {nav.map((v) => (
        <div
          key={v.locale}
          className={twMerge(
            'flex h-[50px] cursor-pointer items-center gap-[20px] bg-clip-padding pl-[30px] pr-[20px] hover:bg-light-100 dark:hover:bg-black-700',
            classNames({
              'bg-light-200 dark:bg-black-500': location.pathname.startsWith(v.path),
            }),
          )}
          onClick={onNavigate(v.path)}
        >
          <img src={v.img} />
          <div>{t(v.locale)}</div>
        </div>
      ))}
      <div className="ml-[30px] flex h-[41px] w-fit cursor-pointer items-center text-[14px]">
        <Button
          appearance="text"
          className="text-dark-700 dark:text-white"
          onClick={onNavigate(Page.SignOut)}
        >
          {t('auth.logout')}
        </Button>
      </div>
    </div>
  );
};

export default SideMenuAdmin;
