import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { AccountUser } from 'src/model/AccountUser';

type Props = {
  data: Array<AccountUser>;
  onEdit: (user: AccountUser) => () => void;
  onDelete: (user: AccountUser) => () => void;
};
const Card = ({ data, onEdit, onDelete }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (user: AccountUser) => (
    <div key={user.id}>
      <div className="px-0 py-[15px]">
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.UserAsk}/${user.id}`)}
          >
            {user.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('askerLimit.desc.slug')}
          </div>
          <div className={'font-bold text-turquoise dark:text-aqua'}>{user.slug ?? '-'}</div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">Email</div>
          <div className={'font-bold text-turquoise dark:text-aqua'}>{user.email}</div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('askerLimit.desc.askerLimit')}
          </div>
          <div className={'font-bold text-turquoise dark:text-aqua'}>{user.fillsLimit}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <Button size="small" appearance="secondary" onClick={onEdit(user)}>
            {t('askerLimit.act.edit')}
          </Button>
          <Button size="small" appearance="secondary" onClick={onDelete(user)}>
            {t('askerLimit.act.delete')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
