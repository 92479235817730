import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import Modal from 'src/component/Modal';
import { EditProxyProfitForm } from 'src/model/Form';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: EditProxyProfitForm) => void;
};

const EditProxyProfitModal = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<EditProxyProfitForm>();

  return (
    <Modal open={open} handleClose={() => onClose()}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col">
        <div className="mb-[10px] text-[32px] font-bold">
          {t('userDetail.relation.editProxyProfit')}
        </div>
        <FormNumberInput name="profit" required label={t('userDetail.relation.proxyProfit')} />
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={() => onClose()} type="button">
            {t('act.cancel')}
          </Button>
          <Button type="submit" appearance="primary">
            {t('act.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditProxyProfitModal;
