import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Pagination from 'src/component/Pagination';
import RoundDateFilter from 'src/component/RoundDateFilter';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcAdd from 'src/image/ic-add-s.svg';
import IcEmpty from 'src/image/ic-empty-light.svg';
import { Asset } from 'src/model/Asset';
import { SystemBalanceTransaction } from 'src/model/Property';
import { openSnackbar } from 'src/redux/uiSlice';
import { getAssetId, getAssetTransaction } from 'src/service/homeService';
import { bnFormat } from 'src/util/bigNumber';
import Card from './component/Card';
import ManipulationModal from './component/ManipulationModal';
import Table from './component/Table';

const Frozen = () => {
  const { coin } = useParams<{ coin: string }>();
  const dispatch = useDispatch();
  const { t, ready } = useTranslation();
  const [asset, setAsset] = useState<Asset>();
  const [transactionArray, setTransactionArray] = useState<Array<SystemBalanceTransaction>>();
  const [manipulationOpen, setManipulationOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const { begin, end, dateFilterProps } = useDateFilter();
  const { offset, limit, setCount, paginationProps } = usePagination();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    if (!coin) return;

    getAssetId(coin)
      .then((res) => setAsset(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh]);

  useEffect(() => {
    if (!coin) return;

    getAssetTransaction(coin, 'freeze,unfreeze,manipulation', { begin, end, offset, limit })
      .then((res) => {
        setTransactionArray(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [begin, end, offset, limit, refresh]);

  const onDownload = () => {
    if (!coin) return;

    getAssetTransaction(
      coin,
      'freeze,unfreeze,manipulation',
      { begin, end, offset, limit },
      true,
    ).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!ready || !asset) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex items-end justify-between text-[32px] font-bold">
        {t('frozen.head', { coin: coin?.toUpperCase() })}
      </div>
      <div className="mt-[20px] rounded-[4px] bg-white px-[40px] py-[25px]">
        <div className="flex items-center gap-[15px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('frozen.address')}</div>
          <div>{asset.externalAddress ?? '-'}</div>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('frozen.coldWallet')}</div>
          <div>{bnFormat(asset.coldWallet)}</div>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('frozen.balance')}</div>
          <div>{bnFormat(asset.frozen)}</div>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('frozen.systemAddress')}
          </div>
          <div>{asset.poolAddress ?? '-'}</div>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('frozen.systemBalance')}
          </div>
          <div>{bnFormat(asset.free)}</div>
        </div>
        <div className="flex items-center gap-[15px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('frozen.required')}</div>
          <div>{bnFormat(asset.required ?? 0)}</div>
        </div>
      </div>
      <div className="mt-[20px] flex items-end justify-between text-[32px] font-bold">
        <div>{t('frozen.record')}</div>
        <div>
          <Button
            appearance="text"
            className="flex flex-row items-center gap-[4px]"
            onClick={() => setManipulationOpen(true)}
          >
            <img src={IcAdd} />
            {t('frozen.manipulation')}
          </Button>
        </div>
      </div>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] text-[14px] dark:bg-black-800 md:px-[40px] md:py-[25px]">
        <div className="flex justify-between">
          <RoundDateFilter {...dateFilterProps} />
          <Button
            className="h-fit font-bold"
            size="small"
            appearance="secondary"
            onClick={onDownload}
          >
            {t('profit.act.download')}
          </Button>
        </div>
        <div className="h-[1px] text-grey-700 dark:text-grey-300" />
        {transactionArray?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {transactionArray &&
          transactionArray?.length > 0 &&
          (isBiggerThanMd ? <Table data={transactionArray} /> : <Card data={transactionArray} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <ManipulationModal
        open={manipulationOpen}
        onClose={() => setManipulationOpen(false)}
        onRefresh={() => setRefresh(!refresh)}
        coin={coin}
      />
    </div>
  );
};

export default Frozen;
