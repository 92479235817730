import { GetAssetTransactionParams, GetAssetTransactionResponse } from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getAssetTransaction = async (
  params?: GetAssetTransactionParams,
  headers?: { [key: string]: string },
) => {
  try {
    return await http.authGet<GetAssetTransactionResponse, GetAssetTransactionParams>(
      'asset-transactions',
      {
        params,
        headers,
      },
    );
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getAssetTransaction,
};
