import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormSelect from 'src/component/FormSelect';
import Modal from 'src/component/Modal';
import SelectOption from 'src/component/SelectOption';
import { AccountUser } from 'src/model/AccountUser';
import { UpdateUserForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateUser } from 'src/service/userService';

type Props = {
  open: boolean;
  data: AccountUser;
  onClose: () => void;
  onRefreshUser: () => void;
};

const UpdateUserModal = ({ open, data, onClose, onRefreshUser }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<UpdateUserForm>();

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = (formData: UpdateUserForm) => {
    handleClose();

    updateUser(data.id, formData)
      .then(() => {
        onRefreshUser();
        dispatch(
          openSnackbar({ message: t('user.desc.updateUserSuccessfully'), severity: 'notify' }),
        );
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={() => handleClose()}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col">
        <div className="text-[32px] font-bold text-black-900 dark:text-white">
          {t('user.desc.updateUserHeading')}
        </div>
        <div className="mt-[25px]">
          <FormInput defaultValue={data.email} label="Email" name="email" type="email" required />
        </div>
        <div className="mt-[25px]">
          <FormInput
            label={t('user.desc.password')}
            name="password"
            type="password"
            hint={t('user.desc.passwordHelperText')}
          />
        </div>
        {data.side === 'admin' && (
          <div className="mt-[25px]">
            <FormSelect
              label={t('user.desc.role')}
              name="role"
              defaultValue={data.role ?? 'manager'}
              required
            >
              <SelectOption value="manager">{t('user.desc.roleDisplay.manager')}</SelectOption>
              <SelectOption value="assistant">{t('user.desc.roleDisplay.assistant')}</SelectOption>
              <SelectOption value="viewer">{t('user.desc.roleDisplay.viewer')}</SelectOption>
            </FormSelect>
          </div>
        )}
        <div className="mt-[25px]">
          <FormInput
            defaultValue={data.mobile ?? ''}
            label={t('user.desc.mobile')}
            name="mobile"
            type="tel"
          />
        </div>
        <div className="mt-[25px]">
          <FormInput defaultValue={data.slug ?? ''} label={t('user.desc.slug')} name="slug" />
        </div>
        {data.side === 'bid' && (
          <div className="mt-[25px]">
            <FormSelect
              label={t('user.desc.paymentMethod')}
              name="paymentMethodType"
              defaultValue={data.paymentMethodType ?? 'all'}
              required
            >
              <SelectOption value="all">{t('user.desc.paymentMethodDisplay.all')}</SelectOption>
              <SelectOption value="bank">{t('user.desc.paymentMethodDisplay.bank')}</SelectOption>
              <SelectOption value="alipay">
                {t('user.desc.paymentMethodDisplay.alipay')}
              </SelectOption>
            </FormSelect>
          </div>
        )}
        <div className="mt-[40px] text-end">
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateUserModal;
