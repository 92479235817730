import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UserStatusLabel from 'src/component/UserStatusLabel';
import { AccountUser } from 'src/model/AccountUser';

type Props = {
  data: Array<AccountUser>;
  onEditUser: (user: AccountUser) => void;
};
const UserListCard = ({ data, onEditUser }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const elementMapFunction = (user: AccountUser) => (
    <div key={user.id}>
      <div className="px-0 py-[15px]">
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${location.pathname}/${user.id}`)}
          >
            {user.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('user.desc.identity')}
          </div>
          <div>{t(`user.desc.side.${user.side}`)}</div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('user.desc.slug')}</div>
          <div className={'font-bold text-turquoise dark:text-aqua'}>{user.slug ?? '-'}</div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">Email</div>
          <div className={'font-bold text-turquoise dark:text-aqua'}>{user.email}</div>
        </div>
        <div className="mt-[10px]">
          <UserStatusLabel user={user} onEditUser={onEditUser} />
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default UserListCard;
