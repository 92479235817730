import classNames from 'classnames';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { SystemBalanceTransaction } from 'src/model/Property';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: SystemBalanceTransaction[];
  navigateCallback: (transaction: SystemBalanceTransaction) => void;
};

const Table = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();
  const transactionTypeMapping = useMemo(
    () => ({
      approvement: t('systemProperty.desc.transactionType.approvement'),
      deposit: t('systemProperty.desc.transactionType.deposit'),
      manipulation: t('systemProperty.desc.transactionType.manipulation'),
      payin: t('systemProperty.desc.transactionType.payin'),
      payout: t('systemProperty.desc.transactionType.payout'),
      'withdrawal-fee': t('systemProperty.desc.transactionType.withdrawalFee'),
      withdrawal: t('systemProperty.desc.transactionType.withdrawal'),
      freeze: t('systemProperty.desc.transactionType.freeze'),
      unfreeze: t('systemProperty.desc.transactionType.unfreeze'),
    }),
    [t],
  );

  const onDetailClick = (transaction: SystemBalanceTransaction) => () => {
    navigateCallback(transaction);
  };

  const elementMapFunction = (
    transaction: SystemBalanceTransaction,
    index: number,
    array: SystemBalanceTransaction[],
  ) => (
    <div key={transaction.id} className="flex flex-col">
      <div className="flex flex-row">
        <div className="box-border w-[25%] break-words pr-[10px] text-grey-500 dark:text-grey-700">
          {format(Date.parse(transaction.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div className="box-border w-[25%] break-words pr-[10px]">
          {bnFormat(transaction.amount)}
        </div>
        <div className="box-border w-[25%] break-words pr-[10px]">
          {bnFormat(transaction.balance)}
        </div>
        <div className="box-border w-[25%] break-words pr-[10px]">
          {bnFormat(transaction.frozenAmount)}
        </div>
        <div className="box-border w-[25%] break-words pr-[10px]">
          {bnFormat(transaction.frozenBalance)}
        </div>
        <div className="box-border flex w-[25%] flex-row items-start gap-[10px] break-words pr-[10px] text-[14px]">
          {transactionTypeMapping[transaction.type]}
          {['deposit', 'withdrawal', 'withdrawal-fee'].includes(transaction.type) && (
            <Button
              style={{ visibility: 'hidden' }}
              appearance="text"
              onClick={onDetailClick(transaction)}
            >
              {t('systemProperty.act.detail')}
            </Button>
          )}
        </div>
      </div>
      <div
        className={classNames('my-[15px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-[25%]">{t('systemProperty.desc.time')}</div>
        <div className="w-[25%]">{t('systemProperty.desc.amount')}</div>
        <div className="w-[25%]">{t('systemProperty.desc.balance')}</div>
        <div className="w-[25%]">{t('systemProperty.desc.frozenAmount')}</div>
        <div className="w-[25%]">{t('systemProperty.desc.frozenBalance')}</div>
        <div className="w-[25%]">{t('systemProperty.desc.information')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
