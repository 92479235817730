import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingExchangeRateForm, SettingUserExchangeRateForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateProxyRateShift, updateUserRateShift } from 'src/service/settingService';
import { bn, bnFormat } from 'src/util/bigNumber';

/**
 * 參考匯率
 */
const ExchangeRate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { exchangeRate, config } = useSelector((rootState: RootState) => rootState.setting);
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const proxyMethods = useForm<SettingExchangeRateForm>();
  const userMethods = useForm<SettingUserExchangeRateForm>();

  useEffect(() => {
    if (exchangeRate === null) return;
    proxyMethods.setValue('shift', exchangeRate.shiftValue);
  }, [exchangeRate]);

  useEffect(() => {
    if (config === null) return;
    userMethods.setValue('shift', config['ask-price-delta']['usdt/cny']);
  }, [config]);

  const onProxyRateSubmit = (data: SettingExchangeRateForm) => {
    updateProxyRateShift({
      base: 'usdt',
      quote: 'cny',
      value: data.shift,
    }).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onUserRateSubmit = (data: SettingUserExchangeRateForm) => {
    updateUserRateShift(data.shift).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.rateHead')}</div>
      <Form
        className="px-[15px] py-[30px] md:px-[30px]"
        methods={proxyMethods}
        onSubmit={onProxyRateSubmit}
      >
        <div className="mb-[20px] font-bold">{'USDT/CNY'}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.rateOriginalPrice')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRate ? `${bnFormat(exchangeRate.originalPrice)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput
              name="shift"
              decimal={fiat.length > 0 ? fiat[0].priceDecimal : 0}
              allowMinus
            />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.proxyRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRate ? `${bnFormat(exchangeRate.price)}` : '-'}
            </div>
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
      <Form
        className="px-[15px] pb-[30px] md:px-[30px]"
        methods={userMethods}
        onSubmit={onUserRateSubmit}
      >
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.proxyRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRate ? `${bnFormat(exchangeRate.price)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput
              name="shift"
              decimal={fiat.length > 0 ? fiat[0].priceDecimal : 0}
              allowMinus
            />
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.userRate')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRate && config
                ? `${bn(exchangeRate.price).plus(config['ask-price-delta']['usdt/cny']).toFormat()}`
                : '-'}
            </div>
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ExchangeRate;
