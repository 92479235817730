import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import useQuery from 'src/hook/useQuery';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { WithdrawalQueryForm } from 'src/model/Form';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import {
  approveWithdrawal,
  cancelWithdrawal,
  getWithdrawalRecordById,
  getWithdrawalRecordList,
} from 'src/service/depositWithdrawalAdminService';
import { getCrypto } from 'src/service/settingService';
import ApproveModal from './component/ApproveModal';
import CancelModal from './component/CancelModal';
import Card from './component/Card';
import Table from './component/Table';
import VerifyModal from './component/VerifyModal';

type QueryParams = {
  approved: string;
};

const WithdrawalAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminWithdrawal: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const { t, ready } = useTranslation();
  const { approved } = useQuery<QueryParams>();
  const [withdrawalId, setWithdrawalId] = useState<string>(filter.id ?? '');
  const [withdrawalList, setWithdrawalList] = useState<Array<WithdrawalRecord>>();
  const [coin, setCoin] = useState<string | undefined>(filter.coin);
  const [filterStatus, setFilterStatus] = useState<string | undefined>(
    filter.status ?? (approved === '1' ? 'verified' : 'all'),
  );
  const [cryptoList, setCryptoList] = useState<{ id: string; value: string }[]>();
  const [minAmount, setMinAmount] = useState<string>(filter.minAmount ?? '');
  const [maxAmount, setMaxAmount] = useState<string>(filter.maxAmount ?? '');
  const [target, setTarget] = useState<WithdrawalRecord>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [cancelId, setCancelId] = useState<string>();
  const [verifyWithdrawalData, setVerifyWithdrawalData] = useState<WithdrawalRecord>();
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<WithdrawalQueryForm>({
    defaultValues: {
      id: filter.id,
      minAmount: filter.minAmount,
      maxAmount: filter.maxAmount,
    },
  });

  useEffect(() => {
    getCrypto()
      .then((res) =>
        setCryptoList([
          { id: 'all', value: t('trade.desc.all') },
          ...res.map((v) => ({
            id: v.id,
            value: v.network
              ? `${v.coin.toUpperCase()}-${v.network.toUpperCase()}`
              : v.coin.toUpperCase(),
          })),
        ]),
      )
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    if (withdrawalId) {
      getWithdrawalRecordById(withdrawalId, true)
        .then((res) => {
          setWithdrawalList([res]);
          setCount(1);
          paginationProps.setPage(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getWithdrawalRecordList(
      {
        user: state?.userId,
        coin,
        minAmount,
        maxAmount,
        status: filterStatus === 'all' ? undefined : filterStatus,
      },
      { begin, end, offset, limit },
    )
      .then((res) => {
        setWithdrawalList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [
    withdrawalId,
    begin,
    end,
    offset,
    limit,
    state,
    coin,
    minAmount,
    maxAmount,
    filterStatus,
    refresh,
  ]);

  const onCoinFilterChange = (value: string) => {
    setCoin(value !== 'all' ? value : undefined);
    paginationProps.setPage(1);
  };

  const onStatusFilterChange = (value: string) => {
    setFilterStatus(value);
    paginationProps.setPage(1);
  };

  const onQuery = (data: WithdrawalQueryForm) => {
    setWithdrawalId(data.id);
    setMinAmount(data.minAmount);
    setMaxAmount(data.maxAmount);
  };

  const onDownload = () => {
    getWithdrawalRecordList(
      {
        user: state?.userId,
        coin,
        minAmount,
        maxAmount,
        status: filterStatus === 'all' ? undefined : filterStatus,
      },
      { begin, end, offset, limit },
      true,
    ).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onApprove = () => {
    if (!target) return;
    approveWithdrawal(target.id)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onCancelWithdrawal = (id: string) => {
    setCancelId(id);
  };

  const onCancelConfirm = () => {
    if (!cancelId) return;
    setCancelId(undefined);
    cancelWithdrawal(cancelId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onVerifyClick = (data: WithdrawalRecord) => {
    setVerifyWithdrawalData(data);
  };

  if (!ready || !cryptoList) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('withdrawalRecord.heading')}
      </div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-0 md:gap-[60px] md:px-[30px]">
        <Select
          label={t('withdrawalRecord.desc.coin')}
          defaultValue={filter.coin ?? 'all'}
          onChange={onCoinFilterChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          {cryptoList.map((v) => (
            <SelectOption key={v.id} value={v.id}>
              {v.value}
            </SelectOption>
          ))}
        </Select>
        <Select
          label={t('fill.desc.status')}
          onChange={onStatusFilterChange}
          defaultValue={filterStatus}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          <SelectOption value={'all'}>{t('withdrawalRecord.desc.all')}</SelectOption>
          <SelectOption value={'created'}>
            {t('withdrawalRecord.desc.statusDisplay.created')}
          </SelectOption>
          <SelectOption value={'verified'}>
            {t('withdrawalRecord.desc.statusDisplay.verified')}
          </SelectOption>
          <SelectOption value={'approved'}>
            {t('withdrawalRecord.desc.statusDisplay.approved')}
          </SelectOption>
          <SelectOption value={'submitted'}>
            {t('withdrawalRecord.desc.statusDisplay.submitted')}
          </SelectOption>
          <SelectOption value={'confirmed'}>
            {t('withdrawalRecord.desc.statusDisplay.confirmed')}
          </SelectOption>
          <SelectOption value={'canceled'}>
            {t('withdrawalRecord.desc.statusDisplay.canceled')}
          </SelectOption>
          <SelectOption value={'failed'}>
            {t('withdrawalRecord.desc.statusDisplay.failed')}
          </SelectOption>
          <SelectOption value={'expired'}>
            {t('withdrawalRecord.desc.statusDisplay.expired')}
          </SelectOption>
        </Select>
        <DateFilter
          label={t('withdrawalRecord.desc.dateFilter')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <Form
          methods={methods}
          onSubmit={onQuery}
          className="flex flex-row flex-wrap items-center gap-[30px] pb-[25px]"
        >
          <FormSearchInput
            name="id"
            placeholder={t('withdrawalRecord.desc.withdrawalId')}
            onQuery={onQuery}
          />
          <div className="flex w-[120px] gap-[8px]">
            <img src={IcLimit} />
            <div className="grow">
              <Select
                value={limit.toString()}
                onChange={(v) => {
                  setLimit(Number(v));
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="5">{'5'}</SelectOption>
                <SelectOption value="10">{'10'}</SelectOption>
                <SelectOption value="50">{'50'}</SelectOption>
                <SelectOption value="100">{'100'}</SelectOption>
              </Select>
            </div>
          </div>
          <div className="flex items-center gap-[5px]">
            <FormSearchInput
              name="minAmount"
              placeholder={t('withdrawalRecord.desc.searchMinAmount')}
              onQuery={onQuery}
            />
            ~
            <FormSearchInput
              name="maxAmount"
              placeholder={t('withdrawalRecord.desc.searchMaxAmount')}
              onQuery={onQuery}
            />
          </div>
          <Button appearance="secondary" size="small" type="submit">
            {t('withdrawalRecord.desc.search')}
          </Button>
          <Button
            className="font-bold"
            size="small"
            appearance="secondary"
            type="button"
            onClick={onDownload}
          >
            {t('withdrawalRecord.desc.download')}
          </Button>
        </Form>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {withdrawalList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {withdrawalList &&
          withdrawalList.length > 0 &&
          (isBiggerThanMd ? (
            <Table
              data={withdrawalList}
              onApprove={(v) => setTarget(v)}
              onCancel={onCancelWithdrawal}
              onVerifyClick={onVerifyClick}
            />
          ) : (
            <Card
              data={withdrawalList}
              onApprove={(v) => setTarget(v)}
              onVerifyClick={onVerifyClick}
              onCancel={onCancelWithdrawal}
            />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <ApproveModal
        open={!!target}
        onClose={() => setTarget(undefined)}
        withdrawal={target}
        onConfirm={onApprove}
      />
      <CancelModal
        open={!!cancelId}
        onClose={() => setCancelId(undefined)}
        onSubmit={onCancelConfirm}
      />
      <VerifyModal
        open={!!verifyWithdrawalData}
        onClose={() => setVerifyWithdrawalData(undefined)}
        withdrawalData={verifyWithdrawalData}
        refresh={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default WithdrawalAdmin;
