import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormSelect from 'src/component/FormSelect';
import SelectOption from 'src/component/SelectOption';
import { SettingVendorForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { changeVendor } from 'src/service/settingService';

/**
 * 原始匯率來源
 */
const Vendor = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config } = useSelector((rootState: RootState) => rootState.setting);
  const methods = useForm<SettingVendorForm>();

  const onSubmit = (data: SettingVendorForm) => {
    changeVendor(data.vendor).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.vendor')}</div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methods} onSubmit={onSubmit}>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          {config && (
            <FormSelect
              className="w-[120px]"
              name="vendor"
              defaultValue={config['exchange-rate'].vendor}
            >
              <SelectOption value="okex">OKEX</SelectOption>
              <SelectOption value="binancec2c">Binance</SelectOption>
            </FormSelect>
          )}
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Vendor;
