import BackButton from 'src/component/BackButton';
import Price from './component/Price';
import Profit from './component/Profit';
import SystemBalance from './component/SystemBalance';
import UnapprovedUser from './component/UnapprovedUser';
import UnapprovedWithdrawal from './component/UnapprovedWithdrawal';
import Unmatched from './component/Unmatched';
import UserBalance from './component/UserBalance';

const Home = () => (
  <div>
    <BackButton />
    <div
      className={`mt-[20px] grid grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr]
        grid-rows-[151px_237px_auto_auto] gap-[30px]
        grid-areas-[unapprovedWithdrawal_unapprovedWithdrawal_unapprovedUser_unapprovedUser_unmatched_unmatched,price_price_price_userBalance_userBalance_userBalance,profit_profit_profit_profit_profit_profit,systemBalance_systemBalance_systemBalance_systemBalance_systemBalance_systemBalance]
        md:grid-cols-[1fr_1fr] md:grid-rows-[153px_153px_auto_auto_auto]
        md:grid-areas-[unapprovedWithdrawal_unapprovedUser,unmatched_price,userBalance_userBalance,profit_profit,systemBalance_systemBalance]
        lg:grid-cols-[1fr_1fr_1fr_1fr] lg:grid-rows-[153px_auto_auto_auto]
        lg:grid-areas-[unapprovedWithdrawal_unapprovedUser_unmatched_price,userBalance_userBalance_userBalance_userBalance,profit_profit_profit_profit,systemBalance_systemBalance_systemBalance_systemBalance]`}
    >
      <div className="grid-in-[unapprovedWithdrawal]">
        <UnapprovedWithdrawal />
      </div>
      <div className="grid-in-[unapprovedUser]">
        <UnapprovedUser />
      </div>
      <div className="grid-in-[unmatched]">
        <Unmatched />
      </div>
      <div className="grid-in-[price]">
        <Price />
      </div>
      <div className="grid-in-[userBalance]">
        <UserBalance />
      </div>
      <div className="grid-in-[profit]">
        <Profit />
      </div>
      <div className="grid-in-[systemBalance]">
        <SystemBalance />
      </div>
    </div>
  </div>
);

export default Home;
