import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { RootState } from 'src/redux/store';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<WithdrawalRecord>;
  onApprove: (v: WithdrawalRecord) => void;
  onCancel: (id: string) => void;
  onVerifyClick: (record: WithdrawalRecord) => void;
};

const Table = ({ data, onApprove, onCancel, onVerifyClick }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);

  const elementMapFunction = (withdrawal: WithdrawalRecord) => (
    <div key={withdrawal.id}>
      <div className="flex flex-row">
        <div className="box-border w-[20%] break-all px-[12px] py-[16px] text-[14px]">
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.WithdrawalRecord}/${withdrawal.id}`)}
          >
            {withdrawal.id}
          </div>
        </div>
        <div className="box-border w-[20%] break-all px-[12px] py-[16px] text-[14px]">
          {withdrawal.user ? (
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (withdrawal.user?.side === 'ask')
                  navigate(`${Page.UserAsk}/${withdrawal.userId}`);
                else if (withdrawal.user?.side === 'bid')
                  navigate(`${Page.UserBid}/${withdrawal.userId}`);
                else navigate(`${Page.UserAdmin}/${withdrawal.userId}`);
              }}
            >
              {t(`withdrawalRecord.desc.side.${withdrawal.user?.side}`)}-{withdrawal.user?.email}
            </div>
          ) : (
            <div>{t('withdrawalRecord.desc.profitWithdrawal')}</div>
          )}
        </div>
        <div className="box-border w-[10%] break-all px-[12px] py-[16px] text-[14px]">
          {bnFormat(withdrawal.amount)}
        </div>
        <div className="box-border w-[10%] break-all px-[12px] py-[16px] text-[14px]">
          {(withdrawal.network
            ? `${withdrawal.coin}-${withdrawal.network}`
            : withdrawal.coin
          ).toUpperCase()}
        </div>
        <div className="box-border w-[10%] break-all px-[12px] py-[16px] text-[14px]">
          <div>{t(`withdrawalRecord.desc.statusDisplay.${withdrawal.status}`)}</div>
          {withdrawal.status === 'created' && accountInfo?.id === withdrawal.userId && (
            <Button appearance="text" type="button" onClick={() => onVerifyClick(withdrawal)}>
              {t('withdrawalRecord.desc.verify')}
            </Button>
          )}
        </div>
        <div className="box-border w-[20%] break-all px-[12px] py-[16px] text-[14px]">
          <div>
            <span>{`${format(Date.parse(withdrawal.createdAt), 'yyyy/MM/dd')} `}</span>
            <span className="break-keep">
              {format(Date.parse(withdrawal.createdAt), 'HH:mm:ss')}
            </span>
          </div>
        </div>
        <div className="box-border flex w-[10%] items-start justify-end gap-[10px] break-all px-[12px] py-[16px] text-[14px]">
          {withdrawal.status === 'verified' && (
            <Button appearance="text" onClick={() => onApprove(withdrawal)}>
              {t('withdrawalRecord.desc.approve')}
            </Button>
          )}
          {(withdrawal.status === 'created' || withdrawal.status === 'verified') && (
            <Button
              appearance="text"
              className="text-crimson dark:text-red"
              onClick={() => onCancel(withdrawal.id)}
            >
              {t('act.cancel')}
            </Button>
          )}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[20%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          ID
        </div>
        <div className="box-border w-[20%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('withdrawalRecord.desc.user')}
        </div>
        <div className="box-border w-[10%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('withdrawalRecord.desc.amount')}
        </div>
        <div className="box-border w-[10%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('withdrawalRecord.desc.coin')}
        </div>
        <div className="box-border w-[10%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('withdrawalRecord.desc.status')}
        </div>
        <div className="box-border w-[20%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('withdrawalRecord.desc.withdrawalDate')}
        </div>
        <div className="box-border w-[10%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700" />
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
