import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { RebateDetail } from 'src/model/Rebate';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<RebateDetail>;
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (rebate: RebateDetail) => (
    <div key={rebate.id}>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/9*2)]',
            'font-bold text-turquoise dark:text-aqua',
          )}
        >
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${Page.ParentRebate}/${rebate.id}`)}
          >
            {rebate.id}
          </div>
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/9*2)]',
            'font-bold text-turquoise dark:text-aqua',
          )}
        >
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${Page.UserAsk}/${rebate.userId}`)}
          >
            {rebate.user.email}
          </div>
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/9)]',
          )}
        >
          {rebate.coin.toUpperCase()}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/9)]',
          )}
        >
          {bnFormat(rebate.askAmount)}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/9)]',
          )}
        >
          {bnFormat(rebate.amount)}
        </div>
        <div
          className={classNames(
            'box-border break-all px-[12px] py-[16px] text-[14px]',
            'w-[calc(100%/9*2)]',
          )}
        >
          {format(new Date(rebate.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300',
            'w-[calc(100%/9*2)]',
          )}
        >
          ID
        </div>
        <div
          className={classNames(
            'box-border px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300',
            'w-[calc(100%/9*2)]',
          )}
        >
          {t('rebate.desc.user')}
        </div>
        <div
          className={classNames(
            'box-border px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300',
            'w-[calc(100%/9)]',
          )}
        >
          {t('rebate.desc.coin')}
        </div>
        <div
          className={classNames(
            'box-border px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300',
            'w-[calc(100%/9)]',
          )}
        >
          {t('rebate.desc.askAmount')}
        </div>
        <div
          className={classNames(
            'box-border px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300',
            'w-[calc(100%/9)]',
          )}
        >
          {t('rebate.desc.amount')}
        </div>
        <div
          className={classNames(
            'box-border px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300',
            'w-[calc(100%/9*2)]',
          )}
        >
          {t('rebate.desc.createdAt')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
