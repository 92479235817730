import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import IcGo from 'src/image/ic-go.svg';
import { getUnapprovedUser } from 'src/service/homeService';

const UnapprovedUser = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [unapproved, setUnapproved] = useState<string>();

  useEffect(() => {
    getUnapprovedUser().then((res) => setUnapproved(res));
  }, []);

  return (
    <div
      className="relative box-border h-[100%] cursor-pointer bg-turquoise p-[16px] text-white dark:bg-aqua"
      onClick={() => navigate(`${Page.UserBid}?approved=0`)}
    >
      <div>{t('home.unapproved')}</div>
      <div className="mt-[4px] text-[42px] font-bold">{unapproved ?? '-'}</div>
      <img className="absolute bottom-[16px] right-[16px]" src={IcGo} />
    </div>
  );
};

export default UnapprovedUser;
