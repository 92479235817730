import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const CancelModal = ({ open, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div>{t('withdrawalRecord.desc.cancelMessage')}</div>
        <div className="mt-[40px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" onClick={onClose} type="button">
            {t('withdrawalRecord.desc.cancel')}
          </Button>
          <Button type="button" onClick={onSubmit}>
            {t('withdrawalRecord.desc.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
