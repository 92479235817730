import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingParentRebateForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateParentRebate } from 'src/service/settingService';

/**
 * 階級返傭
 */
const ParentRebate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { parentRebatePercentage } = useSelector((rootState: RootState) => rootState.setting);
  const methods = useForm<SettingParentRebateForm>();

  useEffect(() => {
    if (parentRebatePercentage === null) return;
    methods.setValue('percentage', parentRebatePercentage);
  }, [parentRebatePercentage]);

  const onSubmit = (data: SettingParentRebateForm) => {
    updateParentRebate(data.percentage).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.parentRebate')}</div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methods} onSubmit={onSubmit}>
        <div className="mb-[20px] font-bold">{t('setting.desc.rebatePerc')}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <FormNumberInput name="percentage" decimal={3} />
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ParentRebate;
