import messageEndpoint from 'src/api/messageEndpoint';
import { PostCannedMessageRequest, PutCannedMessageIdRequest } from 'src/model/Api';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';

export const getMessageList = async (
  params: { user?: string; side?: string; email?: string },
  paginationParams?: PaginationParams,
) => {
  try {
    dispatch(startWaiting());
    const res = await messageEndpoint.getMessageList({
      ...params,
      ...paginationParams,
      order: 'desc',
    });

    dispatch(
      updateFilter({
        key: 'adminMessage',
        filter: {
          ...params,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getCannedMessageList = async () => {
  try {
    dispatch(startWaiting());
    const res = await messageEndpoint.getCannedMessageList({});

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const addCannedMessage = async (data: PostCannedMessageRequest) => {
  try {
    dispatch(startWaiting());
    await messageEndpoint.postCannedMessage(data);
  } finally {
    dispatch(finishWaiting());
  }
};

export const editCannedMessage = async (id: string, data: PutCannedMessageIdRequest) => {
  try {
    dispatch(startWaiting());
    await messageEndpoint.putCannedMessageId(id, data);
  } finally {
    dispatch(finishWaiting());
  }
};

export const deleteCannedMessage = async (id: string) => {
  try {
    dispatch(startWaiting());
    await messageEndpoint.deleteCannedMessageId(id);
  } finally {
    dispatch(finishWaiting());
  }
};
