import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { Bank } from 'src/model/Bank';
import { BankForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { editBank } from 'src/service/bankAdminService';

type Props = {
  open: boolean;
  bank?: Bank;
  onClose: () => void;
  doRefresh: () => void;
};

const EditModal = ({ open, bank, onClose, doRefresh }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<BankForm>();
  const dispatch = useDispatch();

  useEffect(() => {
    methods.setValue('name', bank?.name ?? '');
  }, [open]);

  const onSubmit = (data: BankForm) => {
    if (bank === undefined) return;
    editBank(bank.id, data.name)
      .then(() => {
        doRefresh();
        onClose();
        methods.reset();
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={() => onClose()}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mb-[30px] text-[32px] font-bold">{t('bank.desc.editHead')}</div>
        <FormInput name="name" label={t('bank.desc.label')} />
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button
            className="font-bold"
            appearance="outlined"
            onClick={() => onClose()}
            type="button"
          >
            {t('act.cancel')}
          </Button>
          <Button className="font-bold" appearance="primary" type="submit">
            {t('bank.act.edit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditModal;
