import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingBidPriceForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updatePriceDelta } from 'src/service/settingService';
import { bnFormat } from 'src/util/bigNumber';

/**
 * 買家購入價
 */
const BidPrice = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config, exchangeRate, bidPrice } = useSelector(
    (rootState: RootState) => rootState.setting,
  );
  const { fiat } = useSelector((rootState: RootState) => rootState.coin);
  const methods = useForm<SettingBidPriceForm>();

  useEffect(() => {
    if (config === null) return;
    methods.setValue('shift', config['price-delta']['usdt/cny']);
  }, [config]);

  const onSubmit = (data: SettingBidPriceForm) => {
    updatePriceDelta(data.shift).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.bidPrice')}</div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methods} onSubmit={onSubmit}>
        <div className="mb-[20px] font-bold">{'USDT/CNY'}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.rateHead')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {exchangeRate ? `${bnFormat(exchangeRate.price)}` : '-'}
            </div>
          </div>
          <div className="relative w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.shift')}
            </div>
            <FormNumberInput name="shift" decimal={fiat.length > 0 ? fiat[0].priceDecimal : 0} />
            {methods.formState.errors.shift && (
              <div className="absolute mt-[5px] text-[12px] text-crimson dark:text-red">
                {t('setting.desc.birPriceError')}
              </div>
            )}
          </div>
          <div className="w-[120px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.bidPrice')}
            </div>
            <div className="h-[40px] leading-[40px]">
              {bidPrice ? `${bnFormat(bidPrice)}` : '-'}
            </div>
          </div>
          <Button
            className="min-w-[auto] px-[30px] py-[10px]"
            type="submit"
            disabled={Object.keys(methods.formState.errors).length !== 0}
          >
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default BidPrice;
