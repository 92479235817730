import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Page } from 'src/constant/Page';
import { WithdrawalRecord } from 'src/model/Withdrawal';
import { openSnackbar } from 'src/redux/uiSlice';
import { getWithdrawalRecordById } from 'src/service/depositWithdrawalAdminService';
import { bnFormat } from 'src/util/bigNumber';

const WithdrawalAdminDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: withdrawalId } = useParams<{ id: string }>();
  const [withdrawal, setWithdrawal] = useState<WithdrawalRecord>();

  useEffect(() => {
    if (!withdrawalId) return;

    getWithdrawalRecordById(withdrawalId)
      .then((res) => setWithdrawal(res))
      .catch((e) => {
        navigate(Page.WithdrawalRecord, { replace: true });
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [withdrawalId]);

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">{`${t(
        'withdrawalRecord.desc.withdrawal',
      )} ${withdrawal?.id ?? ''}`}</div>
      <div className="mt-[30px] bg-white px-[25px] py-[30px]">
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            ID
          </div>
          <div className="flex-1 break-all leading-[21px]">{withdrawal?.id}</div>
        </div>
        {withdrawal?.userId && (
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('withdrawalRecord.desc.user')}
            </div>
            <div className="flex-1 break-all leading-[21px]">
              <div
                className="w-fit cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
                onClick={() => {
                  if (withdrawal.user?.side === 'ask')
                    navigate(`${Page.UserAsk}/${withdrawal.userId}`);
                  else if (withdrawal.user?.side === 'bid')
                    navigate(`${Page.UserBid}/${withdrawal.userId}`);
                  else navigate(`${Page.UserAdmin}/${withdrawal.userId}`);
                }}
              >
                {t(`withdrawalRecord.desc.side.${withdrawal?.user?.side}`)}-
                {withdrawal?.user?.email}
              </div>
            </div>
          </div>
        )}
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.type')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {withdrawal?.isInternal
              ? t('withdrawalRecord.desc.internalTransfer')
              : t('withdrawalRecord.desc.withdrawal')}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.amount')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {withdrawal ? bnFormat(withdrawal.amount) : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.fee')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {withdrawal ? bnFormat(withdrawal.fee) : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.coin')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {(withdrawal?.network
              ? `${withdrawal.coin}-${withdrawal.network}`
              : withdrawal?.coin
            )?.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.address')}
          </div>
          <div className="flex-1 break-all leading-[21px]">{withdrawal?.address}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            Transaction Hash
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {withdrawal?.transactionHash ?? '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.status')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {t(`withdrawalRecord.desc.statusDisplay.${withdrawal?.status}`)}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.withdrawalDate')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {withdrawal?.createdAt
              ? format(new Date(withdrawal.createdAt), 'yyyy/MM/dd HH:mm:ss')
              : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('withdrawalRecord.desc.confirmDate')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {withdrawal?.confirmedAt
              ? format(new Date(withdrawal.confirmedAt), 'yyyy/MM/dd HH:mm:ss')
              : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalAdminDetail;
