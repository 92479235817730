import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { Page } from 'src/constant/Page';
import { DepositRecord } from 'src/model/Deposit';
import { openSnackbar } from 'src/redux/uiSlice';
import { getDepositRecordById } from 'src/service/depositWithdrawalAdminService';
import { bnFormat } from 'src/util/bigNumber';

const DepositAdminDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: depositId } = useParams<{ id: string }>();
  const [deposit, setDeposit] = useState<DepositRecord>();

  useEffect(() => {
    if (!depositId) return;

    getDepositRecordById(depositId)
      .then((res) => setDeposit(res))
      .catch((e) => {
        navigate(Page.DepositRecord, { replace: true });
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [depositId]);

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">{`${t(
        'depositRecord.desc.deposit',
      )} ${deposit?.id ?? ''}`}</div>
      <div className="mt-[30px] bg-white px-[25px] py-[30px]">
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            ID
          </div>
          <div className="flex-1 break-all leading-[21px]">{deposit?.id}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.user')}
          </div>
          {deposit?.user && (
            <div className="flex-1 break-all leading-[21px]">
              <div
                className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
                onClick={() => {
                  if (deposit.user?.side === 'ask') navigate(`${Page.UserAsk}/${deposit.userId}`);
                  else if (deposit.user?.side === 'bid')
                    navigate(`${Page.UserBid}/${deposit.userId}`);
                  else navigate(`${Page.UserAdmin}/${deposit.userId}`);
                }}
              >
                {t(`depositRecord.desc.side.${deposit.user.side}`)}-{deposit.user.email}
              </div>
            </div>
          )}
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.amount')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {deposit ? bnFormat(deposit.amount) : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.coin')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {(deposit?.network
              ? `${deposit.coin}-${deposit.network}`
              : deposit?.coin
            )?.toUpperCase()}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.address')}
          </div>
          <div className="flex-1 break-all leading-[21px]">{deposit?.address}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            Transaction Hash
          </div>
          <div className="flex-1 break-all leading-[21px]">{deposit?.transactionHash ?? '-'}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.depositDate')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {deposit?.createdAt ? format(new Date(deposit.createdAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositAdminDetail;
