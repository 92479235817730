import balanceEndpoint from 'src/api/balanceEndpoint';
import balanceTransactionEndpoint from 'src/api/balanceTransactionEndpoint';
import userEndpoint from 'src/api/userEndpoint';
import { GetUsersParams } from 'src/model/Api';
import { CreateUserForm, EditProxyProfitForm, UpdateUserForm } from 'src/model/Form';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';

export const getUserList = async (
  params: {
    side: string;
    query?: string;
    status: string;
  },
  paginationParams?: PaginationParams,
) => {
  try {
    dispatch(startWaiting());
    const status = params.status === 'waiting' ? '0' : undefined;
    const res = await userEndpoint.getUsers({
      side: params.side,
      query: params.query?.length === 0 ? undefined : params.query,
      isApproved: status,
      isVerified: '1',
      ...paginationParams,
    });

    dispatch(
      updateFilter({
        key:
          params.side === 'ask'
            ? 'adminUserAsk'
            : params.side === 'bid'
            ? 'adminUserBid'
            : 'adminUserAdmin',
        filter: {
          query: params?.query,
          status,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUserListByQuery = async (side?: string, query?: GetUsersParams['query']) => {
  const res = await userEndpoint.getUsers({ side, query, isVerified: '1' });

  return res.data;
};

export const getUserById = async (id: string) => {
  try {
    dispatch(startWaiting());
    const [resUser, resUserRelation, resBalance] = await Promise.all([
      userEndpoint.getTheUser(id),
      userEndpoint.getUserRelation(id),
      balanceEndpoint.getBalanceList({
        user: id,
      }),
    ]);

    return {
      user: resUser.data,
      relation: resUserRelation.data,
      balance: resBalance.data,
    };
  } finally {
    dispatch(finishWaiting());
  }
};

export const createUser = async (formData: CreateUserForm) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.postUsers({
      email: formData.email,
      password: formData.password,
      side: formData.side,
      role: formData.side === 'bid' ? undefined : formData.role,
      mobile: (formData.mobile?.length ?? 0) > 0 ? formData.mobile : undefined,
      slug: (formData.slug?.length ?? 0) > 0 ? formData.slug : undefined,
    });
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateUser = async (id: string, data: UpdateUserForm) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.putUser(id, {
      email: data.email,
      password: (data.password?.length ?? 0) > 0 ? data.password : undefined,
      role: data.role,
      mobile: (data.mobile?.length ?? 0) > 0 ? data.mobile : undefined,
      slug: (data.slug?.length ?? 0) > 0 ? data.slug : undefined,
      paymentMethodType: data.paymentMethodType !== 'all' ? data.paymentMethodType : null,
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const editProxyProfit = async (id: string, data: EditProxyProfitForm) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.putUser(id, {
      commissionDelta: Number(data.profit),
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const approveUser = async (userId: string) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.patchUserApprove(userId);
  } finally {
    dispatch(finishWaiting());
  }
};

export const rejectUser = async (userId: string) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.deleteUser(userId);
  } finally {
    dispatch(finishWaiting());
  }
};

export const blockUser = async (id: string) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.blockUser(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const unblockUser = async (id: string) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.unblockUser(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUserBalance = async (user: string, coin: string) => {
  try {
    dispatch(startWaiting());
    const res = await balanceEndpoint.getBalanceList({ user, coin });

    if (res.data.length === 0) return null;

    return res.data[0];
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUserBalanceTransactions = async (
  userId: string,
  coin: string,
  type: string,
  params?: PaginationParams,
) => {
  try {
    dispatch(startWaiting());
    const res = await balanceTransactionEndpoint.getBalanceTransaction({
      ...params,
      order: 'desc',
      coin,
      user: userId,
      type: type === 'all' ? undefined : type,
    });

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const setUserAgentRelation = async (id: string, parentId: string) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.putUser(id, { parentId });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const setUserAsAgent = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.putUser(id, { isAgent: true });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const setUserNotAgent = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.putUser(id, { isAgent: false });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const enforceDeactivateTfa = async (id: string) => {
  try {
    dispatch(startWaiting());
    await userEndpoint.deactivateUserOtp(id, 'gauth');
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUserAskerLimitList = async (
  params: {
    query?: string;
  } & PaginationParams,
) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.getUsers({
      ...params,
      side: 'ask',
      query: params.query?.length === 0 ? undefined : params.query,
      isVerified: '1',
      isFillsLimited: '1',
    });

    dispatch(
      updateFilter({
        key: 'adminAskerLimit',
        filter: {
          query: params?.query,
          limit: params?.limit,
          page: getPage(params?.offset, params?.limit),
        },
      }),
    );

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const updateUserAskerLimit = async (id: string, limit: string | null) => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.putUser(id, {
      fillsLimit: limit,
    });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
