import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import IcEmpty from 'src/image/ic-empty-light.svg';
import { BidOrder, FillAdmin } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { cancelOrder, getBidOrderDetailById } from 'src/service/orderAdminService';
import { bn, bnFormat } from 'src/util/bigNumber';
import Card from './component/Card';
import Table from './component/Table';

const OrderAdminDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { id: orderId } = useParams<{ id: string }>();
  const [order, setOrder] = useState<BidOrder>();
  const [fillList, setFillList] = useState<FillAdmin[]>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const showButton = useMemo(() => {
    if (!order || !fillList || !accountInfo) return false;
    if (
      (order.status === 'open' || order.status === 'executed') &&
      fillList.filter((v) => v.status === 'executed' || v.status === 'received').length ===
        fillList.length &&
      (accountInfo.role === 'assistant' || accountInfo.role === 'manager')
    )
      return true;

    return false;
  }, [order, fillList, accountInfo]);

  useEffect(() => {
    if (!orderId) return;

    getBidOrderDetailById(orderId)
      .then(([resOrder, resFill]) => {
        setOrder(resOrder);
        setFillList(resFill);
      })
      .catch((e) => {
        navigate(Page.Order, { replace: true });
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [orderId, refresh]);

  const onFinish = () => {
    if (!orderId) return;

    cancelOrder(orderId)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mx-0 mb-[30px] mt-[20px] flex flex-row justify-between text-[32px] font-bold">{`${t(
        'order.desc.order',
      )} ${order?.id ?? ''}`}</div>
      {showButton && (
        <Button className="mb-[30px] px-[30px] py-[10px] font-bold" onClick={onFinish}>
          {t('order.act.finishOrder')}
        </Button>
      )}
      <div className="bg-white px-[25px] py-[30px]">
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            ID
          </div>
          <div className="flex-1 break-all leading-[21px]">{order?.id}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.status')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order ? t(`order.desc.statusDisplay.${order.status}`) : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.buyer')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            <div
              className="w-fit cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (order?.user.side === 'ask') navigate(`${Page.UserAsk}/${order.userId}`);
                else if (order?.user.side === 'bid') navigate(`${Page.UserBid}/${order.userId}`);
                else navigate(`${Page.UserAdmin}/${order?.userId}`);
              }}
            >
              {t(`order.desc.side.${order?.user.side}`)}-{order?.user.email}
            </div>
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.orderAmount')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order
              ? `${bn(order.openAmount).plus(bn(order.filledAmount))} ${order.base.toUpperCase()}`
              : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.filledAmount')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order ? `${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}` : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.sentAmount')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order ? `${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}` : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.price')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order ? `${bnFormat(order.price)} ${order.quote.toUpperCase()}` : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.total')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order ? `${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}` : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.paidTotal')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order ? `${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}` : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.unpaidTotal')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order
              ? `${bn(order.filledTotal)
                  .minus(order.sentTotal)
                  .toFormat()} ${order.quote.toUpperCase()}`
              : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.createTime')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order?.createdAt ? format(new Date(order.createdAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.expiredTime')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order?.expiredAt ? format(new Date(order.expiredAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.completeTime')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {order?.completedAt ? format(new Date(order.completedAt), 'yyyy/MM/dd HH:mm:ss') : '-'}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[100px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('order.desc.profit')}
          </div>
          <div className="flex-1 break-all leading-[21px]">
            {fillList
              ? `${fillList
                  .filter((v) => v.status === 'received')
                  .reduce((sum, current) => sum.plus(bn(current.profit)), bn(0))
                  .toFormat()} ${order?.base.toUpperCase()}`
              : '-'}
          </div>
        </div>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white p-[25px] text-[14px]">
        <div className="mb-[10px] text-[24px] font-bold">{t('fill.desc.fill')}</div>
        {fillList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {fillList &&
          fillList.length > 0 &&
          (isBiggerThanMd ? <Table data={fillList} /> : <Card data={fillList} />)}
      </div>
    </div>
  );
};

export default OrderAdminDetail;
