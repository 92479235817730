import classNames from 'classnames';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SystemBalanceTransaction } from 'src/model/Property';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: SystemBalanceTransaction[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const transactionTypeMapping = useMemo(
    () => ({
      approvement: t('systemProperty.desc.transactionType.approvement'),
      deposit: t('systemProperty.desc.transactionType.deposit'),
      manipulation: t('systemProperty.desc.transactionType.manipulation'),
      payin: t('systemProperty.desc.transactionType.payin'),
      payout: t('systemProperty.desc.transactionType.payout'),
      'withdrawal-fee': t('systemProperty.desc.transactionType.withdrawalFee'),
      withdrawal: t('systemProperty.desc.transactionType.withdrawal'),
      freeze: t('systemProperty.desc.transactionType.freeze'),
      unfreeze: t('systemProperty.desc.transactionType.unfreeze'),
    }),
    [t],
  );

  const elementMapFunction = (
    transaction: SystemBalanceTransaction,
    index: number,
    array: SystemBalanceTransaction[],
  ) => (
    <div key={transaction.id} className="flex flex-col">
      <div className="flex flex-row">
        <div
          className={classNames(
            'box-border break-words pr-[10px]',
            'text-grey-500 dark:text-grey-700',
            'w-1/5',
          )}
        >
          {format(Date.parse(transaction.createdAt), 'yyyy/MM/dd HH:mm:ss')}
        </div>
        <div className={classNames('box-border break-words pr-[10px]', 'w-1/5')}>
          {bnFormat(transaction.coldWalletAmount)}
        </div>
        <div className={classNames('box-border break-words pr-[10px]', 'w-1/5')}>
          {bnFormat(transaction.coldWalletBalance)}
        </div>
        <div
          className={classNames(
            'box-border break-words pr-[10px]',
            'flex flex-row items-start gap-[10px] text-[14px]',
            'w-1/5',
          )}
        >
          {transactionTypeMapping[transaction.type]}
        </div>
        <div className={classNames('box-border break-words pr-[10px]', 'w-1/5')}>
          {transaction.adminAction?.note}
        </div>
      </div>
      <div
        className={classNames('my-[15px] h-[1px] bg-light-200 dark:bg-dark-700', {
          ['hidden']: index >= array.length,
        })}
      />
    </div>
  );

  return (
    <div className="mt-[15px]">
      <div className="flex flex-row text-[14px] font-bold text-grey-500 dark:text-grey-700">
        <div className="w-1/5">{t('systemProperty.desc.time')}</div>
        <div className="w-1/5">{t('systemProperty.desc.amount')}</div>
        <div className="w-1/5">{t('systemProperty.desc.balance')}</div>
        <div className="w-1/5">{t('systemProperty.desc.information')}</div>
        <div className="w-1/5">{t('systemProperty.desc.note')}</div>
      </div>
      <div className="my-[15px] h-[1px] bg-light-200 dark:bg-dark-700" />
      {data.map(elementMapFunction)}
    </div>
  );
};

export default Table;
