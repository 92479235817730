import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { RebateDetail } from 'src/model/Rebate';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<RebateDetail>;
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (rebate: RebateDetail) => (
    <div key={rebate.id}>
      <div className="px-[0px] py-[15px]">
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className={classNames(
              'font-bold text-turquoise dark:text-aqua',
              'cursor-pointer hover:underline',
            )}
            onClick={() => navigate(`${Page.ParentRebate}/${rebate.id}`)}
          >
            {rebate.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('rebate.desc.user')}</div>
          <div
            className={classNames(
              'font-bold text-turquoise dark:text-aqua',
              'cursor-pointer hover:underline',
            )}
            onClick={() => navigate(`${Page.UserAsk}/${rebate.userId}`)}
          >
            {rebate.user.email}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('rebate.desc.coin')}</div>
          <div>{rebate.coin.toUpperCase()}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('rebate.desc.askAmount')}
          </div>
          <div>{bnFormat(rebate.askAmount)}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('rebate.desc.amount')}
          </div>
          <div>{bnFormat(rebate.amount)}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('rebate.desc.createdAt')}
          </div>
          <div>{format(new Date(rebate.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
