import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormTextArea from 'src/component/FormTextArea';
import Input from 'src/component/Input';
import Modal from 'src/component/Modal';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { AccountUser } from 'src/model/AccountUser';
import { MessageForm } from 'src/model/Form';
import { CannedMessage } from 'src/model/Message';
import { openSnackbar } from 'src/redux/uiSlice';
import { getCannedMessageList, sentMessage } from 'src/service/fillService';

type Props = {
  open: boolean;
  onClose: (doRefresh: boolean) => void;
  to?: AccountUser;
};

const MessageModal = ({ open, onClose, to }: Props) => {
  const { t } = useTranslation();
  const { id: fillId } = useParams<{ id: string }>();
  const methods = useForm<MessageForm>();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<CannedMessage[]>();

  useEffect(() => {
    getCannedMessageList()
      .then((res) => setMessage(res))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  const handleClose = (doRefresh: boolean) => () => {
    onClose(doRefresh);
    methods.reset();
  };

  const onSelect = (id: string) => {
    const selected = message?.find((v) => v.id === id);
    if (!selected) return;
    methods.setValue('subject', selected.subject);
    methods.setValue('content', selected.content);
  };

  const onSubmit = (data: MessageForm) => {
    sentMessage(fillId ?? 'xx', to?.id ?? 'yy', data.subject, data.content)
      .then(handleClose(true))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose(false)}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mb-[30px] text-[32px] font-bold">
          {to?.side === 'ask'
            ? t('fill.desc.sendMessageToAsker')
            : t('fill.desc.sendMessageToBidder')}
        </div>
        <div className="my-5">
          <Select onChange={(v) => onSelect(v)} label={t('fill.desc.canned')}>
            {message?.map((v) => (
              <SelectOption key={v.id} value={v.id}>
                {v.name}
              </SelectOption>
            ))}
          </Select>
        </div>
        <Input value={to?.email ?? ''} label="Email" />
        <div className="mx-0 my-5">
          <FormInput name="subject" label={t('fill.desc.subject')} required />
        </div>
        <FormTextArea
          name="content"
          label={t('fill.desc.content')}
          required
          className="h-[200px]"
        />
        <div className="mt-[30px] flex justify-end">
          <Button appearance="primary" type="submit">
            {t('act.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MessageModal;
