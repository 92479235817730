import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Input from 'src/component/Input';
import Modal from 'src/component/Modal';
import TextArea from 'src/component/TextArea';
import { Message } from 'src/model/Message';

type Props = {
  open: boolean;
  onClose: () => void;
  message?: Message;
};

const MessageModal = ({ open, onClose, message }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={onClose}>
      <div>
        <div className="mb-[30px] text-[32px] font-bold">{t('messageAdmin.desc.modalHead')}</div>
        <Input value={message?.user.email} label="Email" />
        <div className="mx-[0] my-[20px]">
          <Input value={message?.subject} label={t('messageAdmin.desc.subject')} />
        </div>
        <TextArea
          value={message?.content}
          className="h-[200px]"
          label={t('messageAdmin.desc.content')}
        />
        <div className="mt-[30px] flex justify-end">
          <Button appearance="primary" type="button" onClick={onClose}>
            {t('messageAdmin.act.close')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default MessageModal;
