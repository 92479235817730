import { t } from 'i18next';
import orderEndpoint from 'src/api/orderEndpoint';
import { UserOrder } from 'src/model/Order';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getTradeList = async (
  tradeStatus?: string,
  sorting?: string,
  paginationParams?: PaginationParams,
  user?: string,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrders<Array<UserOrder>>(
      {
        ...paginationParams,
        status: tradeStatus,
        sort: sorting === 'default' ? undefined : sorting,
        side: 'ask',
        order: 'desc',
        user,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminTrade',
        filter: {
          id: undefined,
          status: tradeStatus,
          sorting,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    if (csv) toCsvFile('trade.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getTradeById = async (id: string, saveFilter?: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getUserOrderId<UserOrder>(id);
    if (res.data.side !== 'ask') throw t('error.common.E-404-0');

    saveFilter && dispatch(updateFilter({ key: 'adminTrade', filter: { id } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const setPriorityOfTrade = async (id: string, isPriority: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.updateOrder(id, { priority: isPriority ? '1' : '0' });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};
