import classNames from 'classnames';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { SystemBalanceTransaction } from 'src/model/Property';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: SystemBalanceTransaction[];
  navigateCallback: (transaction: SystemBalanceTransaction) => void;
};

const Card = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();
  const transactionTypeMapping = useMemo(
    () => ({
      approvement: t('systemProperty.desc.transactionType.approvement'),
      deposit: t('systemProperty.desc.transactionType.deposit'),
      manipulation: t('systemProperty.desc.transactionType.manipulation'),
      payin: t('systemProperty.desc.transactionType.payin'),
      payout: t('systemProperty.desc.transactionType.payout'),
      'withdrawal-fee': t('systemProperty.desc.transactionType.withdrawalFee'),
      withdrawal: t('systemProperty.desc.transactionType.withdrawal'),
      freeze: t('systemProperty.desc.transactionType.freeze'),
      unfreeze: t('systemProperty.desc.transactionType.unfreeze'),
    }),
    [t],
  );

  const onDetailClick = (transaction: SystemBalanceTransaction) => () => {
    navigateCallback(transaction);
  };

  const elementMapFunction = (
    transaction: SystemBalanceTransaction,
    index: number,
    array: SystemBalanceTransaction[],
  ) => (
    <div key={transaction.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('systemProperty.desc.amount')}
          </div>
          <div className="text-[14px]">{bnFormat(transaction.amount)}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('systemProperty.desc.balance')}
          </div>
          <div className="text-[14px]">{bnFormat(transaction.balance)}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('systemProperty.desc.frozenAmount')}
          </div>
          <div className="text-[14px]">{bnFormat(transaction.frozenAmount)}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('systemProperty.desc.frozenBalance')}
          </div>
          <div className="text-[14px]">{bnFormat(transaction.frozenBalance)}</div>
        </div>
        <div className="flex flex-row items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('systemProperty.desc.information')}
          </div>
          <div className="flex flex-row gap-[10px] text-[14px]">
            {transactionTypeMapping[transaction.type]}
            {['deposit', 'withdrawal', 'withdrawal-fee'].includes(transaction.type) && (
              <Button
                style={{ visibility: 'hidden' }}
                appearance="text"
                onClick={onDetailClick(transaction)}
              >
                {t('systemProperty.act.detail')}
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-[2px] text-[12px] text-grey-500 dark:text-grey-700">
          <div>{format(Date.parse(transaction.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
      </div>
      <div
        className={classNames('mt-[13px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
