import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import Modal from 'src/component/Modal';
import { ManipulationForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { manipulation } from 'src/service/homeService';

type Props = {
  coin?: string;
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
};

const ManipulationModal = ({ coin, open, onClose, onRefresh }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<ManipulationForm>();

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = (data: ManipulationForm) => {
    handleClose();
    if (!coin) return;
    manipulation(data, coin)
      .then(onRefresh)
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={() => handleClose()}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col">
        <div className="text-[32px] font-bold text-black-900 dark:text-white">
          {t('frozen.manipulationModalTitle')}
        </div>
        <div className="mt-[25px]">
          <FormInput
            label={t('frozen.amount')}
            name="amount"
            type="number"
            required
            asterisked
            hint={t('frozen.manipulationModalHint')}
          />
        </div>
        <div className="mt-[25px]">
          <FormInput label={t('frozen.note')} name="note" />
        </div>
        <div className="mt-[40px] text-end">
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ManipulationModal;
