import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import UserSearch from 'src/component/UserSearch';

type Props = {
  open: boolean;
  onClose: () => void;
  onTransfer: (parentId: string) => void;
};

const TransferModal = ({ open, onClose, onTransfer }: Props) => {
  const { t } = useTranslation();
  const [parentId, setParentId] = useState<string>();

  return (
    <Modal open={open} handleClose={() => onClose()}>
      <div className="flex flex-col">
        <div className="mb-[10px] text-[32px] font-bold">{t('userDetail.relation.transfer')}</div>
        <UserSearch
          label={t('userDetail.relation.transferLabel')}
          filteredSide="ask"
          setUserId={(v) => setParentId(v)}
        />
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button className="font-bold" appearance="outlined" onClick={() => onClose()}>
            {t('act.cancel')}
          </Button>
          <Button
            className="font-bold"
            appearance="primary"
            onClick={() => onTransfer(parentId ?? 'xxx')}
            disabled={parentId?.length === 0}
          >
            {t('act.submit')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default TransferModal;
