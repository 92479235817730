import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import SortingSelect from 'src/component/SortingSelect';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import useQuery from 'src/hook/useQuery';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { FillForm } from 'src/model/Form';
import { FillAdmin } from 'src/model/Order';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getFillById, getFillList } from 'src/service/fillService';
import Card from './component/Card';
import Table from './component/Table';

const FillPage = () => {
  const { status: queryStatus } = useQuery<{ status?: string }>();
  const dispatch = useDispatch();
  const { adminFill: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const { t, ready } = useTranslation();
  const [fillterStatus, setFillterStatus] = useState<string | undefined>(
    queryStatus ?? filter.status,
  );
  const [fillId, setFillId] = useState<string>(filter.id ?? '');
  const [askerEmail, setAskerEmail] = useState<string>(filter.askerEmail ?? '');
  const [bankAccount, setBankAccount] = useState<string>(filter.bankAccount ?? '');
  const [filterSorting, setFilterSorting] = useState<string>(filter.sorting ?? 'default');
  const [fillList, setFillList] = useState<Array<FillAdmin>>();
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<FillForm>({
    defaultValues: {
      id: filter.id,
      askerEmail: filter.askerEmail,
      bankAccount: filter.bankAccount,
    },
  });

  useEffect(() => {
    if (fillId) {
      getFillById(fillId, true)
        .then((res) => {
          setFillList([res]);
          setCount(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getFillList(
      { status: fillterStatus, sorting: filterSorting, email: askerEmail, bankAccount },
      { begin, end, offset, limit },
      state?.userId,
    )
      .then((res) => {
        setFillList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [
    fillId,
    askerEmail,
    bankAccount,
    fillterStatus,
    filterSorting,
    begin,
    end,
    offset,
    limit,
    state,
  ]);

  const onStatusFilterChange = (value: string) => {
    setFillterStatus(value === 'all' ? undefined : value);
    paginationProps.setPage(1);
  };

  const onSortingFilterChange = (value: string) => {
    setFilterSorting(value);
    paginationProps.setPage(1);
  };

  const onQuery = (data: FillForm) => {
    setFillId(data.id);
    setAskerEmail(data.askerEmail);
    setBankAccount(data.bankAccount);
  };

  const onDownload = () => {
    getFillList(
      { status: fillterStatus, sorting: filterSorting, email: askerEmail, bankAccount },
      { begin, end, offset, limit },
      state?.userId,
      true,
    ).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('fill.heading')}
      </div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-0 md:gap-[60px] md:px-[30px]">
        <Select
          label={t('fill.desc.status')}
          onChange={onStatusFilterChange}
          defaultValue={queryStatus ?? filter.status ?? 'all'}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          <SelectOption value={'all'}>{t('fill.desc.all')}</SelectOption>
          <SelectOption value={'unfinished'}>{t('fill.desc.inProcessing')}</SelectOption>
          <SelectOption value={'rejected'}>{t('fill.desc.rejected')}</SelectOption>
        </Select>
        <DateFilter
          label={t('fill.desc.createDate')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <div className="flex flex-wrap justify-between gap-x-[10px]">
          <Form
            methods={methods}
            onSubmit={onQuery}
            className="flex flex-1 flex-wrap items-center gap-[10px] pb-[15px]"
          >
            <FormSearchInput
              name="id"
              placeholder={t('fill.desc.searchPlaceholderId')}
              onQuery={onQuery}
            />
            <FormSearchInput
              name="askerEmail"
              placeholder={t('fill.desc.searchPlaceholderEmail')}
              onQuery={onQuery}
            />
            <FormSearchInput
              name="bankAccount"
              placeholder={t('fill.desc.searchPlaceholderBank')}
              onQuery={onQuery}
            />
            <Button appearance="secondary" size="small" type="submit">
              {t('fill.desc.search')}
            </Button>
            <div className="flex w-[120px] gap-[8px]">
              <img src={IcLimit} />
              <div className="grow">
                <Select
                  value={limit.toString()}
                  onChange={(v) => {
                    setLimit(Number(v));
                    paginationProps.setPage(1);
                  }}
                >
                  <SelectOption value="5">{'5'}</SelectOption>
                  <SelectOption value="10">{'10'}</SelectOption>
                  <SelectOption value="50">{'50'}</SelectOption>
                  <SelectOption value="100">{'100'}</SelectOption>
                </Select>
              </div>
            </div>
            <Button
              className="font-bold"
              size="small"
              appearance="secondary"
              type="button"
              onClick={onDownload}
            >
              {t('fill.desc.download')}
            </Button>
          </Form>
          <div className="pb-[15px]">
            <SortingSelect
              defaultValue={filter.sorting ?? 'default'}
              onChange={onSortingFilterChange}
            >
              <SelectOption value="default">{t('fill.desc.sortingOption.default')}</SelectOption>
              <SelectOption value="-ask_price">
                {t('fill.desc.sortingOption.sellingPriceDesc')}
              </SelectOption>
              <SelectOption value="ask_price">
                {t('fill.desc.sortingOption.sellingPriceAsce')}
              </SelectOption>
              <SelectOption value="-bid_price">
                {t('fill.desc.sortingOption.buyingPriceDesc')}
              </SelectOption>
              <SelectOption value="bid_price">
                {t('fill.desc.sortingOption.buyingPriceAsce')}
              </SelectOption>
              <SelectOption value="-ask_amount">
                {t('fill.desc.sortingOption.sellingAmountDesc')}
              </SelectOption>
              <SelectOption value="ask_amount">
                {t('fill.desc.sortingOption.sellingAmountAsce')}
              </SelectOption>
              <SelectOption value="-bid_amount">
                {t('fill.desc.sortingOption.buyingAmountDesc')}
              </SelectOption>
              <SelectOption value="bid_amount">
                {t('fill.desc.sortingOption.buyingAmountAsce')}
              </SelectOption>
              <SelectOption value="-total">{t('fill.desc.sortingOption.priceDesc')}</SelectOption>
              <SelectOption value="total">{t('fill.desc.sortingOption.priceAsce')}</SelectOption>
              <SelectOption value="-profit">{t('fill.desc.sortingOption.profitDesc')}</SelectOption>
              <SelectOption value="profit">{t('fill.desc.sortingOption.profitAsce')}</SelectOption>
            </SortingSelect>
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {fillList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {fillList &&
          fillList.length > 0 &&
          (isBiggerThanMd ? <Table data={fillList} /> : <Card data={fillList} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default FillPage;
