import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { DepositRecord } from 'src/model/Deposit';
import { DepositQueryForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import {
  getDepositRecordById,
  getDepositRecordList,
} from 'src/service/depositWithdrawalAdminService';
import { getCrypto } from 'src/service/settingService';
import Card from './component/Card';
import Table from './component/Table';

const DepositAdmin = () => {
  const dispatch = useDispatch();
  const { adminDeposit: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const { t, ready } = useTranslation();
  const [depositId, setDepositId] = useState<string>(filter.id ?? '');
  const [depositList, setDepositList] = useState<Array<DepositRecord>>();
  const [coin, setCoin] = useState<string | undefined>(filter.coin);
  const [cryptoList, setCryptoList] = useState<{ id: string; value: string }[]>();
  const [minAmount, setMinAmount] = useState<string>(filter.minAmount ?? '');
  const [maxAmount, setMaxAmount] = useState<string>(filter.maxAmount ?? '');
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<DepositQueryForm>({
    defaultValues: {
      id: filter.id,
      minAmount: filter.minAmount,
      maxAmount: filter.maxAmount,
    },
  });

  useEffect(() => {
    getCrypto()
      .then((res) =>
        setCryptoList([
          { id: 'all', value: t('trade.desc.all') },
          ...res.map((v) => ({
            id: v.id,
            value: v.network
              ? `${v.coin.toUpperCase()}-${v.network.toUpperCase()}`
              : v.coin.toUpperCase(),
          })),
        ]),
      )
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  useEffect(() => {
    if (depositId) {
      getDepositRecordById(depositId, true)
        .then((res) => {
          setDepositList([res]);
          setCount(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getDepositRecordList(
      { user: state?.userId, coin, minAmount, maxAmount },
      { begin, end, offset, limit },
    )
      .then((res) => {
        setDepositList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [depositId, begin, end, offset, limit, state, coin, minAmount, maxAmount]);

  const onCoinFilterChange = (value: string) => {
    setCoin(value !== 'all' ? value : undefined);
    paginationProps.setPage(1);
  };

  const onQuery = (data: DepositQueryForm) => {
    setDepositId(data.id);
    setMinAmount(data.minAmount);
    setMaxAmount(data.maxAmount);
  };

  const onDownload = () => {
    getDepositRecordList(
      { user: state?.userId, coin, minAmount, maxAmount },
      { begin, end, offset, limit },
      true,
    ).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  if (!ready || !cryptoList) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('depositRecord.heading')}
      </div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-0 md:mt-[30px] md:gap-[60px] md:px-[30px]">
        <Select
          label={t('withdrawalRecord.desc.coin')}
          defaultValue={filter.coin ?? 'all'}
          onChange={onCoinFilterChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          {cryptoList.map((v) => (
            <SelectOption key={v.id} value={v.id}>
              {v.value}
            </SelectOption>
          ))}
        </Select>
        <DateFilter
          label={t('depositRecord.desc.dateFilter')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <Form
          methods={methods}
          onSubmit={onQuery}
          className="flex flex-row flex-wrap items-center gap-[30px] pb-[25px]"
        >
          <FormSearchInput
            name="id"
            placeholder={t('depositRecord.desc.depositId')}
            onQuery={onQuery}
          />
          <div className="flex w-[120px] gap-[8px]">
            <img src={IcLimit} />
            <div className="grow">
              <Select
                value={limit.toString()}
                onChange={(v) => {
                  setLimit(Number(v));
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="5">{'5'}</SelectOption>
                <SelectOption value="10">{'10'}</SelectOption>
                <SelectOption value="50">{'50'}</SelectOption>
                <SelectOption value="100">{'100'}</SelectOption>
              </Select>
            </div>
          </div>
          <div className="flex items-center gap-[5px]">
            <FormSearchInput
              className="w-fit max-w-[300px] "
              name="minAmount"
              placeholder={t('depositRecord.desc.searchMinAmount')}
              onQuery={onQuery}
            />
            ~
            <FormSearchInput
              className="w-fit max-w-[300px]"
              name="maxAmount"
              placeholder={t('depositRecord.desc.searchMaxAmount')}
              onQuery={onQuery}
            />
          </div>
          <Button appearance="secondary" size="small" type="submit">
            {t('depositRecord.desc.search')}
          </Button>
          <Button
            className="font-bold"
            size="small"
            appearance="secondary"
            type="button"
            onClick={onDownload}
          >
            {t('depositRecord.desc.download')}
          </Button>
        </Form>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {depositList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {depositList &&
          depositList.length > 0 &&
          (isBiggerThanMd ? <Table data={depositList} /> : <Card data={depositList} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default DepositAdmin;
