import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FillStatusLabel from 'src/component/FillStatusLabel';
import { Page } from 'src/constant/Page';
import { FillAdmin } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<FillAdmin>;
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (fill: FillAdmin) => (
    <div key={fill.id}>
      <div className="flex flex-col gap-[4px] px-0 py-[20px]">
        <div className="flex items-center gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Fill}/${fill.id}`)}
          >
            {fill.id}
          </div>
        </div>
        <div className="flex flex-row">
          <div className="flex w-[50%] items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.price')}
            </div>
            <div className="text-[14px]">{`${bnFormat(
              fill.total,
            )} ${fill.quote.toUpperCase()}`}</div>
          </div>
          <div className="flex w-[50%] items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.profit')}
            </div>
            <div className="text-[14px]">{`${bnFormat(
              fill.profit,
            )} ${fill.base.toUpperCase()}`}</div>
          </div>
        </div>

        <div className="rounded-[4px] bg-light-100 px-[12px] py-[8px]">
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.seller')}
            </div>
            <div
              className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (fill.asker.side === 'ask') navigate(`${Page.UserAsk}/${fill.askerId}`);
                else if (fill.asker.side === 'bid') navigate(`${Page.UserBid}/${fill.askerId}`);
                else navigate(`${Page.UserAdmin}/${fill.askerId}`);
              }}
            >
              {t(`fill.desc.side.${fill.asker.side}`)}-{fill.asker.email}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex w-[50%] items-center gap-[15px]">
              <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
                {t('fill.desc.sellingAmount')}
              </div>
              <div className="text-[14px]">{`${bnFormat(
                fill.askAmount,
              )} ${fill.base.toUpperCase()}`}</div>
            </div>
            <div className="flex w-[50%] items-center gap-[15px]">
              <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
                {t('fill.desc.sellingPrice')}
              </div>
              <div className="text-[14px]">{`${bnFormat(
                fill.askPrice,
              )} ${fill.quote.toUpperCase()}`}</div>
            </div>
          </div>
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.advertisement')}
            </div>
            <div
              className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => navigate(`${Page.Trade}/${fill.askId}`)}
            >
              {fill.askId}
            </div>
          </div>
        </div>

        <div className="rounded-[4px] bg-light-100 px-[12px] py-[8px]">
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyer')}
            </div>
            <div
              className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (fill.bidder.side === 'ask') navigate(`${Page.UserAsk}/${fill.bidderId}`);
                else if (fill.bidder.side === 'bid') navigate(`${Page.UserBid}/${fill.bidderId}`);
                else navigate(`${Page.UserAdmin}/${fill.bidderId}`);
              }}
            >
              {t(`fill.desc.side.${fill.bidder.side}`)}-{fill.bidder.email}
            </div>
          </div>
          <div className="flex flex-row">
            <div className="flex w-[50%] items-center gap-[15px]">
              <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
                {t('fill.desc.buyingAmount')}
              </div>
              <div className="text-[14px]">{`${bnFormat(
                fill.bidAmount,
              )} ${fill.base.toUpperCase()}`}</div>
            </div>
            <div className="flex w-[50%] items-center gap-[15px]">
              <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
                {t('fill.desc.buyingPrice')}
              </div>
              <div className="text-[14px]">{`${bnFormat(
                fill.bidPrice,
              )} ${fill.quote.toUpperCase()}`}</div>
            </div>
          </div>
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.order')}
            </div>
            <div
              className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => navigate(`${Page.Order}/${fill.bidId}`)}
            >
              {fill.bidId}
            </div>
          </div>
        </div>

        <div className="flex h-[23px] items-center justify-between">
          <div className="flex items-center gap-[15px]">
            <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">{`${t(
              'fill.desc.createdAt',
            )}/${t('fill.desc.status')}`}</div>
            <div className="text-[14px]">
              {format(Date.parse(fill.createdAt), 'yyyy/MM/dd HH:mm:ss')}
            </div>
          </div>
          <div className="flex gap-[4px]">
            <FillStatusLabel fill={fill} />
            {fill.askerMessagedAt && (
              <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
                {t('fill.desc.askerMessageSent')}
              </div>
            )}
            {fill.bidderMessagedAt && (
              <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
                {t('fill.desc.bidderMessageSent')}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
