import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import UserStatusLabel from 'src/component/UserStatusLabel';
import { AccountUser } from 'src/model/AccountUser';

type Props = {
  data: Array<AccountUser>;
  onEditUser: (user: AccountUser) => void;
};

const UserListTable = ({ data, onEditUser }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const elementMapFunction = (user: AccountUser) => (
    <div key={user.id}>
      <div className="flex flex-row">
        <div className="box-border w-[25%] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${location.pathname}/${user.id}`)}
          >
            {user.id}
          </div>
        </div>
        <div className="box-border w-[16.66%] break-all px-[12px] py-[16px] text-[14px]">
          {t(`user.desc.side.${user.side}`)}
        </div>
        <div className="box-border w-[16.66%] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          {user.slug ?? '-'}
        </div>
        <div className="box-border w-[25%] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          {user.email}
        </div>
        <div className="box-border w-[16.66%] break-all px-[12px] py-[16px] text-[14px]">
          <UserStatusLabel user={user} onEditUser={onEditUser} />
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[25%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          ID
        </div>
        <div className="box-border w-[16.66%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('user.desc.identity')}
        </div>
        <div className="box-border w-[16.66%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('user.desc.slug')}
        </div>
        <div className="box-border w-[25%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          Email
        </div>
        <div className="box-border w-[16.66%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('user.desc.status')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default UserListTable;
