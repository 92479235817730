import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Pagination from 'src/component/Pagination';
import RoundDateFilter from 'src/component/RoundDateFilter';
import RoundSelect from 'src/component/RoundSelect';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import { Balance } from 'src/model/Balance';
import { BalanceTransaction } from 'src/model/Property';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateBalance } from 'src/service/propertyService';
import { getUserBalance, getUserBalanceTransactions } from 'src/service/userService';
import { bnFormat } from 'src/util/bigNumber';
import Card from './component/Card';
import ConfirmUpdateBalanceModal from './component/ConfirmUpdateBalanceModal';
import Table from './component/Table';

const UserProperty = () => {
  const { t, ready } = useTranslation();
  const { id: userId, coin } = useParams<{ id: string; coin: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const [balance, setBalance] = useState<Balance>();
  const [transactionArray, setTransactionArray] = useState<Array<BalanceTransaction>>();
  const [filterType, setFilterType] = useState<string>('all');
  const { begin, end, dateFilterProps } = useDateFilter();
  const { offset, limit, setCount, paginationProps } = usePagination();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const options = useMemo(
    () => [
      'all',
      'ask',
      'bid',
      'deposit',
      'withdrawal',
      'manipulation',
      'agent-rebate',
      'parent-rebate',
      'commission',
    ],
    [],
  );

  useEffect(() => {
    if (!userId || !coin) return;
    getUserBalance(userId, coin)
      .then((res) => {
        if (res !== null) setBalance(res);
      })
      .catch((e) => {
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
        navigate(Page.Home);
      });
  }, [userId, coin]);

  useEffect(() => {
    if (!userId || !coin) return;

    getUserBalanceTransactions(userId, coin, filterType, { begin, end, offset, limit })
      .then((res) => {
        setTransactionArray(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [begin, end, offset, limit, refresh, filterType, userId, coin]);

  const onConfirmUpdateBalance = (free: string) => {
    if (!userId || !coin || free.length === 0) return;
    setIsOpen(false);
    updateBalance({ user: userId, coin, free })
      .then((res) => {
        setRefresh(!refresh);
        setBalance(res);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onUpdateBalanceConfirmAction = () => {
    setIsOpen(true);
  };

  const navigateCallback = (transaction: BalanceTransaction) => {
    switch (transaction.type) {
      case 'ask':
      case 'bid': {
        navigate(`${Page.Fill}/${transaction.referenceId}`);
        break;
      }
      case 'withdrawal':
      case 'withdrawal-fee': {
        navigate(`${Page.WithdrawalRecord}/${transaction.referenceId}`);
        break;
      }
      case 'deposit': {
        navigate(`${Page.DepositRecord}/${transaction.referenceId}`);
        break;
      }
      case 'profit': {
        navigate(`${Page.Fill}/${transaction.referenceId}`);
        break;
      }
      case 'fee-profit': {
        navigate(`${Page.WithdrawalRecord}/${transaction.referenceId}`);
        break;
      }
      case 'parent-rebate': {
        navigate(`${Page.ParentRebate}/${transaction.referenceId}`);
        break;
      }
      case 'agent-rebate': {
        navigate(`${Page.AgentRebate}/${transaction.referenceId}`);
        break;
      }
      case 'commission': {
        navigate(`${Page.Commission}/${transaction.referenceId}`);
        break;
      }
    }
  };

  const onTypeFilterChange = (value: string) => {
    paginationProps.setPage(1);
    setFilterType(value);
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] hidden text-[28px] font-bold xs:block xs:pb-[30px] sm:mt-[20px] sm:text-[32px]">
        {t('userProperty.heading', { userId, coin: coin?.toUpperCase() })}
      </div>
      <div className="relative rounded-[12px] bg-black-500 p-[25px] text-white">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-[10px] text-[20px] font-bold">
            {coin?.toUpperCase()}
          </div>
          <div className="hidden xs:flex xs:flex-col xs:items-end">
            <div className="text-[12px] text-grey-300">{t('userProperty.desc.total')}</div>
            <div className="text-[27px] font-bold">{bnFormat(balance?.total ?? null)}</div>
          </div>
        </div>
        <div className="mt-[10px] xs:hidden">
          <div className="text-[12px] text-grey-300">{t('userProperty.desc.total')}</div>
          <div className="text-[16px] font-bold">{bnFormat(balance?.total ?? null)}</div>
        </div>
        <div className={'mt-[10px]'}>
          <div className="text-[12px] text-grey-300">{t('userProperty.desc.available')}</div>
          <div className="text-[14px]">{bnFormat(balance?.free ?? null)}</div>
        </div>
        <div className={'mt-[10px]'}>
          <div className="text-[12px] text-grey-300">{t('userProperty.desc.locked')}</div>
          <div className="text-[14px]">{bnFormat(balance?.locked ?? null)}</div>
        </div>
        <div className="absolute bottom-[25px] right-[25px] flex gap-[12px]">
          <Button
            appearance="primary"
            size="small"
            onClick={() =>
              navigate(`/${location.pathname.split('/')[1]}/deposit?coinId=${coin}`, {
                state: { userId },
              })
            }
          >
            {t('userProperty.act.deposit')}
          </Button>
          {accountInfo?.id === userId && (
            <Button
              appearance="primary"
              size="small"
              onClick={() => navigate(`${Page.UserAdmin}/withdrawal?coinId=${coin}`)}
            >
              {t('userProperty.act.withdrawal')}
            </Button>
          )}
          {accountInfo?.role === 'manager' && (
            <Button appearance="primary" size="small" onClick={onUpdateBalanceConfirmAction}>
              {t('userProperty.act.correctedBalance')}
            </Button>
          )}
        </div>
      </div>
      <div className="mt-[15px] rounded-[4px] bg-white px-[15px] py-[25px] text-[14px] dark:bg-black-800 md:px-[40px]">
        <div className="flex flex-wrap gap-x-[15px]">
          <RoundDateFilter {...dateFilterProps} />
          <div className="mb-[15px]">
            <RoundSelect
              label={t('userProperty.desc.type')}
              defaultValue="all"
              onChange={onTypeFilterChange}
            >
              {options.map((v) => (
                <SelectOption key={v} value={v}>
                  {t(`userProperty.desc.typeOption.${v}`)}
                </SelectOption>
              ))}
            </RoundSelect>
          </div>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {transactionArray?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {transactionArray &&
          transactionArray?.length > 0 &&
          (isBiggerThanMd ? (
            <Table data={transactionArray} navigateCallback={navigateCallback} />
          ) : (
            <Card data={transactionArray} navigateCallback={navigateCallback} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <ConfirmUpdateBalanceModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSubmit={onConfirmUpdateBalance}
      />
    </div>
  );
};

export default UserProperty;
