import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { CannedMessage } from 'src/model/Message';

type Props = {
  list: Array<CannedMessage>;
  onEdit: (data: CannedMessage) => void;
  onDelete: (data: CannedMessage) => void;
};

const Card = ({ list, onEdit, onDelete }: Props) => {
  const { t } = useTranslation();

  const elementMapFunction = (data: CannedMessage) => (
    <div key={data.id}>
      <div className="px-0 py-[15px]">
        <div className="mt-1 flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div className="font-bold text-turquoise dark:text-aqua">{data.id}</div>
        </div>
        <div className="mt-1 flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageCannedAdmin.desc.name')}
          </div>
          <div>{data.name}</div>
        </div>
        <div className="mt-1 flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('messageCannedAdmin.desc.subject')}
          </div>
          <div>{data.subject}</div>
        </div>
        <div className="mt-1 font-bold text-grey-700 dark:text-grey-300">
          {t('messageCannedAdmin.desc.content')}
        </div>
        <div className="whitespace-pre-line">{data.content}</div>
        <div className="mt-2 flex gap-2">
          <Button size="small" appearance="secondary" onClick={() => onEdit(data)}>
            {t('messageCannedAdmin.act.edit')}
          </Button>
          <Button size="small" appearance="secondary" onClick={() => onDelete(data)}>
            {t('messageCannedAdmin.act.delete')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{list.map(elementMapFunction)}</div>;
};

export default Card;
