import cryptoEndpoint from 'src/api/cryptoEndpoint';
import depositEndpoint from 'src/api/depositEndpoint';
import withdrawalEndpoint from 'src/api/withdrawalEndpoint';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getDepositRecordList = async (
  params: {
    user?: string;
    coin?: string;
    minAmount?: string;
    maxAmount?: string;
  },
  paginationParams?: PaginationParams,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await depositEndpoint.getDeposits(
      {
        ...paginationParams,
        order: 'desc',
        user: params.user,
        coin: params.coin !== undefined ? params.coin.split('@')[0] : undefined,
        network: params.coin !== undefined ? params.coin.split('@')[1] : undefined,
        amount:
          params.minAmount || params.maxAmount
            ? [params.minAmount, params.maxAmount].join()
            : undefined,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminDeposit',
        filter: {
          id: undefined,
          coin: params.coin,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
          minAmount: params.minAmount,
          maxAmount: params.maxAmount,
        },
      }),
    );

    if (csv) toCsvFile('deposit-record.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getDepositRecordById = async (depositId: string, save?: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await depositEndpoint.getDepositsWithId(depositId);

    save && dispatch(updateFilter({ key: 'adminDeposit', filter: { id: depositId } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getWithdrawalRecordList = async (
  params: {
    user?: string;
    coin?: string;
    minAmount?: string;
    maxAmount?: string;
    status?: string;
  },
  paginationParams?: PaginationParams,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.getWithdrawals(
      {
        ...paginationParams,
        order: 'desc',
        user: params.user,
        coin: params.coin !== undefined ? params.coin.split('@')[0] : undefined,
        network: params.coin !== undefined ? params.coin.split('@')[1] : undefined,
        amount:
          params.minAmount || params.maxAmount
            ? [params.minAmount, params.maxAmount].join()
            : undefined,
        status: params.status,
        isFrozen: 'false',
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminWithdrawal',
        filter: {
          id: undefined,
          coin: params.coin,
          status: params.status,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
          minAmount: params.minAmount,
          maxAmount: params.maxAmount,
        },
      }),
    );

    if (csv) toCsvFile('withdrawal-record.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getWithdrawalRecordById = async (withdrawalId: string, save?: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.getWithdrawalsWithId(withdrawalId);

    save && dispatch(updateFilter({ key: 'adminWithdrawal', filter: { id: withdrawalId } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getCryptos = async () => {
  try {
    dispatch(startWaiting());
    const res = await cryptoEndpoint.listCryptos();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const approveWithdrawal = async (id: string) => {
  try {
    dispatch(startWaiting());
    await withdrawalEndpoint.patchWithdrawalsIdApprove(id);
  } finally {
    dispatch(finishWaiting());
  }
};

export const cancelWithdrawal = async (id: string) => {
  try {
    dispatch(startWaiting());
    await withdrawalEndpoint.deleteWithdrawalsId(id);
  } finally {
    dispatch(finishWaiting());
  }
};
