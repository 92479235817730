import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'src/component/Button';
import IcAdd from 'src/image/ic-add-s.svg';
import { RootState } from 'src/redux/store';
import { bnFormat } from 'src/util/bigNumber';
import AddRebateModal from './AddRebateModal';
import DeleteRebateModal from './DeleteRebateModal';

/**
 * 團隊返傭
 */
const AgentRebate = () => {
  const { t } = useTranslation();
  const { agentRebate } = useSelector((rootState: RootState) => rootState.setting);
  const [addRebateModalOpen, setAddRebateModalOpen] = useState<boolean>(false);
  const [targetRebateId, setTargetRebateId] = useState<string>();

  return (
    <div>
      <div className="mb-[15px] mt-[30px] flex justify-between text-[24px] font-bold">
        <div>{t('setting.desc.agentRebate')}</div>
        <div>
          <Button
            appearance="text"
            className="flex items-center gap-[4px]"
            onClick={() => setAddRebateModalOpen(true)}
          >
            <img src={IcAdd} />
            {t('setting.desc.addAgentRebate')}
          </Button>
        </div>
      </div>
      <div>
        <div className="flex">
          <div
            className={classNames(
              'box-border px-[12px] py-[16px] text-[14px]',
              'font-bold text-grey-700',
              'w-[calc(100%/12*7)]',
            )}
          >
            {t('setting.desc.rebateAmount')}
          </div>
          <div
            className={classNames(
              'box-border px-[12px] py-[16px] text-[14px]',
              'font-bold text-grey-700',
              'w-[calc(100%/3)]',
            )}
          >
            {t('setting.desc.rebatePerc')}
          </div>
          <div
            className={classNames(
              'box-border px-[12px] py-[16px] text-[14px]',
              'font-bold text-grey-700',
              'w-[calc(100%/12)]',
            )}
          />
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        <>
          {agentRebate.map((v) => (
            <div key={v.id}>
              <div className="flex">
                <div
                  className={classNames(
                    'box-border px-[12px] py-[16px] text-[14px]',
                    'w-[calc(100%/12*7)]',
                  )}
                >
                  {bnFormat(v.amount)}
                </div>
                <div
                  className={classNames(
                    'box-border px-[12px] py-[16px] text-[14px]',
                    'w-[calc(100%/3)]',
                  )}
                >
                  {v.percentage}%
                </div>
                <div
                  className={classNames(
                    'box-border px-[12px] py-[16px] text-[14px]',
                    'w-[calc(100%/12)]',
                  )}
                >
                  <div
                    className="cursor-pointer font-bold text-crimson hover:underline dark:text-red"
                    onClick={() => setTargetRebateId(v.id)}
                  >
                    {t('setting.act.delete')}
                  </div>
                </div>
              </div>
              <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
            </div>
          ))}
        </>
      </div>
      <AddRebateModal open={addRebateModalOpen} onClose={() => setAddRebateModalOpen(false)} />
      <DeleteRebateModal
        open={!!targetRebateId}
        onClose={() => setTargetRebateId(undefined)}
        id={targetRebateId ?? 'xxx'}
      />
    </div>
  );
};

export default AgentRebate;
