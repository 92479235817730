import { useEffect } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormSelect from 'src/component/FormSelect';
import Modal from 'src/component/Modal';
import SelectOption from 'src/component/SelectOption';
import { CreateUserForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createUser } from 'src/service/userService';

type Props = {
  side: 'ask' | 'bid' | 'admin';
  open: boolean;
  onClose: () => void;
  onRefreshUser: () => void;
};

const CreateUserModal = ({ side, open, onClose, onRefreshUser }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<CreateUserForm>();
  const formData = useWatch({ control: methods.control });

  useEffect(() => {
    if (side !== 'ask') methods.setValue('side', side);
  }, [side]);

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = (data: CreateUserForm) => {
    handleClose();
    createUser(data)
      .then(() => {
        onRefreshUser();
        dispatch(
          openSnackbar({ message: t('user.desc.createUserSuccessfully'), severity: 'notify' }),
        );
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={() => handleClose()}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col">
        <div className="text-[32px] font-bold text-black-900 dark:text-white">
          {t(`user.desc.addUserHeading.${side}`)}
        </div>
        <div className="mt-[25px]">
          <FormInput label="Email" name="email" type="email" required asterisked />
        </div>
        <div className="mt-[25px]">
          <FormInput
            label={t('user.desc.password')}
            name="password"
            type="password"
            required
            asterisked
          />
        </div>
        {formData.side === 'admin' && (
          <div className="mt-[25px]">
            <FormSelect
              label={t('user.desc.role')}
              name="role"
              defaultValue="manager"
              required
              asterisked
            >
              <SelectOption value="manager">{t('user.desc.roleDisplay.manager')}</SelectOption>
              <SelectOption value="assistant">{t('user.desc.roleDisplay.assistant')}</SelectOption>
              <SelectOption value="viewer">{t('user.desc.roleDisplay.viewer')}</SelectOption>
            </FormSelect>
          </div>
        )}
        <div className="mt-[25px]">
          <FormInput label={t('user.desc.mobile')} name="mobile" type="tel" />
        </div>
        <div className="mt-[25px]">
          <FormInput label={t('user.desc.slug')} name="slug" />
        </div>
        <div className="mt-[40px] text-end">
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default CreateUserModal;
