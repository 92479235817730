import assetEndpoints from 'src/api/assetEndpoints';
import assetTransactionEndpoint from 'src/api/assetTransactionEndpoint';
import balanceEndpoint from 'src/api/balanceEndpoint';
import balanceTransactionEndpoint from 'src/api/balanceTransactionEndpoint';
import exchangeRateEndpoint from 'src/api/exchangeRateEndpoint';
import fillsEndpoint from 'src/api/fillsEndpoint';
import orderEndpoint from 'src/api/orderEndpoint';
import userEndpoint from 'src/api/userEndpoint';
import withdrawalEndpoint from 'src/api/withdrawalEndpoint';
import { PostWithdrawalRequest, PostWithdrawalsPreviewRequest } from 'src/model/Api';
import { ManipulationForm } from 'src/model/Form';
import { PaginationParams } from 'src/model/Pagination';
import { dispatch } from 'src/redux/store';
import { finishWaiting, startWaiting } from 'src/redux/uiSlice';
import { toCsvFile } from 'src/util/toCsv';

export const getUnapprovedWithdrawal = async () => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.getWithdrawals({ status: 'verified' });

    return res.headers['x-pagination-count'];
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUnapprovedUser = async () => {
  try {
    dispatch(startWaiting());
    const res = await userEndpoint.getUsers({ isApproved: '0', isVerified: '1' });

    return res.headers['x-pagination-count'];
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUnmatched = async () => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.getFillsAdmin({ status: 'unfinished', timeout: '7200' });

    return res.headers['x-pagination-count'];
  } finally {
    dispatch(finishWaiting());
  }
};

export const getReferencePrice = async () => {
  try {
    dispatch(startWaiting());
    const res = await exchangeRateEndpoint.getExchangeRate('usdt', 'cny');

    return res.data.price;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getBidPrice = async () => {
  try {
    dispatch(startWaiting());
    const res = await orderEndpoint.getOrderPrice('usdt', 'cny');

    return res.data.price;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getUserBalance = async () => {
  try {
    dispatch(startWaiting());
    const res = await balanceEndpoint.getSummedBalance({ without: 'system' });

    return res.data.find((v) => v.coin === 'usdt');
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAssetList = async () => {
  try {
    dispatch(startWaiting());
    const res = await assetEndpoints.getAssetList();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAssetId = async (coinId: string) => {
  try {
    dispatch(startWaiting());
    const res = await assetEndpoints.getAssetId(coinId);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getAssetTransaction = async (
  coinId: string,
  type?: string,
  paginationParams?: PaginationParams,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await assetTransactionEndpoint.getAssetTransaction(
      {
        coin: coinId.split('@')[0],
        network: coinId.split('@')[1],
        type,
        ...paginationParams,
        order: 'desc',
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    if (csv) toCsvFile('asset-transaction.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getProfit = async () => {
  try {
    dispatch(startWaiting());
    const res = await balanceEndpoint.getSystemBalance();

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getProfitTransaction = async (
  coinId: string,
  paginationParams?: PaginationParams,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await balanceTransactionEndpoint.getBalanceTransaction(
      {
        coin: coinId,
        user: ',',
        order: 'desc',
        ...paginationParams,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    if (csv) toCsvFile('profit-transaction.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const createWithdrawal = async (
  data: Omit<PostWithdrawalRequest, 'clientId'>,
  code: string,
) => {
  try {
    dispatch(startWaiting());
    const res = await withdrawalEndpoint.postWithdrawal(
      {
        ...data,
        userId: null,
        clientId: `${Date.now()}`,
      },
      code,
    );

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const previewWithdrawal = async (data: PostWithdrawalsPreviewRequest) => {
  const res = await withdrawalEndpoint.postWithdrawalPreview(data);

  return res.data;
};

export const manipulation = async (formData: ManipulationForm, coin: string) => {
  try {
    dispatch(startWaiting());
    await assetEndpoints.patchAsset({
      coldWallet: Number(formData.amount),
      note: formData.note.length === 0 ? undefined : formData.note,
      coin: coin.split('@')[0],
      network: coin.split('@')[1],
    });
  } finally {
    dispatch(finishWaiting());
  }
};
