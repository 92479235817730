import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import BackButton from 'src/component/BackButton';
import { openSnackbar } from 'src/redux/uiSlice';
import { init } from 'src/service/settingService';
import AdLimit from './component/AdLimit';
import AgentRebate from './component/AgentRebate';
import AmountLimit from './component/AmountLimit';
import AskerLimit from './component/AskerLimit';
import BidPrice from './component/BidPrice';
import ExchangeRate from './component/ExchangeRate';
import Fee from './component/Fee';
import MinProfit from './component/MinProfit';
import OrderSetting from './component/OrderSetting';
import ParentRebate from './component/ParentRebate';
import Vendor from './component/Vendor';
import WithdrawalAutoApprovedSetting from './component/WithdrawalAutoApprovedSetting';

const Setting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    init().catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, []);

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('setting.heading')}
      </div>
      <Vendor />
      <ExchangeRate />
      <BidPrice />
      <MinProfit />
      <AmountLimit />
      <AdLimit />
      <OrderSetting />
      <Fee side="ask" />
      <Fee side="bid" />
      <WithdrawalAutoApprovedSetting />
      <AskerLimit />
      <ParentRebate />
      <AgentRebate />
    </div>
  );
};

export default Setting;
