import { useTranslation } from 'react-i18next';
import Alert from 'src/component/Alert';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';
import { Severity } from 'src/constant/Notification';
import { AccountUser } from 'src/model/AccountUser';

type Props = {
  open: boolean;
  user?: AccountUser;
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
};

const EditUserModal = ({ open, user, onClose, onApprove, onReject }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={() => onClose()}>
      <div className="flex flex-col">
        <div className="text-[16px]">
          {t('user.desc.confirmToPassUser', { user: user?.slug ?? user?.id })}
        </div>
        <div className="mt-[20px]">
          <Alert severity={Severity.Warning}>{t('user.desc.editUserNotice')}</Alert>
        </div>
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button appearance="outlined" className="font-bold" onClick={() => onClose()}>
            {t('act.cancel')}
          </Button>
          <Button appearance="secondary" className="font-bold text-red" onClick={onReject}>
            {t('user.act.reject')}
          </Button>
          <Button appearance="primary" className="font-bold" onClick={onApprove}>
            {t('user.act.approve')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserModal;
