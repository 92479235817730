import {
  GetCannedMessageListParams,
  GetCannedMessageListResponse,
  GetMessageIdResponse,
  GetMessageListParams,
  GetMessageListResponse,
  PostCannedMessageRequest,
  PostCannedMessageResponse,
  PutCannedMessageIdRequest,
  PutCannedMessageIdResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getMessageList = async (params: GetMessageListParams) => {
  try {
    return await http.authGet<GetMessageListResponse>('messages', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getMessageById = async (id: string) => {
  try {
    return await http.authGet<GetMessageIdResponse>(`messages/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getCannedMessageList = async (params: GetCannedMessageListParams) => {
  try {
    return await http.authGet<GetCannedMessageListResponse>('canned-messages', { params });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const postCannedMessage = async (data: PostCannedMessageRequest) => {
  try {
    return await http.authPost<PostCannedMessageResponse>('canned-messages', { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const putCannedMessageId = async (id: string, data: PutCannedMessageIdRequest) => {
  try {
    return await http.authPut<PutCannedMessageIdResponse>(`canned-messages/${id}`, { data });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteCannedMessageId = async (id: string) => {
  try {
    return await http.authDelete(`canned-messages/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getMessageList,
  getMessageById,
  getCannedMessageList,
  postCannedMessage,
  putCannedMessageId,
  deleteCannedMessageId,
};
