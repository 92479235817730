import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import IcClose from 'src/image/ic-close-s.svg';
import IcSearch from 'src/image/ic-search.svg';
import FormInput from './FormInput';

/**
 * usage note:
 * This component should be put inside <Form>
 */

type Props = {
  name: string;
  // eslint-disable-next-line
  onQuery: (data: any) => void;
  placeholder?: string;
  className?: string;
};

const FormSearchInput = ({ name, placeholder, className, onQuery }: Props) => {
  const { setValue, handleSubmit, getValues } = useFormContext();

  const onClick = async () => {
    setValue(name, '');
    handleSubmit(onQuery)();
  };

  return (
    <div className={twMerge('relative w-[300px]', className)}>
      <div className="absolute top-[7px] box-border">
        <img src={IcSearch} />
      </div>
      <FormInput name={name} type="text" placeholder={placeholder} className="flex-1 pl-[26px]" />
      {getValues(name) && (
        <div
          className="absolute right-0 top-[12px] flex cursor-pointer items-center justify-center rounded-[50%] bg-dark-500 p-[3px] dark:bg-grey-300"
          onClick={onClick}
        >
          <img src={IcClose} className="h-[12px] w-[12px]" />
        </div>
      )}
    </div>
  );
};

export default FormSearchInput;
