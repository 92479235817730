import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { CommissionAdmin } from 'src/model/Commission';

type Props = {
  data: CommissionAdmin[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (commission: CommissionAdmin) => (
    <div key={commission.id}>
      <div className="flex flex-row">
        <div className="box-border flex w-[calc(100%/13*2)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Commission}/${commission.id}`)}
          >
            {commission.id}
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/13*2)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.UserAsk}/${commission.userId}`)}
          >
            {commission.user.email}
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/13)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {commission.coin.toUpperCase()}
        </div>
        <div className="box-border flex w-[calc(100%/13*2)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.UserAsk}/${commission.kid.id}`)}
          >
            {commission.kid.email}
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/13*2)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {commission.askAmount}
        </div>
        <div className="box-border flex w-[calc(100%/13*2)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {commission.amount}
        </div>
        <div className="box-border flex w-[calc(100%/13*2)] flex-col gap-[4px] break-words px-[12px] py-[16px] text-[14px]">
          {format(new Date(commission.createdAt), 'yyyy-MM-dd HH:mm:ss')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/13*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          ID
        </div>
        <div className="box-border w-[calc(100%/13*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('commission.desc.user')}
        </div>
        <div className="box-border w-[calc(100%/13)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('commission.desc.coin')}
        </div>
        <div className="box-border w-[calc(100%/13*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('commission.desc.kid')}
        </div>
        <div className="box-border w-[calc(100%/13*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('commission.desc.kidAmount')}
        </div>
        <div className="box-border w-[calc(100%/13*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('commission.desc.amount')}
        </div>
        <div className="box-border w-[calc(100%/13*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('commission.desc.createdAt')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
