import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Badge from 'src/component/Badge';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { QueryForm } from 'src/model/Form';
import { RebateDetail } from 'src/model/Rebate';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getRebateById, getRebateList } from 'src/service/rebateService';
import Card from './component/Card';
import Table from './component/Table';

const AgentRebateAdmin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminAgentRebate: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const location = useLocation();
  const state = location.state as { userId: string } | null;
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const [rebateId, setRebateId] = useState<string>(filter.id ?? '');
  const [list, setList] = useState<RebateDetail[]>();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<QueryForm>({
    defaultValues: {
      id: filter.id,
    },
  });

  useEffect(() => {
    if (rebateId) {
      getRebateById(rebateId, true)
        .then((res) => {
          setList([res]);
          setCount(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getRebateList('agent', { user: state?.userId }, { begin, end, offset, limit })
      .then((res) => {
        setList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [rebateId, begin, end, offset, limit, state]);

  const onQuery = (data: QueryForm) => {
    setRebateId(data.id);
  };

  const onDownload = () => {
    getRebateList('agent', { user: state?.userId }, { begin, end, offset, limit }, true).catch(
      (e) => dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('rebate.agentHeading')}
      </div>
      {state && (
        <Badge
          head={t('desc.userId')}
          value={state.userId}
          onClose={() => navigate('.', { replace: true })}
        />
      )}
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-[0px] md:mt-[30px] md:gap-[60px] md:px-[30px] md:py-[0px]">
        <DateFilter
          label={t('rebate.desc.date')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px] dark:bg-white">
        <div className="relative flex-wrap xs:flex xs:justify-between xs:gap-x-[25px]">
          <Form
            methods={methods}
            onSubmit={onQuery}
            className="flex flex-col flex-wrap items-center pb-[15px] xs:flex xs:flex-row xs:gap-[30px]"
          >
            <FormSearchInput
              name="id"
              placeholder={t('rebate.desc.searchInputHelper')}
              onQuery={onQuery}
            />
            <Button appearance="secondary" size="small" type="submit">
              {t('rebate.desc.search')}
            </Button>
            <div className="flex w-[120px] items-center gap-[8px]">
              <img className="h-[24px] w-[24px]" src={IcLimit} />
              <div className="grow">
                <Select
                  value={limit.toString()}
                  onChange={(v) => {
                    setLimit(Number(v));
                    paginationProps.setPage(1);
                  }}
                >
                  <SelectOption value="5">{'5'}</SelectOption>
                  <SelectOption value="10">{'10'}</SelectOption>
                  <SelectOption value="50">{'50'}</SelectOption>
                  <SelectOption value="100">{'100'}</SelectOption>
                </Select>
              </div>
            </div>
            <Button
              className="font-bold"
              size="small"
              appearance="secondary"
              type="button"
              onClick={onDownload}
            >
              {t('rebate.desc.download')}
            </Button>
          </Form>
        </div>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {list?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {list && list.length > 0 && (isBiggerThanMd ? <Table data={list} /> : <Card data={list} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default AgentRebateAdmin;
