import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { AccountUser } from 'src/model/AccountUser';
import { QueryForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getUserAskerLimitList, updateUserAskerLimit } from 'src/service/userService';
import AddModal from './component/AddModal';
import Card from './component/Card';
import EditModal from './component/EditModal';
import Table from './component/Table';

const AskerLimit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { adminAskerLimit: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { offset, limit, setLimit, setCount, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const methods = useForm<QueryForm>({
    defaultValues: {
      id: filter.query,
    },
  });
  const [userArray, setUserArray] = useState<AccountUser[]>();
  const [userQuery, setUserQuery] = useState<string>(filter.query ?? '');
  const [refresh, setRefresh] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [target, setTarget] = useState<AccountUser>();

  useEffect(() => {
    getUserAskerLimitList({ query: userQuery, offset, limit })
      .then((res) => {
        setUserArray(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh, offset, limit, userQuery]);

  const onQuery = (data: QueryForm) => {
    setUserQuery(data.id);
  };

  const onEdit = (user: AccountUser) => () => {
    setTarget(user);
    setOpenEdit(true);
  };

  const onDelete = (user: AccountUser) => () => {
    updateUserAskerLimit(user.id, null)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        <div>{t('askerLimit.heading')}</div>
        <div>
          <Button size="small" onClick={() => setOpen(true)}>
            {t('askerLimit.act.add')}
          </Button>
        </div>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px] md:px-[40px] md:py-[25px]">
        <Form
          methods={methods}
          onSubmit={onQuery}
          className="flex flex-row items-center gap-[30px] pb-[25px]"
        >
          <FormSearchInput
            name="id"
            placeholder={t('user.desc.searchInputHelper')}
            onQuery={onQuery}
          />
          <Button appearance="secondary" size="small" type="submit">
            {t('user.act.search')}
          </Button>
          <div className="flex w-[120px] gap-[8px]">
            <img src={IcLimit} />
            <div className="grow">
              <Select
                value={limit.toString()}
                onChange={(v) => {
                  setLimit(Number(v));
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="5">{'5'}</SelectOption>
                <SelectOption value="10">{'10'}</SelectOption>
                <SelectOption value="50">{'50'}</SelectOption>
                <SelectOption value="100">{'100'}</SelectOption>
              </Select>
            </div>
          </div>
        </Form>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {userArray?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {userArray &&
          userArray.length > 0 &&
          (isBiggerThanMd ? (
            <Table data={userArray} onEdit={onEdit} onDelete={onDelete} />
          ) : (
            <Card data={userArray} onEdit={onEdit} onDelete={onDelete} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <AddModal
        open={open}
        onClose={() => setOpen(false)}
        doRefresh={() => setRefresh(!refresh)}
        target={target}
      />
      {target && (
        <EditModal
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
            setTarget(undefined);
          }}
          doRefresh={() => setRefresh(!refresh)}
          target={target}
        />
      )}
    </div>
  );
};

export default AskerLimit;
