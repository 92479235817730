import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import Modal from 'src/component/Modal';

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const BlockUserModal = ({ open, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} handleClose={() => onClose()}>
      <div className="flex flex-col">
        <div className="text-[32px] font-bold">{t('userDetail.blockModal.head')}</div>
        <div className="mt-[20px]">{t('userDetail.blockModal.content')}</div>
        <div className="mt-[30px] flex flex-row justify-end gap-[20px]">
          <Button className="font-bold" appearance="outlined" onClick={() => onClose()}>
            {t('act.cancel')}
          </Button>
          <Button className="font-bold" appearance="primary" onClick={() => onConfirm()}>
            {t('userDetail.blockModal.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BlockUserModal;
