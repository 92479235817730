import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import EditUserModal from 'src/component/EditUserModal';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { usePagination } from 'src/hook/usePagination';
import useQuery from 'src/hook/useQuery';
import IcAdd from 'src/image/ic-add-s.svg';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { AccountUser } from 'src/model/AccountUser';
import { QueryForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { approveUser, getUserList, rejectUser } from 'src/service/userService';
import CreateUserModal from './component/CreateUserModal';
import UserListCard from './component/UserListCard';
import UserListTable from './component/UserListTable';

type QueryParams = { approved: string };

const User = () => {
  const { t, ready } = useTranslation();
  const location = useLocation();
  const side = useMemo<'ask' | 'bid' | 'admin'>(() => {
    if (location.pathname === '/user-ask') return 'ask';
    if (location.pathname === '/user-bid') return 'bid';

    return 'admin';
  }, [location.pathname]);
  const { approved } = useQuery<QueryParams>();
  const dispatch = useDispatch();
  const {
    adminUserAsk: filterAsk,
    adminUserBid: filterBid,
    adminUserAdmin: filterAdmin,
  } = useSelector((rootState: RootState) => rootState.ui.filter);
  const filter = useMemo(
    () => (side === 'ask' ? filterAsk : side === 'bid' ? filterBid : filterAdmin),
    [side],
  );
  const { accountInfo } = useSelector((rootState: RootState) => rootState.auth);
  const [createUserModalOpen, setCreateUserModalOpen] = useState<boolean>(false);
  const [editUserModalOpen, setEditUserModalOpen] = useState<boolean>(false);

  const { defaultLimit, offset, limit, setLimit, setCount, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const [filterStatus, setFilterStatus] = useState<string>(
    filter.status ?? approved === '0' ? 'waiting' : 'all',
  );
  const [userQuery, setUserQuery] = useState<string>();
  const methods = useForm<QueryForm>({
    defaultValues: {
      id: filter.query,
    },
  });
  const [userArray, setUserArray] = useState<Array<AccountUser>>();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<AccountUser>();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    setRefresh(!refresh);
    methods.setValue('id', filter.query ?? '');
    setFilterStatus(filter.status ?? approved === '0' ? 'waiting' : 'all');
    setUserQuery(filter.query ?? '');
    setLimit(filter.limit ?? defaultLimit);
    paginationProps.setPage(filter.page ?? 1);
  }, [filter]);

  useEffect(() => {
    getUserList({ side, query: userQuery, status: filterStatus }, { offset, limit })
      .then((res) => {
        setUserArray(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh, offset, limit, filterStatus, userQuery]);

  const onStatusSelectChange = (v: string) => {
    setFilterStatus(v);
    paginationProps.setPage(1);
  };

  const onEditUser = (user: AccountUser) => {
    setEditUser(user);
    setEditUserModalOpen(true);
  };

  const onApprove = () => {
    if (editUser === undefined) return;
    setEditUserModalOpen(false);
    approveUser(editUser.id)
      .then(() => {
        setRefresh(!refresh);
        dispatch(
          openSnackbar({ message: t('user.desc.editUserSuccessfully'), severity: 'notify' }),
        );
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onReject = () => {
    if (editUser === undefined) return;
    setEditUserModalOpen(false);
    rejectUser(editUser.id)
      .then(() => {
        setRefresh(!refresh);
        dispatch(
          openSnackbar({ message: t('user.desc.editUserSuccessfully'), severity: 'notify' }),
        );
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  const onQuery = (data: QueryForm) => {
    setUserQuery(data.id);
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        <div>{t(`user.heading.${side}`)}</div>
        {side !== 'ask' && accountInfo?.role === 'manager' && (
          <div>
            <Button
              appearance="text"
              className="flex flex-row items-center gap-[4px]"
              onClick={() => setCreateUserModalOpen(true)}
            >
              <img src={IcAdd} />
              {t('user.act.addUser')}
            </Button>
          </div>
        )}
      </div>
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-0 md:gap-[60px] md:px-[30px]">
        <Select
          label={t('user.desc.status')}
          value={filterStatus}
          onChange={onStatusSelectChange}
          className="w-[119px] md:w-[140px] lg:w-[240px]"
        >
          <SelectOption value={'all'}>{t('user.desc.all')}</SelectOption>
          <SelectOption value={'waiting'}>{t('user.desc.statusDisplay.waiting')}</SelectOption>
        </Select>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px] md:px-[40px] md:py-[25px]">
        <Form
          methods={methods}
          onSubmit={onQuery}
          className="flex flex-row items-center gap-[30px] pb-[25px]"
        >
          <FormSearchInput
            name="id"
            placeholder={t('user.desc.searchInputHelper')}
            onQuery={onQuery}
          />
          <Button appearance="secondary" size="small" type="submit">
            {t('user.act.search')}
          </Button>
          <div className="flex w-[120px] gap-[8px]">
            <img src={IcLimit} />
            <div className="grow">
              <Select
                value={limit.toString()}
                onChange={(v) => {
                  setLimit(Number(v));
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="5">{'5'}</SelectOption>
                <SelectOption value="10">{'10'}</SelectOption>
                <SelectOption value="50">{'50'}</SelectOption>
                <SelectOption value="100">{'100'}</SelectOption>
              </Select>
            </div>
          </div>
        </Form>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {userArray?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {userArray &&
          userArray.length > 0 &&
          (isBiggerThanMd ? (
            <UserListTable data={userArray} onEditUser={onEditUser} />
          ) : (
            <UserListCard data={userArray} onEditUser={onEditUser} />
          ))}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
      <EditUserModal
        open={editUserModalOpen}
        user={editUser}
        onClose={() => setEditUserModalOpen(false)}
        onApprove={onApprove}
        onReject={onReject}
      />
      <CreateUserModal
        side={side}
        open={createUserModalOpen}
        onClose={() => setCreateUserModalOpen(false)}
        onRefreshUser={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default User;
