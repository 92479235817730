import classNames from 'classnames';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from 'src/component/Button';
import { Page } from 'src/constant/Page';
import { Announcement } from 'src/model/Announcement';

type Props = {
  data: Announcement[];
  onDelete: (id: string) => void;
};

const Card = ({ data, onDelete }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (announcement: Announcement) => (
    <div key={announcement.id}>
      <div className="px-[0px] py-[15px]">
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className={classNames(
              'font-bold text-turquoise dark:text-aqua',
              'cursor-pointer hover:underline',
            )}
            onClick={() => navigate(`${Page.Announcement}/${announcement.id}`)}
          >
            {announcement.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('announcement.desc.side')}
          </div>
          <div>
            {announcement.side === 'ask'
              ? t('announcement.desc.sideAsk')
              : t('announcement.desc.sideBid')}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('announcement.desc.subject')}
          </div>
          <div>{announcement.subject}</div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('announcement.desc.published')}
          </div>
          <div>{format(Date.parse(announcement.publishedAt), 'yyyy-MM-dd HH:mm')}</div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('announcement.desc.unpinned')}
          </div>
          <div>
            {announcement.unpinnedAt
              ? format(Date.parse(announcement.unpinnedAt), 'yyyy-MM-dd HH:mm')
              : '-'}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('announcement.desc.unpublished')}
          </div>
          <div>
            {announcement.unpublishedAt
              ? format(Date.parse(announcement.unpublishedAt), 'yyyy-MM-dd HH:mm')
              : '-'}
          </div>
        </div>
        <div className="mt-[5px] flex gap-[10px]">
          <Button
            className="font-bold"
            appearance="secondary"
            size="small"
            onClick={() => {
              navigate(`${Page.Announcement}/edit`, { state: announcement });
            }}
          >
            {t('announcement.act.update')}
          </Button>
          <Button
            className="font-bold"
            appearance="secondary"
            size="small"
            onClick={() => onDelete(announcement.id)}
          >
            {t('announcement.act.delete')}
          </Button>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
