import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import IcGo from 'src/image/ic-go.svg';
import { getBidPrice, getReferencePrice } from 'src/service/homeService';

const Price = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [referencePrice, setReferencePrice] = useState<string>();
  const [bidPrice, setBidPrice] = useState<string>();

  useEffect(() => {
    getReferencePrice().then((res) => setReferencePrice(res));
    getBidPrice().then((res) => setBidPrice(res));
  }, []);

  return (
    <div
      className="relative box-border flex h-[100%] cursor-pointer flex-col gap-[12px] bg-black-500 px-[16px] py-[30px] text-white md:p-[16px]"
      onClick={() => navigate(`${Page.Setting}`)}
    >
      <div>
        <div className="leading-[1.5]">{t('home.referencePrice')}</div>
        <div className="mt-[1px] text-[20px] font-bold leading-[1.4]">
          {referencePrice ? `USDT: ${referencePrice} CNY` : '-'}
        </div>
      </div>
      <div>
        <div className="leading-[1.5]">{t('home.bidPrice')}</div>
        <div className="mt-[1px] text-[20px] font-bold leading-[1.4]">
          {bidPrice ? `USDT: ${bidPrice} CNY` : '-'}
        </div>
      </div>
      <img className="absolute bottom-[16px] right-[16px]" src={IcGo} />
    </div>
  );
};

export default Price;
