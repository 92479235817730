import IcClose from 'src/image/ic-close-s.svg';

type Props = {
  head: string;
  value: string;
  onClose?: () => void;
};

const Badge = ({ head, value, onClose }: Props) => (
  <div className="mx-0 mb-[20px] mt-[15px] flex w-fit gap-[8px] bg-dark-500 p-[6px] text-[13px] font-bold leading-[1.38] text-white">
    <div className="text-white/70">{head}</div>
    <div>{value}</div>
    <img className="cursor-pointer" src={IcClose} onClick={onClose} />
  </div>
);

export default Badge;
