import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormNumberInput from 'src/component/FormNumberInput';
import Modal from 'src/component/Modal';
import { AddAgentRebateForm } from 'src/model/Form';
import { openSnackbar } from 'src/redux/uiSlice';
import { createAgentRebate } from 'src/service/settingService';

type Props = {
  open: boolean;
  onClose: () => void;
};

const AddRebateModal = ({ open, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const methods = useForm<AddAgentRebateForm>();

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  const onSubmit = (data: AddAgentRebateForm) => {
    handleClose();
    createAgentRebate(data).catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={() => handleClose()}>
      <Form methods={methods} onSubmit={onSubmit} className="flex flex-col">
        <div className="text-[32px] font-bold text-black-900 dark:text-white">
          {t('setting.desc.addAgentRebate')}
        </div>
        <div className="mt-[25px]">
          <FormInput
            label={t('setting.desc.rebateAmount')}
            name="amount"
            type="number"
            required
            asterisked
          />
        </div>
        <div className="mt-[25px]">
          <FormNumberInput
            label={t('setting.desc.rebatePerc')}
            name="percentage"
            decimal={3}
            required
            asterisked
            hint={t('setting.desc.rebateHint')}
          />
        </div>
        <div className="mt-[40px] text-end">
          <Button type="submit">{t('act.submit')}</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddRebateModal;
