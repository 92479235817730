import { format } from 'date-fns';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Checkbox from 'src/component/Checkbox';
import { Page } from 'src/constant/Page';
import { UserOrder } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<UserOrder>;
  onCheck: (id: string) => (e: ChangeEvent<HTMLInputElement>) => void;
};

const Table = ({ data, onCheck }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: UserOrder) => (
    <div key={order.id}>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/11*2)] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${Page.Trade}/${order.id}`)}
          >
            {order.id}
          </div>
        </div>
        <div className="box-border w-[calc(100%/11)] break-all px-[12px] py-[16px] text-[14px]">
          {t(`trade.desc.statusDisplay.${order.status}`)}
        </div>
        <div className="box-border w-[calc(100%/11*2)] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => {
              if (order.user.side === 'ask') navigate(`${Page.UserAsk}/${order.userId}`);
              else if (order.user.side === 'bid') navigate(`${Page.UserBid}/${order.userId}`);
              else navigate(`${Page.UserAdmin}/${order.userId}`);
            }}
          >
            {t(`trade.desc.side.${order.user.side}`)}-{order.user.email}
          </div>
        </div>
        <div className="box-border w-[calc(100%/11*2)] break-all px-[12px] py-[16px] text-[14px]">
          <div>{`${bnFormat(order.openAmount)} ${order.base.toUpperCase()}`}</div>
          <div>{`${bnFormat(order.minTotal)} - ${bnFormat(
            order.maxTotal,
          )} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="box-border w-[calc(100%/11)] break-all px-[12px] py-[16px] text-[14px]">
          <span>{`${bnFormat(order.price)} `}</span>
          <span className="break-keep">{order.quote.toUpperCase()}</span>
        </div>
        <div className="box-border w-[calc(100%/11*2)] break-all px-[12px] py-[16px] text-[14px]">
          <span>{`${format(Date.parse(order.updatedAt), 'yyyy/MM/dd')} `}</span>
          <span className="break-keep">{`${format(Date.parse(order.updatedAt), 'HH:mm:ss')}`}</span>
        </div>
        <div className="box-border w-[calc(100%/11)] break-all px-[12px] py-[16px] text-[14px]">
          <Checkbox checked={order.priority === 1} onChange={onCheck(order.id)} />
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          ID
        </div>
        <div className="box-border w-[calc(100%/11)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('trade.desc.status')}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('trade.desc.seller')}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">{`${t(
          'trade.desc.amount',
        )}/${t('trade.desc.limit')}`}</div>
        <div className="box-border w-[calc(100%/11)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('trade.desc.price')}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('trade.desc.updateTime')}
        </div>
        <div className="box-border w-[calc(100%/11)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('trade.desc.priority')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
