import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import FormTextArea from 'src/component/FormTextArea';
import Modal from 'src/component/Modal';
import { CannedMessageForm } from 'src/model/Form';
import { CannedMessage } from 'src/model/Message';
import { openSnackbar } from 'src/redux/uiSlice';
import { addCannedMessage, editCannedMessage } from 'src/service/messageService';

type Props = {
  open: boolean;
  onClose: () => void;
  message?: CannedMessage;
  doRefresh: () => void;
};

const MessageModal = ({ open, onClose, message, doRefresh }: Props) => {
  const { t } = useTranslation();
  const methods = useForm<CannedMessageForm>();
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
    methods.reset();
  };

  const onSubmit = (data: CannedMessageForm) => {
    handleClose();
    if (message === undefined)
      addCannedMessage(data)
        .then(doRefresh)
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
    else
      editCannedMessage(message.id, data)
        .then(doRefresh)
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <Modal open={open} handleClose={handleClose}>
      <Form methods={methods} onSubmit={onSubmit}>
        <div className="mb-[30px] text-[32px] font-bold">
          {message
            ? t('messageCannedAdmin.desc.modalHeadEdit')
            : t('messageCannedAdmin.desc.modalHeadAdd')}
        </div>
        <FormInput
          name="name"
          defaultValue={message?.name}
          label={t('messageCannedAdmin.desc.name')}
        />
        <div className="mx-[0] my-[20px]">
          <FormInput
            name="subject"
            defaultValue={message?.subject}
            label={t('messageCannedAdmin.desc.subject')}
          />
        </div>
        <FormTextArea
          name="content"
          defaultValue={message?.content}
          className="h-[200px]"
          label={t('messageCannedAdmin.desc.content')}
        />
        <div className="mt-[30px] flex justify-end">
          <Button appearance="primary" type="submit">
            {t('act.submit')}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default MessageModal;
