import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SummedBalance } from 'src/model/Balance';
import { getUserBalance } from 'src/service/homeService';
import { bnFormat } from 'src/util/bigNumber';

const UserBalance = () => {
  const { t } = useTranslation();
  const [userBalance, setUserBalance] = useState<SummedBalance>();

  useEffect(() => {
    getUserBalance().then((res) => setUserBalance(res));
  }, []);

  return (
    <div className="bg-white p-[30px] text-black-900">
      <div className="mb-[20px] flex justify-between">
        <div className="text-[24px] font-bold">{t('home.userBalance')}</div>
        <div className="text-[24px] font-bold text-dark-500">USDT</div>
      </div>
      <div className="flex flex-col gap-[4px]">
        <div className="text-[16px]">{t('home.totalAmount')}</div>
        <div className="text-[20px] font-bold">
          {userBalance?.total ? bnFormat(userBalance.total) : '-'}
        </div>
      </div>
      <div className={classNames('flex flex-col gap-[4px]', 'mt-[23px]')}>
        <div className="text-[16px]">{t('home.freeAmount')}</div>
        <div className="text-[20px] font-bold">
          {userBalance?.free ? bnFormat(userBalance.free) : '-'}
        </div>
      </div>
    </div>
  );
};

export default UserBalance;
