import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<BidOrder>;
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="px-0 py-[15px]">
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Order}/${order.id}`)}
          >
            {order.id}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('order.desc.status')}</div>
          <div>{t(`order.desc.statusDisplay.${order.status}`)}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('order.desc.buyer')}</div>
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => {
              if (order.user.side === 'ask') navigate(`${Page.UserAsk}/${order.userId}`);
              else if (order.user.side === 'bid') navigate(`${Page.UserBid}/${order.userId}`);
              else navigate(`${Page.UserAdmin}/${order.userId}`);
            }}
          >
            {t(`order.desc.side.${order.user.side}`)}-{order.user.email}
          </div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.orderAmount')}
          </div>
          <div>{`${bnFormat(order.amount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledAmount')}
          </div>
          <div>{`${bnFormat(order.filledAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.sentAmount')}
          </div>
          <div>{`${bnFormat(order.sentAmount)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('order.desc.price')}</div>
          <div>{`${bnFormat(order.price)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledTotal')}
          </div>
          <div>{`${bnFormat(order.filledTotal)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.paidTotal')}
          </div>
          <div>{`${bnFormat(order.sentTotal)} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledSentTotal')}
          </div>
          <div>{`${bn(order.filledTotal)
            .minus(order.sentTotal)
            .toFormat()} ${order.quote.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">{t('order.desc.profit')}</div>
          <div>{`${bnFormat(order.profit)} ${order.base.toUpperCase()}`}</div>
        </div>
        <div className="mt-[4px] flex flex-row items-center gap-[15px] text-[14px]">
          <div className="font-bold text-grey-700 dark:text-grey-300">
            {t('trade.desc.createTime')}
          </div>
          <div>{format(Date.parse(order.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
