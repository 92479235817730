import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { BidOrder } from 'src/model/Order';
import { bn, bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<BidOrder>;
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (order: BidOrder) => (
    <div key={order.id}>
      <div className="flex flex-row">
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => navigate(`${Page.Order}/${order.id}`)}
          >
            {order.id}
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/15)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          {t(`order.desc.statusDisplay.${order.status}`)}
        </div>
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => {
              if (order.user.side === 'ask') navigate(`${Page.UserAsk}/${order.userId}`);
              else if (order.user.side === 'bid') navigate(`${Page.UserBid}/${order.userId}`);
              else navigate(`${Page.UserAdmin}/${order.userId}`);
            }}
          >
            {t(`order.desc.side.${order.user.side}`)}-{order.user.email}
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div className="break-keep text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.orderAmount')}
          </div>
          <div>
            <span>{`${bnFormat(order.amount)} `}</span>
            <span className="break-keep">{order.base.toUpperCase()}</span>
          </div>
          <div className="break-keep text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledAmount')}
          </div>
          <div>
            <span>{`${bnFormat(order.filledAmount)} `}</span>
            <span className="break-keep">{order.base.toUpperCase()}</span>
          </div>
          <div className="break-keep text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.sentAmount')}
          </div>
          <div>
            <span>{`${bnFormat(order.sentAmount)} `}</span>
            <span className="break-keep">{order.base.toUpperCase()}</span>
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div>
            <span>{`${bnFormat(order.price)} `}</span>
            <span className="break-keep">{order.quote.toUpperCase()}</span>
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div className="break-keep text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledTotal')}
          </div>
          <div>
            <span>{`${bnFormat(order.filledTotal)} `}</span>
            <span className="break-keep">{order.quote.toUpperCase()}</span>
          </div>
          <div className="break-keep text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.paidTotal')}
          </div>
          <div>
            <span>{`${bnFormat(order.sentTotal)} `}</span>
            <span className="break-keep">{order.quote.toUpperCase()}</span>
          </div>
          <div className="break-keep text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('order.desc.filledSentTotal')}
          </div>
          <div>
            <span>{`${bn(order.filledTotal).minus(order.sentTotal).toFormat()} `}</span>
            <span className="break-keep">{order.quote.toUpperCase()}</span>
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div>
            <span>{`${bnFormat(order.profit)} `}</span>
            <span className="break-keep">{order.quote.toUpperCase()}</span>
          </div>
        </div>
        <div className="box-border flex w-[calc(100%/15*2)] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <span>{`${format(Date.parse(order.createdAt), 'yyyy/MM/dd')} `}</span>
          <span className="break-keep">{`${format(Date.parse(order.createdAt), 'HH:mm:ss')}`}</span>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          ID
        </div>
        <div className="box-border w-[calc(100%/15)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('order.desc.status')}
        </div>
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('order.desc.buyer')}
        </div>
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">{`${t(
          'order.desc.amount',
        )}`}</div>
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">{`${t(
          'order.desc.price',
        )}`}</div>
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">{`${t(
          'order.desc.total',
        )}`}</div>
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">{`${t(
          'order.desc.profit',
        )}`}</div>
        <div className="box-border w-[calc(100%/15*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('order.desc.createTime')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
