import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { Balance } from 'src/model/Balance';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Balance[];
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (balance: Balance) => (
    <div key={balance.id}>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/11*2)] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => {
              if (balance.user.side === 'ask')
                navigate(`${Page.UserAsk}/${balance.userId}/property/usdt`);
              else if (balance.user.side === 'bid')
                navigate(`${Page.UserBid}/${balance.userId}/property/usdt`);
              else navigate(`${Page.UserAdmin}/${balance.userId}/property/usdt`);
            }}
          >
            {balance.id}
          </div>
        </div>
        <div className="box-border w-[calc(100%/11*2)] break-all px-[12px] py-[16px] text-[14px] font-bold text-turquoise dark:text-aqua">
          <div
            className="cursor-pointer hover:underline"
            onClick={() => {
              if (balance.user.side === 'ask') navigate(`${Page.UserAsk}/${balance.userId}`);
              else if (balance.user.side === 'bid') navigate(`${Page.UserBid}/${balance.userId}`);
              else navigate(`${Page.UserAdmin}/${balance.userId}`);
            }}
          >
            {t(`order.desc.side.${balance.user.side}`)}-{balance.user.email}
          </div>
        </div>
        <div className="box-border w-[calc(100%/11)] px-[12px] py-[16px] text-[14px]">
          {balance.coin.toUpperCase()}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px]">
          {bnFormat(balance.total)}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px]">
          {bnFormat(balance.free)}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px]">
          {bnFormat(balance.locked)}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          ID
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('balance.desc.user')}
        </div>
        <div className="box-border w-[calc(100%/11)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('balance.desc.coin')}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('balance.desc.total')}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('balance.desc.free')}
        </div>
        <div className="box-border w-[calc(100%/11*2)] px-[12px] py-[16px] text-[14px] font-bold text-grey-700 dark:text-grey-300">
          {t('balance.desc.locked')}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
