import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormInput from 'src/component/FormInput';
import { SettingAutoCancelTimeForm, SettingLifetimeForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateAutoCancelTime, updateLifetime } from 'src/service/settingService';

/**
 * 訂單設定
 */
const OrderSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config } = useSelector((rootState: RootState) => rootState.setting);
  const lifetimeMethods = useForm<SettingLifetimeForm>();
  const autoCancelTimeMethods = useForm<SettingAutoCancelTimeForm>();

  useEffect(() => {
    if (config === null) return;
    lifetimeMethods.setValue('lifetime', config.order['bid-lifetime'].toString());
    autoCancelTimeMethods.setValue(
      'autoCancelTime',
      config.order['bid-auto-cancel-time'].toString(),
    );
  }, [config]);

  const onMatchPeriodSubmit = (data: SettingLifetimeForm) => {
    updateLifetime(data.lifetime).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  const onAutoCancelTimeSubmit = (data: SettingAutoCancelTimeForm) => {
    updateAutoCancelTime(data.autoCancelTime).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.orderSetting')}</div>
      <Form
        className="px-[15px] py-[30px] md:p-[30px]"
        methods={lifetimeMethods}
        onSubmit={onMatchPeriodSubmit}
      >
        <div className="mb-[20px] font-bold">{t('setting.desc.matchPeriod')}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <FormInput name="lifetime" regex={new RegExp('^[0-9]*$')} />
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
      <Form
        className="px-[15px] py-[30px] md:p-[30px]"
        methods={autoCancelTimeMethods}
        onSubmit={onAutoCancelTimeSubmit}
      >
        <div className="mb-[20px] font-bold">{t('setting.desc.autoCancelTime')}</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <FormInput name="autoCancelTime" regex={new RegExp('^[0-9]*$')} />
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default OrderSetting;
