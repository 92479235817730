import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Page } from 'src/constant/Page';
import { DepositRecord } from 'src/model/Deposit';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: DepositRecord[];
};

const Card = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (deposit: DepositRecord) => (
    <div key={deposit.id}>
      <div className="flex flex-col gap-[4px] px-0 py-[20px]">
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">ID</div>
          <div
            className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.DepositRecord}/${deposit.id}`)}
          >
            {deposit.id}
          </div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.user')}
          </div>
          {deposit.user ? (
            <div
              className="cursor-pointer text-[14px] font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (deposit.user?.side === 'ask') navigate(`${Page.UserAsk}/${deposit.userId}`);
                else if (deposit.user?.side === 'bid')
                  navigate(`${Page.UserBid}/${deposit.userId}`);
                else navigate(`${Page.UserAdmin}/${deposit.userId}`);
              }}
            >
              {t(`depositRecord.desc.side.${deposit.user.side}`)}-{deposit.user.email}
            </div>
          ) : (
            <div>{t('depositRecord.desc.system')}</div>
          )}
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.amount')}
          </div>
          <div className={'text-[14px]'}>{bnFormat(deposit.amount)}</div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.coin')}
          </div>
          <div className={'text-[14px]'}>
            {(deposit.network ? `${deposit.coin}-${deposit.network}` : deposit.coin).toUpperCase()}
          </div>
        </div>
        <div className="flex gap-[15px]">
          <div className="text-[14px] font-bold text-grey-700 dark:text-grey-300">
            {t('depositRecord.desc.depositDate')}
          </div>
          <div className={'text-[14px]'}>
            {format(Date.parse(deposit.createdAt), 'yyyy/MM/dd HH:mm:ss')}
          </div>
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
