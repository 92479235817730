import classNames from 'classnames';
import { format } from 'date-fns';
import { camelCase } from 'lodash';
import { useTranslation } from 'react-i18next';
import Button from 'src/component/Button';
import { BalanceTransaction } from 'src/model/Property';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: BalanceTransaction[];
  navigateCallback: (transaction: BalanceTransaction) => void;
};

const Card = ({ data, navigateCallback }: Props) => {
  const { t } = useTranslation();

  const onDetailClick = (transaction: BalanceTransaction) => () => {
    navigateCallback(transaction);
  };

  const elementMapFunction = (
    transaction: BalanceTransaction,
    index: number,
    array: BalanceTransaction[],
  ) => (
    <div key={transaction.id}>
      <div className="flex flex-col gap-[4px] pt-[15px]">
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('profit.desc.amount')}
          </div>
          <div className="text-[14px]">{bnFormat(transaction.amount)}</div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('profit.desc.balance')}
          </div>
          <div className="text-[14px]">{bnFormat(transaction.balance)}</div>
        </div>
        <div className="flex flex-row gap-[15px]">
          <div className="text-[14px] font-bold text-grey-500 dark:text-grey-700">
            {t('profit.desc.information')}
          </div>
          <div className="flex flex-row gap-[10px] text-[14px]">
            {t(`profit.desc.transactionType.${camelCase(transaction.type)}`)}
            {!['manipulation', 'on-chain-fee', 'init'].includes(transaction.type) && (
              <Button appearance="text" onClick={onDetailClick(transaction)}>
                {t('profit.act.detail')}
              </Button>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-[2px] text-[12px] text-grey-500 dark:text-grey-700">
          <div>{format(Date.parse(transaction.createdAt), 'yyyy/MM/dd HH:mm:ss')}</div>
        </div>
      </div>
      <div
        className={classNames('mt-[13px] h-[1px] bg-light-200 dark:bg-dark-700', {
          hidden: index >= array.length - 1,
        })}
      />
    </div>
  );

  return <div>{data.map(elementMapFunction)}</div>;
};

export default Card;
