import classNames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IcEdit from 'src/image/ic-edit-white.svg';
import { AccountUser } from 'src/model/AccountUser';

type Props = {
  user: AccountUser;
  onEditUser: (user: AccountUser) => void;
};
const UserStatusLabel = ({ user, onEditUser }: Props) => {
  const { t } = useTranslation();
  const displayKey = useMemo(() => {
    if (user.isBlocked === true) return 'user.desc.statusDisplay.blocked';
    if (user.isApproved === true) return 'user.desc.statusDisplay.approved';
    if (user.isVerified === false) return 'user.desc.statusDisplay.unverified';
    if (user.isApproved === false) return 'user.desc.statusDisplay.waiting';

    return '';
  }, [user]);

  const onLabelClick = () => {
    if (displayKey !== 'user.desc.statusDisplay.waiting') return;

    onEditUser(user);
  };

  return (
    <div
      className={classNames(
        'flex w-fit flex-row items-center gap-[2px] rounded-[4px] px-[12px] py-[4px] text-[12px] font-bold text-white',
        {
          'bg-green': displayKey === 'user.desc.statusDisplay.approved',
          'bg-dark-500': displayKey === 'user.desc.statusDisplay.unverified',
          'bg-red': displayKey === 'user.desc.statusDisplay.blocked',
          'cursor-pointer bg-khaki !py-[4px] !pl-[12px] !pr-[8px]':
            displayKey === 'user.desc.statusDisplay.waiting',
        },
      )}
      onClick={onLabelClick}
    >
      <div>{t(displayKey)}</div>
      {displayKey === 'user.desc.statusDisplay.waiting' && (
        <img src={IcEdit} className="h-[17px] w-[17px]" />
      )}
    </div>
  );
};

export default UserStatusLabel;
