import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from 'src/component/BackButton';
import { MediaQuery } from 'src/constant/Media';
import { Page } from 'src/constant/Page';
import IcEmpty from 'src/image/ic-empty-light.svg';
import { FillAdmin } from 'src/model/Order';
import { RebateDetail } from 'src/model/Rebate';
import { openSnackbar } from 'src/redux/uiSlice';
import { getRebateById, getRebateFillById } from 'src/service/rebateService';
import { bnFormat } from 'src/util/bigNumber';
import Card from './component/Card';
import Table from './component/Table';

const RebateAdminDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const { id: rebateId } = useParams<{ id: string }>();
  const [rebate, setRebate] = useState<RebateDetail>();
  const [rebateFill, setRebateFill] = useState<FillAdmin[]>();

  useEffect(() => {
    if (!rebateId) return;

    getRebateById(rebateId)
      .then((res) => setRebate(res))
      .catch((e) => {
        navigate(Page.ParentRebate, { replace: true });
        dispatch(openSnackbar({ message: e, severity: 'alert' }));
      });
  }, [rebateId]);

  useEffect(() => {
    if (rebateId !== undefined && rebate?.type === 'agent')
      getRebateFillById(rebateId).then((res) => setRebateFill(res));
  }, [rebate]);

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">{`${
        rebate?.type === 'agent' ? t('rebate.desc.agent') : t('rebate.desc.parent')
      } ${rebate?.id ?? ''}`}</div>
      <div className="mt-[30px] bg-white px-[25px] py-[30px]">
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            ID
          </div>
          <div className="flex-[1] break-all leading-[21px]">{rebate?.id}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('rebate.desc.type')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">
            {rebate?.type === 'agent' ? t('rebate.desc.agent') : t('rebate.desc.parent')}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('rebate.desc.user')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">
            <div
              className="w-fit cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => navigate(`${Page.UserAsk}/${rebate?.userId}`)}
            >
              {rebate?.user.email}
            </div>
          </div>
        </div>
        {rebate?.accountingTime && (
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('rebate.desc.accountingDate')}
            </div>
            <div className="flex-[1] break-all leading-[21px]">
              {format(new Date(rebate.accountingTime), 'yyyy/MM/dd')}
            </div>
          </div>
        )}
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('rebate.desc.coin')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">{rebate?.coin.toUpperCase()}</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {rebate?.type === 'agent' ? t('rebate.desc.teamAmount') : t('rebate.desc.askAmount')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">
            {bnFormat(rebate?.askAmount ?? 0)}
          </div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('rebate.desc.amount')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">{bnFormat(rebate?.amount ?? 0)}</div>
        </div>
        {rebate?.fillId && (
          <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
            <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
              {t('rebate.desc.fillId')}
            </div>
            <div className="flex-[1] break-all leading-[21px]">
              <div
                className="w-fit cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
                onClick={() => navigate(`${Page.Fill}/${rebate.fillId}`)}
              >
                {rebate.fillId}
              </div>
            </div>
          </div>
        )}
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('rebate.desc.rebatePerc')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">{rebate?.percentage}%</div>
        </div>
        <div className="box-border flex gap-[15px] px-0 py-[4px] text-[14px]">
          <div className="min-w-[130px] font-bold leading-[21px] text-grey-700 dark:text-grey-300">
            {t('rebate.desc.createdAt')}
          </div>
          <div className="flex-[1] break-all leading-[21px]">
            {rebate && format(new Date(rebate.createdAt), 'yyyy/MM/dd HH:mm:ss')}
          </div>
        </div>
      </div>
      {rebate?.type === 'agent' && (
        <div className="mt-[30px] bg-white px-[25px] py-[30px]">
          <div className="mb-[10px] text-[24px] font-bold">{t('rebate.desc.fill')}</div>
          {rebateFill?.length === 0 && (
            <div>
              <img src={IcEmpty} />
            </div>
          )}
          {rebateFill &&
            rebateFill.length > 0 &&
            (isBiggerThanMd ? <Table data={rebateFill} /> : <Card data={rebateFill} />)}
        </div>
      )}
    </div>
  );
};

export default RebateAdminDetail;
