import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { WithdrawalAutoApprovedForm } from 'src/model/Form';
import { settingSlice } from 'src/redux/settingSlice';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateWithdrawalAutoApproved } from 'src/service/settingService';

/**
 * 自動出款條件设置
 */
const WithdrawalAutoApprovedSetting = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config, crypto } = useSelector((rootState: RootState) => ({
    config: rootState.setting.config,
    crypto: rootState.coin.crypto,
  }));
  const methods = useForm<WithdrawalAutoApprovedForm>();

  const usdt = useMemo(() => crypto.find((c) => c.id === 'usdt'), [crypto]);

  useEffect(() => {
    const min = config?.['withdrawal-auto-approved-min']?.usdt ?? '0';
    methods.setValue('minWithdrawalAutoApproved', min);
    const max = config?.['withdrawal-auto-approved-max']?.usdt ?? '0';
    methods.setValue('maxWithdrawalAutoApproved', max);
  }, [config]);

  const onSubmit = (data: WithdrawalAutoApprovedForm) => {
    const min =
      config?.['withdrawal-auto-approved-min']?.usdt === data.minWithdrawalAutoApproved
        ? ''
        : data.minWithdrawalAutoApproved;
    const max =
      config?.['withdrawal-auto-approved-max']?.usdt === data.maxWithdrawalAutoApproved
        ? ''
        : data.maxWithdrawalAutoApproved;
    updateWithdrawalAutoApproved(min, max)
      .then(() => {
        if (!config) return;
        const payload = {
          ...config,
          'withdrawal-auto-approved-min': { usdt: data.minWithdrawalAutoApproved },
          'withdrawal-auto-approved-max': { usdt: data.maxWithdrawalAutoApproved },
        };
        dispatch(settingSlice.actions.setConfig(payload));
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">
        {t('setting.desc.withdrawalAutoApprovedSetting')}
      </div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methods} onSubmit={onSubmit}>
        <div className="mb-[20px] font-bold">USDT</div>
        <div className="flex items-end gap-[30px] md:gap-[60px]">
          <div className="w-[160px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.minWithdrawalAutoApproved')}
            </div>
            <FormNumberInput
              name="minWithdrawalAutoApproved"
              decimal={usdt ? usdt.priceDecimal : 0}
              min={0}
            />
          </div>
          <div className="w-[160px]">
            <div className="mb-[8px] text-[14px] text-dark-500 dark:text-grey-300">
              {t('setting.desc.maxWithdrawalAutoApproved')}
            </div>
            <FormNumberInput
              name="maxWithdrawalAutoApproved"
              decimal={usdt ? usdt.priceDecimal : 0}
              min={0}
            />
          </div>
          <Button className="min-w-[auto] px-[30px] py-[10px]" type="submit">
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default WithdrawalAutoApprovedSetting;
