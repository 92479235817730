import fillsEndpoint from 'src/api/fillsEndpoint';
import messageEndpoint from 'src/api/messageEndpoint';
import { PaginationParams } from 'src/model/Pagination';
import { setCannedMessageList } from 'src/redux/apiSlice';
import { dispatch, getState } from 'src/redux/store';
import { finishWaiting, startWaiting, updateFilter } from 'src/redux/uiSlice';
import { getPage } from 'src/util/paginationHelper';
import { toCsvFile } from 'src/util/toCsv';

export const getFillList = async (
  params: {
    status?: string;
    sorting?: string;
    email?: string;
    bankAccount?: string;
  },
  paginationParams?: PaginationParams,
  user?: string,
  csv = false,
) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.getFillsAdmin(
      {
        ...paginationParams,
        status: params.status,
        sort: params.sorting === 'default' ? undefined : params.sorting,
        side: params.email === undefined ? undefined : 'ask',
        email: params.email,
        bankAccount: params.bankAccount,
        timeout: params.status === 'unfinished' ? '7200' : undefined,
        order: 'desc',
        user,
      },
      csv ? { Accept: 'text/csv' } : undefined,
    );

    dispatch(
      updateFilter({
        key: 'adminFill',
        filter: {
          id: undefined,
          status: params.status,
          sorting: params.sorting,
          askerEmail: params.email,
          bankAccount: params.bankAccount,
          begin: paginationParams?.begin,
          end: paginationParams?.end,
          limit: paginationParams?.limit,
          page: getPage(paginationParams?.offset, paginationParams?.limit),
        },
      }),
    );

    if (csv) toCsvFile('fill.csv', res.data as unknown as string);

    return { data: res.data, count: Number(res.headers['x-pagination-count']) };
  } finally {
    dispatch(finishWaiting());
  }
};

export const getFillById = async (id: string, saveFilter?: boolean) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.getFillIdAdmin(id);

    saveFilter && dispatch(updateFilter({ key: 'adminFill', filter: { id } }));

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const confirmFill = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.patchFillsReceive(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const rejectFill = async (id: string) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.patchFillsIdReject(id);

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const sentMessage = async (
  fillId: string,
  userId: string,
  subject: string,
  content: string,
) => {
  try {
    dispatch(startWaiting());
    const res = await fillsEndpoint.postFillsIdMessage(fillId, { userId, subject, content });

    return res.data;
  } finally {
    dispatch(finishWaiting());
  }
};

export const getCannedMessageList = async () => {
  try {
    dispatch(startWaiting());
    const { cannedMessageList } = getState().api;

    if (cannedMessageList === null) {
      const res = await messageEndpoint.getCannedMessageList({});
      dispatch(setCannedMessageList(res.data));

      return res.data;
    }

    return cannedMessageList;
  } finally {
    dispatch(finishWaiting());
  }
};
