import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FillStatusLabel from 'src/component/FillStatusLabel';
import { Page } from 'src/constant/Page';
import { FillAdmin } from 'src/model/Order';
import { bnFormat } from 'src/util/bigNumber';

type Props = {
  data: Array<FillAdmin>;
};

const Table = ({ data }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const elementMapFunction = (fill: FillAdmin) => (
    <div key={fill.id}>
      <div className="flex flex-row">
        <div className="box-border flex w-[8.33%] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div
            className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
            onClick={() => navigate(`${Page.Fill}/${fill.id}`)}
          >
            {fill.id}
          </div>
        </div>
        <div className="box-border flex w-[16.66%] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div>
            <span>{`${bnFormat(fill.total)} `}</span>
            <span>{fill.quote.toUpperCase()}</span>
          </div>
        </div>
        <div className="box-border flex w-[24.99%] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.seller')}
            </div>
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (fill.asker.side === 'ask') navigate(`${Page.UserAsk}/${fill.askerId}`);
                else if (fill.asker.side === 'bid') navigate(`${Page.UserBid}/${fill.askerId}`);
                else navigate(`${Page.UserAdmin}/${fill.askerId}`);
              }}
            >
              {t(`fill.desc.side.${fill.asker.side}`)}-{fill.asker.email}
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.sellingAmount')}
            </div>
            <div>
              <span>{`${bnFormat(fill.askAmount)} `}</span>
              <span className="break-all">{fill.base.toUpperCase()}</span>
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.sellingPrice')}
            </div>
            <div>
              <span>{`${bnFormat(fill.askPrice)} `}</span>
              <span className="break-all">{fill.quote.toUpperCase()}</span>
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.advertisement')}
            </div>
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => navigate(`${Page.Trade}/${fill.askId}`)}
            >
              {fill.askId}
            </div>
          </div>
        </div>
        <div className="box-border flex w-[24.99%] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyer')}
            </div>
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => {
                if (fill.bidder.side === 'ask') navigate(`${Page.UserAsk}/${fill.bidderId}`);
                else if (fill.bidder.side === 'bid') navigate(`${Page.UserBid}/${fill.bidderId}`);
                else navigate(`${Page.UserAdmin}/${fill.bidderId}`);
              }}
            >
              {t(`fill.desc.side.${fill.bidder.side}`)}-{fill.bidder.email}
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyingAmount')}
            </div>
            <div>
              <span>{`${bnFormat(fill.bidAmount)} `}</span>
              <span className="break-all">{fill.base.toUpperCase()}</span>
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.buyingPrice')}
            </div>
            <div>
              <span>{`${bnFormat(fill.bidPrice)} `}</span>
              <span className="break-all">{fill.quote.toUpperCase()}</span>
            </div>
          </div>
          <div className="flex gap-[15px]">
            <div className="shrink-0 text-[14px] font-bold text-grey-700 dark:text-grey-300">
              {t('fill.desc.order')}
            </div>
            <div
              className="cursor-pointer font-bold text-turquoise hover:underline dark:text-aqua"
              onClick={() => navigate(`${Page.Order}/${fill.bidId}`)}
            >
              {fill.bidId}
            </div>
          </div>
        </div>
        <div className="box-border flex w-[8.33%] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <span>{`${bnFormat(fill.profit)} `}</span>
          <span className="break-all">{fill.base.toUpperCase()}</span>
        </div>
        <div className="box-border flex w-[16.66%] flex-col gap-[4px] break-all px-[12px] py-[16px] text-[14px]">
          <div>
            <span>{`${format(Date.parse(fill.createdAt), 'yyyy/MM/dd')} `}</span>
            <span className="break-all">{format(Date.parse(fill.createdAt), 'HH:mm:ss')}</span>
          </div>
          <FillStatusLabel fill={fill} />
          {fill.askerMessagedAt && (
            <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
              {t('fill.desc.askerMessageSent')}
            </div>
          )}
          {fill.bidderMessagedAt && (
            <div className="w-fit rounded-[4px] bg-crimson px-[12px] py-[4px] text-[12px] font-bold leading-[16px] text-white dark:bg-red">
              {t('fill.desc.bidderMessageSent')}
            </div>
          )}
        </div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
    </div>
  );

  return (
    <div>
      <div className="flex flex-row">
        <div className="box-border w-[8.33%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          ID
        </div>
        <div className="box-border w-[16.66%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('fill.desc.price')}
        </div>
        <div className="box-border w-[24.99%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('fill.desc.seller')}
        </div>
        <div className="box-border w-[24.99%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('fill.desc.buyer')}
        </div>
        <div className="box-border w-[8.33%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">
          {t('fill.desc.profit')}
        </div>
        <div className="box-border w-[16.66%] px-[12px] py-[16px] text-[14px] font-bold text-grey-700">{`${t(
          'fill.desc.createdAt',
        )}/${t('fill.desc.status')}`}</div>
      </div>
      <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
      <>{data.map(elementMapFunction)}</>
    </div>
  );
};

export default Table;
