import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch, useSelector } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import DateFilter from 'src/component/DateFilter';
import Form from 'src/component/Form';
import FormSearchInput from 'src/component/FormSearchInput';
import Pagination from 'src/component/Pagination';
import Select from 'src/component/Select';
import SelectOption from 'src/component/SelectOption';
import { MediaQuery } from 'src/constant/Media';
import { useDateFilter } from 'src/hook/useDateFilter';
import { usePagination } from 'src/hook/usePagination';
import IcEmpty from 'src/image/ic-empty-light.svg';
import IcLimit from 'src/image/ic-limit.svg';
import { CommissionAdmin as Commission } from 'src/model/Commission';
import { CommissionQueryForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { getCommissionAdminById, getCommissionList } from 'src/service/commssionService';
import Card from './component/Card';
import Table from './component/Table';

const CommissionAdmin = () => {
  const dispatch = useDispatch();
  const { adminDeposit: filter } = useSelector((rootState: RootState) => rootState.ui.filter);
  const { t, ready } = useTranslation();
  const [commissionId, setCommissionId] = useState<string>(filter.id ?? '');
  const [commissionList, setCommissionList] = useState<Commission[]>();
  const { begin, end, dateFilterProps } = useDateFilter({
    defaultFrom: filter.begin,
    defaultTo: filter.end,
  });
  const { offset, limit, setCount, setLimit, paginationProps } = usePagination({
    defaultLimit: filter.limit,
    defaultPage: filter.page,
  });
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);
  const methods = useForm<CommissionQueryForm>({
    defaultValues: {
      id: filter.id,
    },
  });

  useEffect(() => {
    if (commissionId) {
      getCommissionAdminById(commissionId, true)
        .then((res) => {
          setCommissionList([res]);
          setCount(1);
        })
        .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));

      return;
    }

    getCommissionList({ begin, end, offset, limit })
      .then((res) => {
        setCommissionList(res.data);
        setCount(res.count);
      })
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [commissionId, begin, end, offset, limit]);

  const onQuery = (data: CommissionQueryForm) => {
    setCommissionId(data.id);
  };

  const onDownload = () => {
    getCommissionList({ begin, end, offset, limit }, true).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  if (!ready) return <div />;

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex flex-row justify-between text-[32px] font-bold">
        {t('commission.heading')}
      </div>
      <div className="mt-[30px] flex flex-row gap-[30px] px-[15px] py-0 md:mt-[30px] md:gap-[60px] md:px-[30px]">
        <DateFilter
          label={t('commission.desc.dateFilter')}
          onChange={() => paginationProps.setPage(1)}
          {...dateFilterProps}
        />
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <Form
          methods={methods}
          onSubmit={onQuery}
          className="flex flex-row flex-wrap items-center gap-[30px] pb-[25px]"
        >
          <FormSearchInput
            name="id"
            placeholder={t('commission.desc.commissionId')}
            onQuery={onQuery}
          />
          <div className="flex w-[120px] gap-[8px]">
            <img src={IcLimit} />
            <div className="grow">
              <Select
                value={limit.toString()}
                onChange={(v) => {
                  setLimit(Number(v));
                  paginationProps.setPage(1);
                }}
              >
                <SelectOption value="5">{'5'}</SelectOption>
                <SelectOption value="10">{'10'}</SelectOption>
                <SelectOption value="50">{'50'}</SelectOption>
                <SelectOption value="100">{'100'}</SelectOption>
              </Select>
            </div>
          </div>
          <Button appearance="secondary" size="small" type="submit">
            {t('commission.desc.search')}
          </Button>
          <Button
            className="font-bold"
            size="small"
            appearance="secondary"
            type="button"
            onClick={onDownload}
          >
            {t('commission.desc.download')}
          </Button>
        </Form>
        <div className="h-[1px] bg-light-200 dark:bg-dark-700" />
        {commissionList?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {commissionList &&
          commissionList.length > 0 &&
          (isBiggerThanMd ? <Table data={commissionList} /> : <Card data={commissionList} />)}
      </div>
      <div className="mt-[15px] flex flex-row-reverse">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};

export default CommissionAdmin;
