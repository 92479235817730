import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'src/component/Button';
import Form from 'src/component/Form';
import FormNumberInput from 'src/component/FormNumberInput';
import { SettingAskerLimitForm } from 'src/model/Form';
import { RootState } from 'src/redux/store';
import { openSnackbar } from 'src/redux/uiSlice';
import { updateAskertLimit } from 'src/service/settingService';

/**
 * 媒合數量限制
 */
const AskerLimit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { config } = useSelector((rootState: RootState) => rootState.setting);
  const methods = useForm<SettingAskerLimitForm>();

  useEffect(() => {
    if (config === null) return;
    methods.setValue('askerLimit', config.fill.askerLimit);
  }, [config]);

  const onSubmit = (data: SettingAskerLimitForm) => {
    updateAskertLimit(data.askerLimit).catch((e) =>
      dispatch(openSnackbar({ message: e, severity: 'alert' })),
    );
  };

  return (
    <div>
      <div className="mt-[30px] text-[24px] font-bold">{t('setting.desc.askerLimit')}</div>
      <Form className="px-[15px] py-[30px] md:p-[30px]" methods={methods} onSubmit={onSubmit}>
        <div className="flex items-center gap-[30px] md:gap-[60px]">
          <div className="w-[120px]">
            <FormNumberInput
              label={t('setting.desc.askerLimitHead')}
              name="askerLimit"
              allowMinus
              required
              min={-1}
              hint={t('setting.desc.askerLimitHint')}
            />
          </div>
          <Button
            className="min-w-[auto] px-[30px] py-[10px]"
            type="submit"
            disabled={Object.keys(methods.formState.errors).length !== 0}
          >
            {t('setting.act.save')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AskerLimit;
