import {
  GetExchangeRateResponse,
  PostExchangeRateResponse,
  PostExchangeRateShiftRequest,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getExchangeRate = async (base: string, quote: string) => {
  try {
    return await http.authGet<GetExchangeRateResponse>(`exchange-rate/${base}/${quote}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const createExchangeRateShift = async (data: PostExchangeRateShiftRequest) => {
  try {
    return await http.authPost<PostExchangeRateResponse, PostExchangeRateShiftRequest>(
      `exchange-rate-shift`,
      { data },
    );
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  createExchangeRateShift,
  getExchangeRate,
};
