import {
  GetAgentRebateResponse,
  GetParentRebateResponse,
  GetRebateIdFillResponse,
  GetRebateIdResponse,
  GetRebateListParams,
  GetRebateListResponse,
  PostRebateRequest,
  PostRebateResponse,
} from 'src/model/Api';
import { axiosError, defaultErrorMessage } from 'src/util/errorHandler';
import http from 'src/util/http';

const getRebateList = async (params: GetRebateListParams, headers?: { [key: string]: string }) => {
  try {
    return await http.authGet<GetRebateListResponse>('rebates', { params, headers });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getRebateById = async (id: string) => {
  try {
    return await http.authGet<GetRebateIdResponse>(`rebates/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getAgentRebate = async () => {
  try {
    return await http.authGet<GetAgentRebateResponse>('rebate-percentages/agent');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getParentRebate = async () => {
  try {
    return await http.authGet<GetParentRebateResponse>('rebate-percentages/parent');
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const postRebate = async (data: PostRebateRequest) => {
  try {
    return await http.authPost<PostRebateResponse, PostRebateRequest>('rebate-percentages', {
      data,
    });
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const deleteRebate = async (id: string) => {
  try {
    return await http.authDelete(`rebate-percentages/${id}`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

const getRebateIdFill = async (id: string) => {
  try {
    return await http.authGet<GetRebateIdFillResponse>(`rebates/${id}/fills`);
  } catch (e) {
    const error = axiosError(e);
    throw defaultErrorMessage(error);
  }
};

export default {
  getRebateList,
  getRebateById,
  getAgentRebate,
  getParentRebate,
  postRebate,
  deleteRebate,
  getRebateIdFill,
};
