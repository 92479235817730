import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaPredicate } from 'react-media-hook';
import { useDispatch } from 'react-redux';
import BackButton from 'src/component/BackButton';
import Button from 'src/component/Button';
import { MediaQuery } from 'src/constant/Media';
import IcAdd from 'src/image/ic-add-s.svg';
import IcEmpty from 'src/image/ic-empty-light.svg';
import { CannedMessage } from 'src/model/Message';
import { openSnackbar } from 'src/redux/uiSlice';
import { deleteCannedMessage, getCannedMessageList } from 'src/service/messageService';
import Card from './component/Card';
import MessageModal from './component/MessageModal';
import Table from './component/Table';

const MessageCannedAdmin = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [list, setList] = useState<CannedMessage[]>();
  const [modalTarget, setModalTarget] = useState<CannedMessage>();
  const [open, setOpen] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const isBiggerThanMd = useMediaPredicate(MediaQuery.Md);

  useEffect(() => {
    getCannedMessageList()
      .then((res) => setList(res.data))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  }, [refresh]);

  const onEdit = (data: CannedMessage) => {
    setOpen(true);
    setModalTarget(data);
  };

  const onDelete = (message: CannedMessage) => {
    deleteCannedMessage(message.id)
      .then(() => setRefresh(!refresh))
      .catch((e) => dispatch(openSnackbar({ message: e, severity: 'alert' })));
  };

  return (
    <div>
      <BackButton />
      <div className="mt-[20px] flex justify-between text-[32px] font-bold">
        <div>{t('messageCannedAdmin.heading')}</div>
        <div>
          <Button
            appearance="text"
            className="flex items-center gap-[4px]"
            onClick={() => {
              setOpen(true);
              setModalTarget(undefined);
            }}
          >
            <img src={IcAdd} />
            {t('messageCannedAdmin.act.add')}
          </Button>
        </div>
      </div>
      <div className="mt-[25px] rounded-[4px] bg-white px-[40px] py-[25px] text-[14px]">
        <div className="h-[1px] bg-light-200" />
        {list?.length === 0 && (
          <div className="text-center">
            <img src={IcEmpty} />
          </div>
        )}
        {list &&
          list.length > 0 &&
          (isBiggerThanMd ? (
            <Table list={list} onEdit={onEdit} onDelete={onDelete} />
          ) : (
            <Card list={list} onEdit={onEdit} onDelete={onDelete} />
          ))}
      </div>
      <MessageModal
        open={open}
        onClose={() => setOpen(false)}
        message={modalTarget}
        doRefresh={() => setRefresh(!refresh)}
      />
    </div>
  );
};

export default MessageCannedAdmin;
